import React from 'react';
import {connect} from "react-redux";
import packageJson from '../../package';
import {Route, Switch} from 'react-router-dom';
import _ from "lodash";
import 'nanoscroller';
import classNames from 'classnames';
import AppTopbar from '../../AppTopbar';
import {AppMenu} from '../../AppMenu';
import jQuery from 'jquery';

import 'primereact/resources/primereact.min.css';
import 'nanoscroller/bin/css/nanoscroller.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'primeflex/primeflex.css';

import {checkForGateway, openMessaging, RES_CHECK_FOR_GATEWAY} from "../../actions/login";

import PracticeControlCenter from './PracticeControlCenter';
import Preferences from "./Preferences/Preferences";
import * as Capabilities from "../Capabilities";

import FindUsers from "./Users/FindUsers";
import NHSManagement from "./NHSManagaement/NHSManagement";
import PatientAccess from "./Patients/PatientAccess";
import AccountGroups from "./Users/AccountGroups";
import OutstandingTasks from "./OutstandingTasks/OutstandingTasks";
import ShowWith from "./Users/ShowWith";
import DiarySortOrder from "./Users/DiarySortOrder";
import FindReferrers from "./Referrers/FindReferrers";
import AddReferrer from "./Referrers/AddReferrer";
import OutstandingAccounts from "./Reports/OutstandingAccounts";
import AccountsInCredit from "./Reports/AccountsInCredit";
import UninvoicedCharges from "./Reports/UninvoicedCharges";
import IncompleteCharges from "./Reports/IncompleteCharges";
import DailyCancellations from "./Reports/DailyCancellations";
import WeeklyCancellations from "./Reports/WeeklyCancellations";
import WeeklyFails from "./Reports/WeeklyFails";
import RecallManagement from "./Recalls/RecallManagement";
import PatientJourney from "./Reports/PatientJourney";
import TreatmentCodeUsage from "./Reports/TreatmentCodeUsage";
import DailyCashBook from "./Reports/DailyCashBook";
import DailyTransactions from "./Reports/DailyTransactions";
import MonthlyCash from "./Reports/MonthlyCash";
import MonthlyShare from "./Reports/MonthlyShare";
import YearlyCash from "./Reports/YearlyCash";
import YearlyShare from "./Reports/YearlyShare";
import Voids from "./Reports/Voids";
import WriteOffs from "./Reports/WriteOffs";
import CostCenters from "./Reports/CostCenters";
import TabletRegistrations from "./Patients/TabletRegistrations";
import FindPatients from "./Patients/FindPatients";
import RecallsDue from "./Recalls/RecallsDue";
import RemindersDue from "./Reminders/RemindersDue";
import PracticeDiary from "./Diary/PracticeDiary";
import PracticeWeekDiary from "./Diary/PracticeWeekDiary";
import WebsocketHandler from "../WebsocketHandler";
import TabErrorBoundary, {menuButtonTemplate} from "./fixedItemUtils";
import {
    HM_ABOUT_CHIRAL,
    HM_BOOK_TRAINING,
    HM_DIARY_SORT_ORDER,
    HM_FORCE_LOGOUT,
    HM_HELP_CHIRAL,
    HM_ISSUE_PAYMENT_PLAN_INVOICES,
    HM_LOGOUT,
    HM_MESSAGING,
    HM_RELEASE_NOTE,
    HM_SHOW_WITH,
    HM_SMS_CREDIT_LIMIT,
    HM_WORK_RECEIVED_REPORT,
    HM_WORK_REQUESTED_REPORT,
    HM_WORK_REQUIRED_PICKUP,
    HM_WORK_REQUIRED_RECEIVED,
    HM_WORK_REQUIRED_REPORT,
    ME_ADD_PATIENT,
    ME_ADD_USER,
    ME_EMAIL_MAIL_SHOTS,
    ME_Housekeeping,
    ME_MAIL_SHOTS,
    ME_PatientTemplates,
    ME_Preferences,
    ME_SMS_MAIL_SHOTS,
    ME_SystemTemplates,
    ME_TemplateNotes,
    ME_UploadPayments,
    ME_UserCapabilities,
    ME_Utilities, SU_STATUS_ACTIVE,
    UP_EDI_CLAIMS_TOTAL,
    UP_EDI_ERRORS_TOTAL,
    UP_EDI_RESUBMIT,
    UP_PATIENT_ARRIVED,
    UP_PP_INVOICES_TOTAL,
    WORK_STATUS_RECEIVED_FROM_LAB
} from "../../Constants";
import {
    ICON_DIARY,
    ICON_HELP,
    ICON_LAB,
    ICON_LINE_CHART,
    ICON_PATIENTS,
    ICON_REFERRERS,
    ICON_USERS,
} from "../../icons";
import {
    HELP_RELEASE_NOTES,
    KNOWLEDGE_BASE,
    messageBus,
    SM_ACCOUNT_GROUPS,
    SM_CLINICIAN_DIARY,
    SM_CLOUD_LOGGED_IN,
    SM_COMPLETED_QUESTIONNAIRES,
    SM_COMPLIANCE,
    SM_INCOMPLETE_QUESTIONNAIRES,
    SM_LABORATORY_ADD,
    SM_LABORATORY_SEARCH,
    SM_NHS_MANAGEMENT,
    SM_ONLINE,
    SM_ONLINE_BOOKING,
    SM_ONLINE_REGISTRATION,
    SM_PATIENT_ACCESS,
    SM_PATIENT_SEARCH,
    SM_PAYMENT_PLAN_ISSUE_INVOICES,
    SM_PRACTICE_DIARY,
    SM_PRACTICE_WEEK_DIARY,
    SM_PRECOMPLETE_QUESTIONNAIRES,
    SM_QUESTIONNAIRES,
    SM_RECALLS_DUE,
    SM_REFERRER_ADD,
    SM_REFERRER_SEARCH,
    SM_REMINDERS_DUE,
    SM_REPORTS,
    SM_REPORTS_ACCOUNTS_IN_CREDIT,
    SM_REPORTS_ATTEND,
    SM_REPORTS_CANCELS,
    SM_REPORTS_CHARGES,
    SM_REPORTS_CLINICIAN_USAGE,
    SM_REPORTS_CODE_SUMMARY,
    SM_REPORTS_CODE_USAGE,
    SM_REPORTS_COP_MONTHLY_TRANS,
    SM_REPORTS_COP_REPORT1_10,
    SM_REPORTS_COP_REPORT1_15,
    SM_REPORTS_COP_REPORT1_20,
    SM_REPORTS_COP_REPORT9,
    SM_REPORTS_COP_REPORTS,
    SM_REPORTS_COST_CENTERS,
    SM_REPORTS_DAILY_CANCELS,
    SM_REPORTS_DAILY_CASH,
    SM_REPORTS_DAILY_TRANSACTIONS,
    SM_REPORTS_INCOMPLETE_CHARGES,
    SM_REPORTS_MONTHS_CASH,
    SM_REPORTS_MONTHS_SHARE,
    SM_REPORTS_OUTSTANDING_ACCOUNTS,
    SM_REPORTS_PATIENT_JOURNEY,
    SM_REPORTS_PAYMENT_PLANS,
    SM_REPORTS_RECALL_MANAGEMENT,
    SM_REPORTS_REVENUES,
    SM_REPORTS_SMS_LIMITS,
    SM_REPORTS_UNINVOICED_CHARGES,
    SM_REPORTS_UTILISATION,
    SM_REPORTS_VOIDED_TRANSACTIONS,
    SM_REPORTS_WEEKLY_CANCELS,
    SM_REPORTS_WEEKLY_NON_ATTEND,
    SM_REPORTS_WEEKS_SHARE,
    SM_REPORTS_WRITTEN_OFFS,
    SM_REPORTS_YEARS_CASH,
    SM_REPORTS_YEARS_SHARE,
    SM_SEDATION,
    SM_SEDATION_REPORT,
    SM_SEDATION_SUMMARY,
    SM_TABLET_REGISTRATIONS,
    SM_TASKS,
    SM_TRAINING,
    SM_USER_SEARCH,
    SM_VIDEO_CONFERENCES,
    SM_WORK_RECEIVED_REPORT,
    SM_WORK_REQUESTED_REPORT,
    SM_WORK_REQUIRED_REPORT,
    SM_XRAY_REPORT,
    SM_XRAY_SUMMARY,
    SM_XRAYS,
    stateRequest
} from "../../actions/stateManagement";
import {getAddReferrerTabIndex} from "./ReferrerDetails/Utils";
import PaymentPlanning from "./Reports/PaymentPlanning";
import {BaseComponent} from "../BaseComponent";
import {Dialog} from "primereact/dialog";
import {Card} from "primereact/card";
import {getSMSCreditLimit, RES_PATIENT_SMS} from "../../actions/personal";
import SMSCreditLimit from "./Reports/SMSCreditLimit";
import {issuePaymentPlanInvoices, RES_PAYMENT_PLANNING} from "../../actions/paymentPlanning";
import {ScrollPanel} from "primereact/scrollpanel";
import {Button} from "primereact/button";
import {getObjectStore, RES_OBJECT_STORE} from "../../actions/objectStore";
import {getObjectStoreIds} from "./Preferences/Utils";
import {
    APPOINTMENT_COLOUR_SCHEME,
    COP_REPORTS,
    HOLIDAY_COLOUR,
    LUNCH_COLOUR,
    OUTOFHOURS_COLOUR,
    RESERVED_COLOUR
} from "./Preferences/Constants";
import {rn1_7_48} from "./ReleaseNotes/1.7.48";
import * as Actions from "../../actions";
import {WSM_SEND_USER} from "../../actions";
import {Toast} from "primereact/components/toast/Toast";
import {ShowQuestionDialog} from "./Diary/components/EventComponent";
import VideoAppointments from "./Online/VideoAppointments";
import OnlineBookings from "./Online/OnlineBookings";
import CompletedQuestionnaires from "./Online/CompletedQuestionnaires";
import WeeklyRegistrations from "./Reports/WeeklyRegistrations";
import IncompleteQuestionnaires from "./Online/IncompleteQuestionnaires";
import PreCompletedQuestionnaires from "./Online/PreCompletedQuestionnaires";
import {dispatchMessage} from "../../actions/websockets";
import {ac} from "../../index";

import beep from '../../beep.wav';
import {getAddLaboratoryTabIndex} from "./LaboratoryDetails/Utils";
import FindLaboratory from "./Laboratories/FindLaboratory";
import AddLaboratory from "./Laboratories/AddLaboratory";
import {DiaryReceiveWorkRequired} from "./Diary/dialogs/DiaryReceiveWorkRequired";
import {getResource as getLabResource, markWorkStatus, RES_WORK_REQUIRED} from "../../actions/findLaboratories";
import moment from "moment";
import {getAllUsers} from "../../actions/users";
import WorkRequiredReport from "./Reports/WorkRequiredReport";
import WorkRequestedReport from "./Reports/WorkRequestedReport";
import WorkReceivedReport from "./Reports/WorkReceivedReport";
import {DiaryPickupWorkRequired} from "./Diary/dialogs/DiaryPickupWorkRequired";
import WeeklyXrayReport from "./Reports/WeeklyXrayReport";
import WeeklyXraySummary from "./Reports/WeeklyXraySummary";
import BookTraining from "./Utilities/dialogs/BookTraining";
import {getCurrentConfiguration, RES_GATEWAY} from "../../actions/gateway";
import {RES_REPORT_COP_REPORT1} from "../../actions/reports";
import CopReport9 from "./Reports/COPReport9";
import MonthlyTransactions from "./Reports/MonthlyTransactions";
import IssueInstallmentInvoices from "./PaymentPlanning/IssueInstallmentInvoices";
import WeeklySedationSummary from "./Reports/WeeklySedationSummary";
import WeeklySedationReport from "./Reports/WeeklySedationReport";
import WeeklyShare from "./Reports/WeeklyShare";
import TreatmentCodeSummary from "./Reports/TreatmentCodeSummary";
import ClinicianUsage from "./Reports/ClincianUsage";
import {getResource as getHSKResource, RES_HOUSEKEEPING_ZONES} from "../../actions/housekeeping";

const requiredObject = [LUNCH_COLOUR.value, RESERVED_COLOUR.value, HOLIDAY_COLOUR.value, OUTOFHOURS_COLOUR.value, APPOINTMENT_COLOUR_SCHEME, COP_REPORTS];

class ConnectedLoggedIn extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            layoutMode: 'static',
            profileMode: 'inline',
            layoutCompact: true,
            overlayMenuActive: false,
            staticMenuDesktopInactive: true,
            staticMenuMobileActive: false,
            rotateMenuButton: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkMenu: false,
            menuActive: false,
            index: 0,
            activeIndex: 0,
            findPatientsVisible: false,
            practiceDiaryVisible: false,
            addPatientVisible: false,
            findUsersVisible: false,
            token: null,

            visibleLeft: false,
            playSound: false,

            barcodePattern: '',
            namePattern: '',

            [HM_DIARY_SORT_ORDER.id]: false,
            [HM_WORK_REQUIRED_RECEIVED.id]: false,
            [HM_HELP_CHIRAL.id]: false,
            [HM_ABOUT_CHIRAL.id]: false,
            [HM_ABOUT_CHIRAL.id]: false,
            [HM_SMS_CREDIT_LIMIT.id]: false,
            [HM_BOOK_TRAINING.id]: false,
        };

        this.menu = [];

        this.tabletGrowl = null;
        this.audioRef = null;
    }

    componentDidMount() {

        this.props.checkForGateway();
        this.props.getCurrentConfiguration();

        this.props.getObjectStore(requiredObject);
        this.props.getAllUsersShort();

        jQuery(this.layoutMenuScroller).nanoScroller({flash: true});

        this.props.dispatchMessage({
            type: SM_CLOUD_LOGGED_IN.id,
            groupId: ac.getGroupId(),
            mcId: ac.getMcId(),
            id: this.props.loginIdentity.id,
            username: this.props.loginIdentity.username,
            name: `${this.props.loginIdentity.firstName} ${this.props.loginIdentity.lastName}`,
        })

        this.audioRef = React.createRef();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case Actions.REQUEST_NHS_MANAGEMENT_SUBMIT_CLAIM:
                    break;

                case Actions.FORCE_LOGOUT:
                    this.setState({[HM_FORCE_LOGOUT.id]: true});
                    break;

                case RES_OBJECT_STORE.GET_LOGIN.receive:
                    this.setState({loginObjectsLoaded: true});
                    break;

                case Actions.RECEIVE_USER_SEARCH:

                    const clinicians = _.filter(this.props.usersShort, user => (user.status === SU_STATUS_ACTIVE && user.username !== 'superuser' && user.username !== 'TED_superuser' && user.myRole === 'CLINICIAN'));

                    clinicians.forEach((clinician) => {
                        this.props.getUserDiaryZones(clinician.id);
                    })
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_PP_INVOICES_TOTAL :

                            const noOfInvoicesIssued = this.props.wsmessage.content[0];
                            const ppiToken = this.props.wsmessage.content[1];

                            if (ppiToken === this.state.token) {
                                this.growl.show({
                                    severity: 'info',
                                    summary: 'Payment Plan Invoices Issued',
                                    detail: `Total issued : ${noOfInvoicesIssued}`,
                                    sticky: true,
                                });
                            }
                            break;

                        case UP_EDI_CLAIMS_TOTAL : {

                            const noOfClaimsSubmitted = this.props.wsmessage.content[0];
                            const ediToken = this.props.wsmessage.content[1];

                            if (ediToken === this.props.ediToken) {
                                this.growl.show({
                                    severity: 'info',
                                    summary: 'EDI Claims Submitted',
                                    detail: `Total Submitted : ${noOfClaimsSubmitted}`,
                                    sticky: true,
                                });
                            }
                            break;
                        }

                        case UP_EDI_ERRORS_TOTAL : {

                            const noOfClaimsInError = this.props.wsmessage.content[0];
                            const ediToken = this.props.wsmessage.content[1];

                            if (ediToken === this.props.ediToken) {
                                this.growl.show({
                                    severity: 'error',
                                    summary: 'EDI Claims In Error',
                                    detail: `Total Errors : ${noOfClaimsInError}`,
                                    sticky: true,
                                });
                            }
                            break;
                        }
                        case UP_EDI_RESUBMIT : {

                            const ediToken = this.props.wsmessage.content;

                            if (ediToken === this.props.ediToken) {
                                this.growl.show({
                                    severity: 'info',
                                    summary: 'EDI Claim Resubmitted',
                                    detail: `Success`,
                                    sticky: false,
                                });
                            }
                            break;
                        }
                        case UP_PATIENT_ARRIVED:
                            this.audioRef.current.play();
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        switch (event.owner) {
            case 'labEntry.receivedOn': {

                const newState = {...this.state};
                _.set(newState, event.owner, moment(event.value).valueOf());
                _.set(newState, 'labEntry.status', WORK_STATUS_RECEIVED_FROM_LAB.name);

                this.setState(newState);
                break;
            }
            case 'labEntry.receivedById':
            case 'labEntry.returnNote': {

                const newState = {...this.state};
                _.set(newState, event.owner, event.value);
                _.set(newState, 'labEntry.status', WORK_STATUS_RECEIVED_FROM_LAB.name);

                this.setState(newState);
                break;
            }
            case 'selectedLabEntry': {

                const labEntry = _.cloneDeep(event.value);

                const newState = {...this.state};
                _.set(newState, 'labEntry', labEntry);

                this.setState(newState);
                break;
            }
            default:

                const newState = {...this.state};
                _.set(newState, event.owner, event.value);

                this.setState(newState);
                break;
        }
    }

    onMenuClick = (event) => {
        this.menuClick = true;

        if (!this.isHorizontal()) {
            setTimeout(() => {
                jQuery(this.layoutMenuScroller).nanoScroller();
            }, 500);
        }
    }

    onMenuButtonClick = (event) => {
        this.menuClick = true;
        this.setState(({
            rotateMenuButton: !this.state.rotateMenuButton,
            topbarMenuActive: false
        }));

        if (this.state.layoutMode === 'overlay') {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        } else {
            if (this.isDesktop())
                this.setState({staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive});
            else
                this.setState({staticMenuMobileActive: !this.state.staticMenuMobileActive});

        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick = (event) => {
        this.topbarItemClick = true;
        this.setState({topbarMenuActive: !this.state.topbarMenuActive});
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick = (event) => {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({activeTopbarItem: null});
        else
            this.setState({activeTopbarItem: event.item});

        switch (event.item) {
            case HM_HELP_CHIRAL.id :
                try {
                    window.open(KNOWLEDGE_BASE);
                } catch (e) {
                    alert(e);
                }

                break;
            case HM_ABOUT_CHIRAL.id :
                this.onShowMenuEntry({item: {target: HM_ABOUT_CHIRAL.id}});
                break;
            case HM_LOGOUT.id :

                this.props.dispatchMessage({
                    type: HM_LOGOUT.id,
                    groupId: ac.getGroupId(),
                    mcId: ac.getMcId(),
                    id: this.props.loginIdentity.id,
                    username: this.props.loginIdentity.username,
                    name: `${this.props.loginIdentity.firstName} ${this.props.loginIdentity.lastName}`,
                })
                break;
            case HM_MESSAGING.id :
                this.props.openMessaging();
                break;
            default:
        }

        event.originalEvent.preventDefault();
    }

    onMenuItemClick = (event) => {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
    }

    onRootMenuItemClick = (event) => {
        this.setState({
            menuActive: !this.state.menuActive
        });

        event.originalEvent.preventDefault();
    }

    onDocumentClick = (event) => {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

        if (!this.rightPanelClick) {
            this.setState({
                rightPanelActive: false
            })
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            rotateMenuButton: false,
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    stackEntry = (stack, entry) => {

        const ignoreCaps = (this.props.capabilities === undefined) || (entry.controlledBy === null);

        if (ignoreCaps || _.includes(this.props.capabilities, entry.controlledBy)) {
            stack.push({
                label: entry.label, icon: entry.menuIcon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, entry.impClass, entry.id, entry.tabIcon, entry.text, entry.prefSection));
                }
            });
        }
    };

    insertMailShots = () => {

        let stack = [];

        // const ignoreCaps = this.props.capabilities === undefined;

        this.stackEntry(stack, ME_EMAIL_MAIL_SHOTS);
        this.stackEntry(stack, ME_SMS_MAIL_SHOTS);

        return {
            label: ME_MAIL_SHOTS.label, icon: ME_MAIL_SHOTS.menuIcon,
            items:
                stack.map(entry => {
                    return entry;
                })
        };
    };

    insertUtilities = () => {

        let stack = [];

        // const ignoreCaps = this.props.capabilities === undefined;

        this.stackEntry(stack, ME_Housekeeping);

        // if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_DBBackup))
        //     stack = _.concat(stack, this.insertDatabase());

        this.stackEntry(stack, ME_UserCapabilities);
        this.stackEntry(stack, ME_PatientTemplates);
        this.stackEntry(stack, ME_SystemTemplates);
        this.stackEntry(stack, ME_TemplateNotes);
        this.stackEntry(stack, ME_UploadPayments);

        return {
            label: ME_Utilities.label, icon: ME_Utilities.menuIcon,
            items:
                stack.map(entry => {
                    return entry;
                })
        };
    };

    insertReferrers = () => {

        const ignoreCaps = this.props.capabilities === undefined;

        const keyId = `${SM_REFERRER_ADD.id}_${getAddReferrerTabIndex()}`;

        return (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_REFFERRES)) ? {
            label: 'Referrers', icon: ICON_REFERRERS,
            items: [
                {
                    label: SM_REFERRER_SEARCH.label, icon: SM_REFERRER_SEARCH.icon, command: () => {
                        this.props.onPCButtonClick(menuButtonTemplate(this.props, FindReferrers, SM_REFERRER_SEARCH.id, SM_REFERRER_SEARCH.tabIcon, SM_REFERRER_SEARCH.label, null));
                    }
                },
                {
                    label: SM_REFERRER_ADD.label, icon: SM_REFERRER_ADD.icon, command: () => {
                        this.props.onPCButtonClick(menuButtonTemplate(this.props, AddReferrer, keyId, SM_REFERRER_ADD.tabIcon, SM_REFERRER_ADD.label, null));
                    }
                },
            ]
        } : {}
    };

    insertLaboratories = () => {

        const ignoreCaps = true; // this.props.capabilities === undefined;

        const keyId = `${SM_LABORATORY_ADD.id}_${getAddLaboratoryTabIndex()}`;

        return (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_LABORATORIES)) ? {
            label: 'Laboratories', icon: ICON_LAB,
            items: [
                {
                    label: SM_LABORATORY_SEARCH.label, icon: SM_LABORATORY_SEARCH.icon, command: () => {
                        this.props.onPCButtonClick(menuButtonTemplate(this.props, FindLaboratory, SM_LABORATORY_SEARCH.id, SM_LABORATORY_SEARCH.tabIcon, SM_LABORATORY_SEARCH.label, null));
                    }
                },
                {
                    label: SM_LABORATORY_ADD.label, icon: SM_LABORATORY_ADD.icon, command: () => {
                        this.props.onPCButtonClick(menuButtonTemplate(this.props, AddLaboratory, keyId, SM_LABORATORY_ADD.tabIcon, SM_LABORATORY_ADD.label, null));
                    }
                },
                {
                    label: HM_WORK_REQUIRED_PICKUP.header, icon: HM_WORK_REQUIRED_PICKUP.icon, command: () => {
                        this.onShowMenuEntry({item: {target: HM_WORK_REQUIRED_PICKUP.id}});
                    }
                },
                {
                    label: HM_WORK_REQUIRED_RECEIVED.header, icon: HM_WORK_REQUIRED_RECEIVED.icon, command: () => {
                        this.onShowMenuEntry({item: {target: HM_WORK_REQUIRED_RECEIVED.id}});
                    }
                },
                {
                    label: 'Lab Work Reports', icon: ICON_LINE_CHART,
                    items: [
                        {
                            label: HM_WORK_REQUESTED_REPORT.header,
                            icon: HM_WORK_REQUESTED_REPORT.icon,
                            command: () => {
                                this.props.onPCButtonClick(menuButtonTemplate(this.props, WorkRequestedReport, SM_WORK_REQUESTED_REPORT.id, SM_WORK_REQUESTED_REPORT.tabIcon, SM_WORK_REQUESTED_REPORT.label, null));
                            }
                        },
                        {
                            label: HM_WORK_REQUIRED_REPORT.header, icon: HM_WORK_REQUIRED_REPORT.icon, command: () => {
                                this.props.onPCButtonClick(menuButtonTemplate(this.props, WorkRequiredReport, SM_WORK_REQUIRED_REPORT.id, SM_WORK_REQUIRED_REPORT.tabIcon, SM_WORK_REQUIRED_REPORT.label, null));
                            }
                        },
                        {
                            label: HM_WORK_RECEIVED_REPORT.header, icon: HM_WORK_RECEIVED_REPORT.icon, command: () => {
                                this.props.onPCButtonClick(menuButtonTemplate(this.props, WorkReceivedReport, SM_WORK_RECEIVED_REPORT.id, SM_WORK_RECEIVED_REPORT.tabIcon, SM_WORK_RECEIVED_REPORT.label, null));
                            }
                        },
                    ]
                },
            ]
        } : {}
    };

    insertReports = () => {

        let stack = [];

        const ignoreCaps = this.props.capabilities === undefined;

        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_OutstandingAccountsReport)) {
            stack.push({
                label: SM_REPORTS_OUTSTANDING_ACCOUNTS.label,
                icon: SM_REPORTS_OUTSTANDING_ACCOUNTS.icon,
                command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, OutstandingAccounts, SM_REPORTS_OUTSTANDING_ACCOUNTS.id, SM_REPORTS_OUTSTANDING_ACCOUNTS.tabIcon, SM_REPORTS_OUTSTANDING_ACCOUNTS.label, null));
                }
            });
        }

        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_AccountsInCreditReport)) {
            stack.push({
                label: SM_REPORTS_ACCOUNTS_IN_CREDIT.label,
                icon: SM_REPORTS_ACCOUNTS_IN_CREDIT.icon,
                command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, AccountsInCredit, SM_REPORTS_ACCOUNTS_IN_CREDIT.id, SM_REPORTS_ACCOUNTS_IN_CREDIT.tabIcon, SM_REPORTS_ACCOUNTS_IN_CREDIT.label, null));
                }
            });
        }
        stack = _.concat(stack, this.insertCharges());
        stack = _.concat(stack, this.insertRevenues());
        stack = _.concat(stack, this.insertCancellations());
        stack = _.concat(stack, this.insertNonAttendance());

        if (this.props.objects[COP_REPORTS] === 'true') {
            stack = _.concat(stack, this.insertCOPReports());
        }

        stack.push({
            label: SM_REPORTS_PATIENT_JOURNEY.label, icon: SM_REPORTS_PATIENT_JOURNEY.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, PatientJourney, SM_REPORTS_PATIENT_JOURNEY.id, SM_REPORTS_PATIENT_JOURNEY.tabIcon, SM_REPORTS_PATIENT_JOURNEY.label, null));
            }
        });

        stack = _.concat(stack, this.insertUtilisations());

        stack.push({
            label: SM_REPORTS_SMS_LIMITS.label, icon: SM_REPORTS_SMS_LIMITS.icon, command: () => {
                this.onShowMenuEntry({item: {target: HM_SMS_CREDIT_LIMIT.id}});
            }
        });

        return {
            label: SM_REPORTS.label, icon: SM_REPORTS.icon,
            items:
                stack.map(entry => {
                    return entry;
                })

        };
    };

    insertCharges = () => {

        const stack = [];

        const ignoreCaps = this.props.capabilities === undefined;

        stack.push({
            label: SM_REPORTS_UNINVOICED_CHARGES.label, icon: SM_REPORTS_UNINVOICED_CHARGES.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, UninvoicedCharges, SM_REPORTS_UNINVOICED_CHARGES.id, SM_REPORTS_UNINVOICED_CHARGES.tabIcon, SM_REPORTS_UNINVOICED_CHARGES.label, null));
            }
        });
        stack.push({
            label: SM_REPORTS_INCOMPLETE_CHARGES.label, icon: SM_REPORTS_INCOMPLETE_CHARGES.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, IncompleteCharges, SM_REPORTS_INCOMPLETE_CHARGES.id, SM_REPORTS_INCOMPLETE_CHARGES.tabIcon, SM_REPORTS_INCOMPLETE_CHARGES.label, null));
            }
        });

        return {
            label: SM_REPORTS_CHARGES.label, icon: SM_REPORTS_CHARGES.icon,
            items:
                stack.map(entry => {
                    return entry;
                })

        };
    };

    COP1ReportCommand = ({type, label, icon, description, filename}, cop1url) => {

        return {
            label,
            icon,
            command: () => {

                const url = `https://${ac.getBASEREPORTRESTURL()}${cop1url}?mcId=${ac.getMcId()}&creatorId=${this.props.loginIdentity.id}&type=${type}`;

                fetch(url, {
                    headers: new Headers({
                        'Authorization': ac.getAuthToken(),
                    }),
                })
                    .then(response => {

                        this.growl.show({
                            severity: 'info',
                            summary: `${label} Generation`,
                            detail: `${description} has started`,
                            sticky: false,
                        });
                    });
            }
        }
    }

    insertCOPReports = () => {

        const stack = [];

        const ignoreCaps = this.props.capabilities === undefined;

        stack.push(this.COP1ReportCommand(SM_REPORTS_COP_REPORT1_10, RES_REPORT_COP_REPORT1.XLS.url));
        stack.push(this.COP1ReportCommand(SM_REPORTS_COP_REPORT1_15, RES_REPORT_COP_REPORT1.XLS.url));
        stack.push(this.COP1ReportCommand(SM_REPORTS_COP_REPORT1_20, RES_REPORT_COP_REPORT1.XLS.url));

        stack.push({
            label: SM_REPORTS_COP_REPORT9.label,
            icon: SM_REPORTS_COP_REPORT9.icon,
            command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, CopReport9, SM_REPORTS_COP_REPORT9.id, SM_REPORTS_COP_REPORT9.tabIcon, SM_REPORTS_COP_REPORT9.label, null));
            }
        });

        stack.push({
            label: SM_REPORTS_COP_MONTHLY_TRANS.label,
            icon: SM_REPORTS_COP_MONTHLY_TRANS.icon,
            command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, MonthlyTransactions, SM_REPORTS_COP_MONTHLY_TRANS.id, SM_REPORTS_COP_MONTHLY_TRANS.tabIcon, SM_REPORTS_COP_MONTHLY_TRANS.label, null));
            }
        });

        return {
            label: SM_REPORTS_COP_REPORTS.label, icon: SM_REPORTS_COP_REPORTS.icon,
            items:
                stack.map(entry => {
                    return entry;
                })

        };
    };

    insertRevenues = () => {

        const stack = [];

        const ignoreCaps = this.props.capabilities === undefined;

        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_DailyIncomeReport))
            stack.push({
                label: SM_REPORTS_DAILY_CASH.label, icon: SM_REPORTS_DAILY_CASH.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, DailyCashBook, SM_REPORTS_DAILY_CASH.id, SM_REPORTS_DAILY_CASH.tabIcon, SM_REPORTS_DAILY_CASH.label, null));
                }
            });
        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_DailyTransactionsReport))
            stack.push({
                label: SM_REPORTS_DAILY_TRANSACTIONS.label, icon: SM_REPORTS_DAILY_TRANSACTIONS.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, DailyTransactions, SM_REPORTS_DAILY_TRANSACTIONS.id, SM_REPORTS_DAILY_TRANSACTIONS.tabIcon, SM_REPORTS_DAILY_TRANSACTIONS.label, null));
                }
            });
        // pr-392
        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_MonthlyIncomeReport))
            stack.push({
                label: SM_REPORTS_WEEKS_SHARE.label, icon: SM_REPORTS_WEEKS_SHARE.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, WeeklyShare, SM_REPORTS_WEEKS_SHARE.id, SM_REPORTS_WEEKS_SHARE.tabIcon, SM_REPORTS_WEEKS_SHARE.label, null));
                }
            });

        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_MonthlyCashReport))
            stack.push({
                label: SM_REPORTS_MONTHS_CASH.label, icon: SM_REPORTS_MONTHS_CASH.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, MonthlyCash, SM_REPORTS_MONTHS_CASH.id, SM_REPORTS_MONTHS_CASH.tabIcon, SM_REPORTS_MONTHS_CASH.label, null));
                }
            });
        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_MonthlyIncomeReport))
            stack.push({
                label: SM_REPORTS_MONTHS_SHARE.label, icon: SM_REPORTS_MONTHS_SHARE.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, MonthlyShare, SM_REPORTS_MONTHS_SHARE.id, SM_REPORTS_MONTHS_SHARE.tabIcon, SM_REPORTS_MONTHS_SHARE.label, null));
                }
            });
        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_YearlyCashReport))
            stack.push({
                label: SM_REPORTS_YEARS_CASH.label, icon: SM_REPORTS_YEARS_CASH.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, YearlyCash, SM_REPORTS_YEARS_CASH.id, SM_REPORTS_YEARS_CASH.tabIcon, SM_REPORTS_YEARS_CASH.label, null));
                }
            });
        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_YearlyRevShareReport))
            stack.push({
                label: SM_REPORTS_YEARS_SHARE.label, icon: SM_REPORTS_YEARS_SHARE.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, YearlyShare, SM_REPORTS_YEARS_SHARE.id, SM_REPORTS_YEARS_SHARE.tabIcon, SM_REPORTS_YEARS_SHARE.label, null));
                }
            });
        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_PAYMENTPLANREPORT))
            stack.push({
                label: SM_REPORTS_PAYMENT_PLANS.label, icon: SM_REPORTS_PAYMENT_PLANS.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, PaymentPlanning, SM_REPORTS_PAYMENT_PLANS.id, SM_REPORTS_PAYMENT_PLANS.tabIcon, SM_REPORTS_PAYMENT_PLANS.label, null));
                }
            });
        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_VoidedTransactionsReport))
            stack.push({
                label: SM_REPORTS_VOIDED_TRANSACTIONS.label, icon: SM_REPORTS_VOIDED_TRANSACTIONS.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, Voids, SM_REPORTS_VOIDED_TRANSACTIONS.id, SM_REPORTS_VOIDED_TRANSACTIONS.tabIcon, SM_REPORTS_VOIDED_TRANSACTIONS.label, null));
                }
            });
        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_WrittenOffAccountsReport))
            stack.push({
                label: SM_REPORTS_WRITTEN_OFFS.label, icon: SM_REPORTS_WRITTEN_OFFS.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, WriteOffs, SM_REPORTS_WRITTEN_OFFS.id, SM_REPORTS_WRITTEN_OFFS.tabIcon, SM_REPORTS_WRITTEN_OFFS.label, null));
                }
            });
        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_CostCentreReport))
            stack.push({
                label: SM_REPORTS_COST_CENTERS.label, icon: SM_REPORTS_COST_CENTERS.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, CostCenters, SM_REPORTS_COST_CENTERS.id, SM_REPORTS_COST_CENTERS.tabIcon, SM_REPORTS_COST_CENTERS.label, null));
                }
            });

        return {
            label: SM_REPORTS_REVENUES.label, icon: SM_REPORTS_REVENUES.icon,
            items:
                stack.map(entry => {
                    return entry;
                })

        };
    };

    insertCancellations = () => {

        const stack = [];

        // const ignoreCaps = this.props.capabilities === undefined;

        stack.push({
            label: SM_REPORTS_DAILY_CANCELS.label, icon: SM_REPORTS_DAILY_CANCELS.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, DailyCancellations, 'dailyCancellations', SM_REPORTS_DAILY_CANCELS.tabIcon, SM_REPORTS_DAILY_CANCELS.label, null));
            }
        });
        stack.push({
            label: SM_REPORTS_WEEKLY_CANCELS.label, icon: SM_REPORTS_WEEKLY_CANCELS.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, WeeklyCancellations, SM_REPORTS_WEEKLY_CANCELS.id, SM_REPORTS_WEEKLY_CANCELS.tabIcon, SM_REPORTS_WEEKLY_CANCELS.label, null));
            }
        });

        return {
            label: SM_REPORTS_CANCELS.label, icon: SM_REPORTS_CANCELS.icon,
            items:
                stack.map(entry => {
                    return entry;
                })

        };
    };

    insertDiary = () => {

        const stack = [];

        // const ignoreCaps = this.props.capabilities === undefined;

        stack.push({
            label: HM_DIARY_SORT_ORDER.header, icon: HM_DIARY_SORT_ORDER.menuIcon, command: () => {
                this.onShowMenuEntry({item: {target: HM_DIARY_SORT_ORDER.id}});
            }
        });
        stack.push({
            label: SM_PRACTICE_DIARY.label, icon: SM_PRACTICE_DIARY.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, PracticeDiary, SM_PRACTICE_DIARY.id, SM_PRACTICE_DIARY.tabIcon, SM_PRACTICE_DIARY.label, null));
            }
        });
        stack.push({
            label: SM_CLINICIAN_DIARY.label, icon: SM_CLINICIAN_DIARY.icon, command: () => {
                this.props.sendMessage({type: SM_CLINICIAN_DIARY.id, payload: null})
            }
        });
        stack.push({
            label: SM_PRACTICE_WEEK_DIARY.label, icon: SM_PRACTICE_WEEK_DIARY.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, PracticeWeekDiary, SM_PRACTICE_WEEK_DIARY.id, SM_PRACTICE_WEEK_DIARY.tabIcon, SM_PRACTICE_WEEK_DIARY.label, null));
            }
        });

        return {
            label: 'Diary', icon: ICON_DIARY,
            items:
                stack.map(entry => {
                    return entry;
                })

        };
    };

    onIssuePaymentPlanInvoicesConfirm = () => {

        const token = Math.random().toString(36).slice(2);
        this.setState({token, [HM_ISSUE_PAYMENT_PLAN_INVOICES.id]: false}, () => {
            this.props.issuePaymentPlanInvoices({token: this.state.token, issuedById: this.props.loginIdentity.id});
        })
    }

    insertPatients = () => {

        let stack = [];

        const ignoreCaps = this.props.capabilities === undefined;

        stack.push({
            label: SM_PATIENT_SEARCH.label, icon: SM_PATIENT_SEARCH.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, FindPatients, SM_PATIENT_SEARCH.id, SM_PATIENT_SEARCH.tabIcon, SM_PATIENT_SEARCH.label, null));
            }
        });
        this.stackEntry(stack, ME_ADD_PATIENT);
        stack.push({
            label: SM_RECALLS_DUE.label, icon: SM_RECALLS_DUE.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, RecallsDue, SM_RECALLS_DUE.id, SM_RECALLS_DUE.tabIcon, SM_RECALLS_DUE.label, null));
            }
        });

        stack.push({
            label: SM_REPORTS_RECALL_MANAGEMENT.label, icon: SM_REPORTS_RECALL_MANAGEMENT.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, RecallManagement, SM_REPORTS_RECALL_MANAGEMENT.id, SM_REPORTS_RECALL_MANAGEMENT.tabIcon, SM_REPORTS_RECALL_MANAGEMENT.label, null));
            }
        });

        stack.push({
            label: SM_REMINDERS_DUE.label, icon: SM_REMINDERS_DUE.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, RemindersDue, SM_REMINDERS_DUE.id, SM_REMINDERS_DUE.tabIcon, SM_REMINDERS_DUE.label, null));
            }
        });
        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_PAYPLAN_ISSUE))
            stack.push({
                label: SM_PAYMENT_PLAN_ISSUE_INVOICES.label, icon: SM_PAYMENT_PLAN_ISSUE_INVOICES.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, IssueInstallmentInvoices, SM_PAYMENT_PLAN_ISSUE_INVOICES.id, SM_PAYMENT_PLAN_ISSUE_INVOICES.tabIcon, SM_PAYMENT_PLAN_ISSUE_INVOICES.label, null));
                }
            });
        stack.push({
            label: SM_TABLET_REGISTRATIONS.label, icon: SM_TABLET_REGISTRATIONS.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, TabletRegistrations, SM_TABLET_REGISTRATIONS.id, SM_TABLET_REGISTRATIONS.tabIcon, SM_TABLET_REGISTRATIONS.label, null));
            }
        });

        stack = _.concat(stack, this.insertMailShots());

        return {
            label: 'Patients', icon: ICON_PATIENTS,
            items:
                stack.map(entry => {
                    return entry;
                })
        };
    };

    insertOnline = () => {

        const stack = [];

        stack.push({
            label: SM_ONLINE_REGISTRATION.label, icon: SM_ONLINE_REGISTRATION.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, WeeklyRegistrations, SM_ONLINE_REGISTRATION.id, SM_ONLINE_REGISTRATION.tabIcon, SM_ONLINE_REGISTRATION.label, null));
            }
        });

        if (this.props.practiceDetails.onlineBooking) {
            stack.push({
                label: SM_ONLINE_BOOKING.label, icon: SM_ONLINE_BOOKING.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, OnlineBookings, SM_ONLINE_BOOKING.id, SM_ONLINE_BOOKING.tabIcon, SM_ONLINE_BOOKING.label, null));
                }
            });
        }

        if (this.props.practiceDetails.videoConferencing) {
            stack.push({
                label: SM_VIDEO_CONFERENCES.label, icon: SM_VIDEO_CONFERENCES.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, VideoAppointments, SM_VIDEO_CONFERENCES.id, SM_VIDEO_CONFERENCES.tabIcon, SM_VIDEO_CONFERENCES.label, null));
                }
            });
        }

        return {
            label: SM_ONLINE.label, icon: SM_ONLINE.icon,
            items:
                stack.map(entry => {
                    return entry;
                })

        };
    }

    insertQuestionnaires = () => {

        let stack = [];

        stack.push({
            label: SM_INCOMPLETE_QUESTIONNAIRES.menu, icon: SM_INCOMPLETE_QUESTIONNAIRES.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, IncompleteQuestionnaires, SM_INCOMPLETE_QUESTIONNAIRES.id, SM_INCOMPLETE_QUESTIONNAIRES.tabIcon, SM_INCOMPLETE_QUESTIONNAIRES.label, null));
            }
        });

        stack.push({
            label: SM_PRECOMPLETE_QUESTIONNAIRES.menu, icon: SM_PRECOMPLETE_QUESTIONNAIRES.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, PreCompletedQuestionnaires, SM_PRECOMPLETE_QUESTIONNAIRES.id, SM_PRECOMPLETE_QUESTIONNAIRES.tabIcon, SM_PRECOMPLETE_QUESTIONNAIRES.label, null));
            }
        });

        stack.push({
            label: SM_COMPLETED_QUESTIONNAIRES.menu, icon: SM_COMPLETED_QUESTIONNAIRES.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, CompletedQuestionnaires, SM_COMPLETED_QUESTIONNAIRES.id, SM_COMPLETED_QUESTIONNAIRES.tabIcon, SM_COMPLETED_QUESTIONNAIRES.label, null));
            }
        });

        return {
            label: SM_QUESTIONNAIRES.label, icon: SM_QUESTIONNAIRES.icon,
            items:
                stack.map(entry => {
                    return entry;
                })

        };
    }

    insertXRays = () => {

        let stack = [];

        stack.push({
            label: SM_XRAY_SUMMARY.menu, icon: SM_XRAY_SUMMARY.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, WeeklyXraySummary, SM_XRAY_SUMMARY.id, SM_XRAY_SUMMARY.tabIcon, SM_XRAY_SUMMARY.label, null));
            }
        });

        stack.push({
            label: SM_XRAY_REPORT.menu, icon: SM_XRAY_REPORT.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, WeeklyXrayReport, SM_XRAY_REPORT.id, SM_XRAY_REPORT.tabIcon, SM_XRAY_REPORT.label, null));
            }
        });

        return {
            label: SM_XRAYS.label, icon: SM_XRAYS.icon,
            items:
                stack.map(entry => {
                    return entry;
                })

        };
    }

    insertSedation = () => {

        let stack = [];

        stack.push({
            label: SM_SEDATION_SUMMARY.menu, icon: SM_SEDATION_SUMMARY.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, WeeklySedationSummary, SM_SEDATION_SUMMARY.id, SM_SEDATION_SUMMARY.tabIcon, SM_SEDATION_SUMMARY.label, null));
            }
        });

        stack.push({
            label: SM_SEDATION_REPORT.menu, icon: SM_SEDATION_REPORT.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, WeeklySedationReport, SM_SEDATION_REPORT.id, SM_SEDATION_REPORT.tabIcon, SM_SEDATION_REPORT.label, null));
            }
        });

        return {
            label: SM_SEDATION.label, icon: SM_SEDATION.icon,
            items:
                stack.map(entry => {
                    return entry;
                })

        };
    }

    insertCompliance = () => {

        let stack = [];

        stack = _.concat(stack, this.insertQuestionnaires());
        stack = _.concat(stack, this.insertXRays());
        stack = _.concat(stack, this.insertSedation());

        return {
            label: SM_COMPLIANCE.label, icon: SM_COMPLIANCE.icon,
            items:
                stack.map(entry => {
                    return entry;
                })

        };
    }

    insertUsers = () => {

        const stack = [];

        const ignoreCaps = this.props.capabilities === undefined;

        stack.push({
            label: SM_USER_SEARCH.label, icon: SM_USER_SEARCH.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, FindUsers, SM_USER_SEARCH.id, SM_USER_SEARCH.tabIcon, SM_USER_SEARCH.label, null));
            }
        });
        this.stackEntry(stack, ME_ADD_USER);
        stack.push({
            label: HM_SHOW_WITH.label, icon: HM_SHOW_WITH.menuIcon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, ShowWith, HM_SHOW_WITH.id, HM_SHOW_WITH.tabIcon, HM_SHOW_WITH.label, null));
            }
        });
        stack.push({
            label: SM_TASKS.label, icon: SM_TASKS.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, OutstandingTasks, SM_TASKS.id, SM_TASKS.tabIcon, SM_TASKS.label, null));
            }
        });
        stack.push({
            label: SM_ACCOUNT_GROUPS.label, icon: SM_ACCOUNT_GROUPS.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, AccountGroups, SM_ACCOUNT_GROUPS.id, SM_ACCOUNT_GROUPS.tabIcon, SM_ACCOUNT_GROUPS.label, null));
            }
        });
        if (ignoreCaps || _.includes(this.props.capabilities, Capabilities.AID_AddUser))
            stack.push({
                label: SM_TRAINING.label, icon: SM_TRAINING.icon, command: () => {
                    this.onShowMenuEntry({item: {target: HM_BOOK_TRAINING.id}});
                }
            });

        return {
            label: 'Users', icon: ICON_USERS,
            items:
                stack.map(entry => {
                    return entry;
                })

        };
    };

    insertNonAttendance = () => {

        const stack = [];

        // const ignoreCaps = this.props.capabilities === undefined;

        stack.push({
            label: SM_REPORTS_WEEKLY_NON_ATTEND.label, icon: SM_REPORTS_WEEKLY_NON_ATTEND.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, WeeklyFails, SM_REPORTS_WEEKLY_NON_ATTEND.id, SM_REPORTS_WEEKLY_NON_ATTEND.tabIcon, SM_REPORTS_WEEKLY_NON_ATTEND.label, null));
            }
        });

        return {
            label: SM_REPORTS_ATTEND.label, icon: SM_REPORTS_ATTEND.icon,
            items:
                stack.map(entry => {
                    return entry;
                })

        };
    };

    insertUtilisations = () => {

        const stack = [];

        stack.push({
            label: SM_REPORTS_CLINICIAN_USAGE.label, icon: SM_REPORTS_CLINICIAN_USAGE.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, ClinicianUsage, SM_REPORTS_CLINICIAN_USAGE.id, SM_REPORTS_CLINICIAN_USAGE.tabIcon, SM_REPORTS_CLINICIAN_USAGE.label, null));
            }
        });

        stack.push({
            label: SM_REPORTS_CODE_USAGE.label, icon: SM_REPORTS_CODE_USAGE.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, TreatmentCodeUsage, SM_REPORTS_CODE_USAGE.id, SM_REPORTS_CODE_USAGE.tabIcon, SM_REPORTS_CODE_USAGE.label, null));
            }
        });

        stack.push({
            label: SM_REPORTS_CODE_SUMMARY.label, icon: SM_REPORTS_CODE_SUMMARY.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, TreatmentCodeSummary, SM_REPORTS_CODE_SUMMARY.id, SM_REPORTS_CODE_SUMMARY.tabIcon, SM_REPORTS_CODE_SUMMARY.label, null));
            }
        });

        return {
            label: SM_REPORTS_UTILISATION.label, icon: SM_REPORTS_UTILISATION.icon,
            items:
                stack.map(entry => {
                    return entry;
                })

        };
    };

    createMenu = () => {

        if (this.menu.length !== 0) return;

        this.menu.push(this.insertUtilities());
        this.menu.push(
            {
                label: ME_Preferences.label, icon: ME_Preferences.menuIcon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, Preferences, ME_Preferences.id, ME_Preferences.tabIcon, ME_Preferences.label, null));
                }
            },
        );
        this.menu.push(this.insertDiary());
        this.menu.push(this.insertPatients());
        this.menu.push(
            {
                label: SM_PATIENT_ACCESS.label, icon: SM_PATIENT_ACCESS.icon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, PatientAccess, SM_PATIENT_ACCESS.id, SM_PATIENT_ACCESS.tabIcon, SM_PATIENT_ACCESS.label, null));
                }
            }
        )

        this.menu.push(this.insertOnline());

        this.menu.push(this.insertUsers());
        this.menu.push(this.insertCompliance());

        this.menu.push(this.insertReports());
        //
        // this.menu.push(
        //     {
        //         label: SM_ANALYTICS.label, icon: SM_ANALYTICS.icon, command: () => {
        //             this.props.onPCButtonClick(menuButtonTemplate(this.props, KPIs, SM_ANALYTICS.id, SM_ANALYTICS.tabIcon, SM_ANALYTICS.label, null));
        //         }, disabled: false,
        //     }
        // );

        if (_.includes(this.props.capabilities, Capabilities.AID_REFFERRES)) {
            this.menu.push(this.insertReferrers());
        }

        const laboratories = this.insertLaboratories()
        this.menu.push(laboratories);

        if (_.includes(this.props.capabilities, Capabilities.AID_UDACLAIMS)) {
            this.menu.push(
                {
                    label: SM_NHS_MANAGEMENT.label, icon: SM_NHS_MANAGEMENT.icon, command: () => {
                        this.props.onPCButtonClick(menuButtonTemplate(this.props, NHSManagement, SM_NHS_MANAGEMENT.id, SM_NHS_MANAGEMENT.tabIcon, SM_NHS_MANAGEMENT.label, null));
                    }
                }
            );
        }
        this.menu.push(
            {
                label: 'Help', icon: ICON_HELP, command: () => {
                    window.open(KNOWLEDGE_BASE);
                }
            }
        );
    }

    forcedLogout = () => {

        this.setState({[HM_RELEASE_NOTE.id]: false}, () => {
            window.location.hash = '/';
        });
    }

    markWorkReceived = (labEntry) => {

        const _labEntry = _.cloneDeep(labEntry);
        _labEntry.status = WORK_STATUS_RECEIVED_FROM_LAB.name;
        this.setState({
            labEntry: null,
            namePattern: '',
            barcodePattern: '',
            [HM_WORK_REQUIRED_RECEIVED.id]: false
        }, () => {
            _labEntry.appointmentId = this.props.appointmentId;
            this.props.markWorkReceived(_labEntry);
            this.props.stateRequest(RES_WORK_REQUIRED.CLEAR);
        })
    }

    showDialogs() {

        if (this.state[HM_ABOUT_CHIRAL.id]) {

            const header = (
                <i className='fa aboutChiral'/>
            );

            return (
                <Dialog header='About'
                        onHide={() => this.onHideMenuEntry(HM_ABOUT_CHIRAL.id)}
                        visible={true}
                >
                    <Card title={HM_ABOUT_CHIRAL.header}
                          className='ui-card-shadow'
                          header={header}
                    >
                        <div>
                            Chiral Systems Ltd <i className="far fa-copyright"/> 2020
                            v{packageJson.version}
                        </div>
                        <div>
                            <hr/>
                            Gateway Active : {this.props.gatewayActive ? `Yes (${this.props.gatewayVersion})` : `No`}
                            <hr/>
                        </div>
                        <div>
                            <i className="fas fa-at"/>{' '}support@chiralsystems.com{' '}
                            <Button style={{float: 'right'}}
                                    label={`Release Note ${packageJson.version}`}
                                    onClick={() => {
                                        this.setState({[HM_ABOUT_CHIRAL.id]: false}, () => {
                                            window.open(HELP_RELEASE_NOTES);
                                        })
                                    }}
                            />
                        </div>
                    </Card>
                </Dialog>
            )
        } else if (this.state[HM_RELEASE_NOTE.id]) {

            return (
                <Dialog header={`Release Note ${packageJson.version}`}
                        onHide={() => this.onHideMenuEntry(HM_RELEASE_NOTE.id)}
                        visible={true}
                >
                    <ScrollPanel style={{width: '100%', height: '75vh'}} className='custom'>
                        {rn1_7_48()}
                    </ScrollPanel>
                </Dialog>
            )
        } else if (this.state[HM_DIARY_SORT_ORDER.id]) {

            return (
                <DiarySortOrder onHideDialog={() => this.onHideMenuEntry(HM_DIARY_SORT_ORDER.id)}
                />
            )
        } else if (this.state[HM_SMS_CREDIT_LIMIT.id]) {

            return (
                <SMSCreditLimit onHideDialog={() => this.onHideMenuEntry(HM_SMS_CREDIT_LIMIT.id)}
                />
            )
        } else if (this.state[HM_BOOK_TRAINING.id]) {

            return (
                <BookTraining onHideDialog={() => this.onHideMenuEntry(HM_BOOK_TRAINING.id)}
                />
            )
        } else if (this.state[HM_ISSUE_PAYMENT_PLAN_INVOICES.id]) {
            return ShowQuestionDialog(this, HM_ISSUE_PAYMENT_PLAN_INVOICES, this.onIssuePaymentPlanInvoicesConfirm);

        } else if (this.state[HM_WORK_REQUIRED_PICKUP.id]) {

            const patterns = {namePattern: this.state.namePattern, barcodePattern: this.state.barcodePattern};
            let labWorkResults;
            let labEntry;

            if (this.state.namePattern.length < 3 && this.state.barcodePattern < 3) {
                labWorkResults = [];
                labEntry = null;
            } else {
                labWorkResults = this.props.labWorkResults;
                labEntry = this.state.labEntry;
            }

            return (
                <DiaryPickupWorkRequired
                    onChange={this.onChange}
                    patterns={patterns}
                    labEntry={labEntry}
                    users={this.props.usersShort}
                    loginIdentity={this.props.loginIdentity}
                    searchForWorkToPickup={(e) => {
                        this.setState({labEntry: null, namePattern: e.pattern, barcodePattern: ''}, () => {
                            if (e.length < 3) return;
                            else this.props.searchForWorkToPickup({pattern: this.state.namePattern});
                        });
                    }}
                    searchForWorkToPickupByBarcode={(e) => {
                        this.setState({labEntry: null, namePattern: '', barcodePattern: e.pattern}, () => {
                            if (e.length < 3) return;
                            else this.props.searchForWorkToPickupByBarcode({pattern: this.state.barcodePattern});
                        });
                    }}
                    labWorkResults={labWorkResults}
                    markWorkPickedup={this.onPickup}
                    onHideDialog={(target) => {
                        this.setState({labEntry: null, namePattern: '', barcodePattern: ''}, () => {
                            this.onHideMenuEntry(target);
                            this.props.stateRequest(RES_WORK_REQUIRED.CLEAR);
                        })
                    }}
                    target={HM_WORK_REQUIRED_PICKUP}
                />
            )

        } else if (this.state[HM_WORK_REQUIRED_RECEIVED.id]) {

            const patterns = {namePattern: this.state.namePattern, barcodePattern: this.state.barcodePattern};
            let labWorkResults;
            let labEntry;

            if (this.state.namePattern.length < 3 && this.state.barcodePattern < 3) {
                labWorkResults = [];
                labEntry = null;
            } else {
                labWorkResults = this.props.labWorkResults;
                labEntry = this.state.labEntry;
            }

            return (
                <DiaryReceiveWorkRequired
                    onChange={this.onChange}
                    patterns={patterns}
                    labEntry={labEntry}
                    users={this.props.usersShort}
                    loginIdentity={this.props.loginIdentity}
                    searchForWorkRequired={(e) => {
                        this.setState({labEntry: null, namePattern: e.pattern, barcodePattern: ''}, () => {
                            if (e.length < 3) return;
                            else this.props.searchForWorkRequired({pattern: this.state.namePattern});
                        });
                    }}
                    searchForWorkByBarcode={(e) => {
                        this.setState({labEntry: null, namePattern: '', barcodePattern: e.pattern}, () => {
                            if (e.length < 3) return;
                            else this.props.searchForWorkByBarcode({pattern: this.state.barcodePattern});
                        });
                    }}
                    labWorkResults={labWorkResults}
                    markWorkReceived={this.markWorkReceived}
                    onHideDialog={(target) => {
                        this.setState({labEntry: null, namePattern: '', barcodePattern: ''}, () => {
                            this.onHideMenuEntry(target);
                            this.props.stateRequest(RES_WORK_REQUIRED.CLEAR);
                        })
                    }}
                    target={HM_WORK_REQUIRED_RECEIVED}
                />
            )
        } else if (this.state[HM_FORCE_LOGOUT.id]) {

            const footer = (
                <div>
                    <Button label="Logout" icon={HM_FORCE_LOGOUT.icon} onClick={
                        () => {
                            this.forcedLogout();
                        }
                    }/>
                </div>
            );

            return (
                <Dialog header={HM_FORCE_LOGOUT.header}
                        onHide={this.forcedLogout}
                        visible={true}
                        footer={footer}
                >
                    <Card>{HM_FORCE_LOGOUT.message}</Card>
                </Dialog>
            )
        }
    }

    render() {

        if (!this.props.objectsLoaded) {
            return <React.Fragment/>;
        }

        this.createMenu();

        let layoutClassName = classNames('layout-wrapper', {
            'menu-layout-static': this.state.layoutMode !== 'overlay',
            'menu-layout-overlay': this.state.layoutMode === 'overlay',
            'layout-menu-overlay-active': this.state.overlayMenuActive,
            'menu-layout-slim': this.state.layoutMode === 'slim',
            'menu-layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-menu-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-menu-static-active': this.state.staticMenuMobileActive
        });
        let menuClassName = classNames('layout-menu-container', {'layout-menu-dark': this.state.darkMenu});

        return (
            <TabErrorBoundary>
                <div className={layoutClassName} onClick={this.onDocumentClick} id='mainPCC'>

                    <WebsocketHandler/>

                    <Toast ref={(el) => this.growl = el}/>
                    {this.showDialogs()}

                    <audio ref={this.audioRef}>
                        <source src={beep} type='audio/wav'/>
                    </audio>

                    <div>
                        <AppTopbar profileMode={this.state.profileMode} horizontal={this.props.horizontal}
                                   topbarMenuActive={this.state.topbarMenuActive}
                                   activeTopbarItem={this.state.activeTopbarItem}
                                   onMenuButtonClick={this.onMenuButtonClick}
                                   onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                                   onTopbarItemClick={this.onTopbarItemClick}
                                   practiceDetails={this.props.practiceDetails}
                                   loginIdentity={this.props.loginIdentity}
                        />

                        <div className={menuClassName} onClick={this.onMenuClick}>
                            <div ref={(el) => this.layoutMenuScroller = el} className="nano">
                                <div className="nano-content menu-scroll-content">
                                    {(this.state.profileMode === 'inline' && this.state.layoutMode !== 'horizontal')}
                                    <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick}
                                             onRootMenuItemClick={this.onRootMenuItemClick}
                                             layoutMode={this.state.layoutMode} active={this.state.menuActive}/>
                                </div>
                            </div>
                        </div>

                        <Switch>
                            <Route path="/main/practiceControlCenter" exact component={PracticeControlCenter}/>
                        </Switch>

                        <div className="layout-mask"/>

                        {/*<AppFooter/>*/}
                        <span className="bg-shape bg-shape-2"/>
                        <span className="bg-shape bg-shape-1"/>
                        <span className="bg-shape bg-shape-3"/>
                    </div>
                </div>
            </TabErrorBoundary>
        );
    }
}

const mapStateToProps = (state, ownProps) => {

    const objects = getObjectStoreIds(state, ownProps, requiredObject);

    const practiceDetailsLoaded = Boolean(state.preferences.practiceDetailsLoaded);
    const practiceDetails = practiceDetailsLoaded ? state.preferences.practiceDetails : [];

    const labWorkSearchComplete = state.findLaboratories.labWorkSearchComplete;
    const labWorkResults = state.findLaboratories.labWorkResults;

    return {
        message: state.stateManagement.message,
        wsmessage: state.websockets.message,
        messageToSend: state.websockets.userMessageToSend,

        capabilities: state.login.capabilities,
        objectsLoaded: state.objectStore.loginObjectRequestLoaded,
        objects,

        loggedIn: state.login.loggedIn,

        labWorkSearchComplete,
        labWorkResults,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        loginIdentity: state.login.user,
        practiceDetailsLoaded,
        practiceDetails,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        ediToken: state.nhsManagement.token,

        gatewayActive: state.login.gatewayActive,
        gatewayVersion: state.login.gatewayVersion,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsersShort: () => dispatch(getAllUsers()),
        getUserDiaryZones: (id) => dispatch(getHSKResource(RES_HOUSEKEEPING_ZONES.GET, {clinicianId: id})),

        checkForGateway: () => dispatch(checkForGateway(RES_CHECK_FOR_GATEWAY.CHECK)),
        getCurrentConfiguration: () => dispatch(getCurrentConfiguration(RES_GATEWAY.GET)),

        sendMessage: (message) => dispatch(messageBus(message)),
        getSMSCreditLimit: () => dispatch(getSMSCreditLimit(RES_PATIENT_SMS.CREDIT_LIMIT)),
        issuePaymentPlanInvoices: (params) => dispatch(issuePaymentPlanInvoices(RES_PAYMENT_PLANNING.ISSUE, params)),
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET_LOGIN, objectList)),

        searchForWorkToPickup: (params) => dispatch(getLabResource(RES_WORK_REQUIRED.SEARCH_REQUESTED, params)),
        searchForWorkToPickupByBarcode: (params) => dispatch(getLabResource(RES_WORK_REQUIRED.SEARCH_REQUESTED_BY_BARCODE, params)),
        markWorkPickup: (labEntry) => dispatch(markWorkStatus(RES_WORK_REQUIRED.LAB_PICK_UP, labEntry)),

        searchForWorkRequired: (params) => dispatch(getLabResource(RES_WORK_REQUIRED.SEARCH, params)),
        searchForWorkByBarcode: (params) => dispatch(getLabResource(RES_WORK_REQUIRED.SEARCH_BY_BARCODE, params)),
        markWorkReceived: (labEntry) => dispatch(markWorkStatus(RES_WORK_REQUIRED.RECEIVED, labEntry)),

        openMessaging: () => dispatch(openMessaging()),

        stateRequest: (source) => dispatch(stateRequest(source)),

        dispatchMessage: (data) => dispatch(dispatchMessage(WSM_SEND_USER, data)),
    };
};

const LoggedIn = connect(mapStateToProps, mapDispatchToProps)(ConnectedLoggedIn);

export default LoggedIn;