import * as Constants from "../../../Constants";
import {ac} from "../../../index";
import {SR_CLINIC} from "../../../Constants";

export const doDragEvent = (event, notify) => {

    let type = null;

    switch (event.discriminator) {
        case SR_CLINIC:
            type = Constants.APP_DRAG_DELTA;
            break;
        default :
            type = Constants.EVENT_DRAG_DELTA;
            break;
    }
    const data = {
        mcId: ac.getMcId(),
        type,
        id:event.id,
        start: event.start,
        end: event.end,
        notify,
    };

    ac.fetchClient().post('/Appointments/dragEvent', data)
        .then(res => {
        })
};

export const doResizeEvent = (event, delta) => {

    let type = null;

    switch (event.discriminator) {
        case SR_CLINIC:
            type = Constants.APP_DRAG_DELTA;
            break;
        default :
            type = Constants.EVENT_DRAG_DELTA;
            break;
    }

    const data = {
        mcId: ac.getMcId(),
        type,
        id:event.id,
        start: event.start,
        end: event.end,
    };

    ac.fetchClient().post('/Appointments/resizeEvent', data)
        .then(res => {
        })
};
