import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import _ from "lodash";
import {TB_AddRule, TB_EditRule} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {ScheduleRule} from "../Sections/ScheduleRule";
import * as DefaultData from "../../DefaultData";

export default class AddScheduleRule extends Component {

    constructor(props) {
        super();

        if (props.editing) {
            this.state = {
                rule: props.rule,
            }
        } else {
            this.state = {
                rule: _.cloneDeep(DefaultData.ScheduleRule(ac.getMcId(), props.practiceDetails)),
            }
        }

        if (!props.editing) {
            this.state.rule.user = props.createdFor;
            this.state.rule.createdOn = new Date();
            this.state.rule.startingOn = new Date();
            this.state.rule.endingOn = new Date();
        }
        this.onChange = this.onChange.bind(this);
        this.copyPreviousWeek = this.copyPreviousWeek.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

       // this.setState(state);
    }

    copyPreviousWeek(week) {

        const rule = {...this.state.rule};

        for (let day=0;day<7;day++) {

            rule.days[week][day] = rule.days[week-1][day];

            rule.startHour[week][day] = rule.startHour[week-1][day];
            rule.startMin[week][day] = rule.startMin[week-1][day];

            rule.endHour[week][day] = rule.endHour[week-1][day];
            rule.endMin[week][day] = rule.endMin[week-1][day];

            ////////////////////// LUNCH /////////////////////////

            rule.endHour[week][day] = rule.endHour[week-1][day];
            rule.endMin[week][day] = rule.endMin[week-1][day];

            rule.lunchShown[week][day] = rule.lunchShown[week-1][day];

            rule.lbStartHour[week][day] = rule.lbStartHour[week-1][day];
            rule.lbstartMin[week][day] = rule.lbstartMin[week-1][day];

            rule.lbEndHour[week][day] = rule.lbEndHour[week-1][day];
            rule.lbEndMin[week][day] = rule.lbEndMin[week-1][day];
        }
        this.setState({rule});
    }

    render() {

        const ruleDefined = this.state.rule !== null ? '' : 'disabled';

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onSaveRuleDialog(this.state.rule)
                        }}
                        disabled={ruleDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            const target = this.props.editing ? TB_EditRule.id : TB_AddRule.id;
                            this.props.onHideDialog(target)
                        }}/>
            </div>
        );

        return (
            <Dialog header={this.props.header}
                    style={{width: '80%'}}
                    footer={footer}
                    modal={true}
                    visible={true}
                    resizable={true}
                    onHide={() => {
                        const target = this.props.editing ? TB_EditRule.id : TB_AddRule.id;
                        this.props.onHideDialog(target)
                    }}>
                <ScheduleRule rule={this.state.rule}
                              onChange={this.onChange}
                              onTabChange={()=>{}}
                              readOnly={false}
                              timeDiv={this.props.practiceDetails.timeDiv}
                              copyPreviousWeek={this.copyPreviousWeek}
                />
            </Dialog>
        )
    }
};
