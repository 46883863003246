import React from 'react';
import _ from 'lodash';
import {connect} from "react-redux";
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {TabBaseComponent} from "../../TabBaseComponent";
import * as Sections from "./Constants";
import {SEC_PP_ACTIVE, SEC_PP_FINISHED, SEC_PP_SUSPENDED, SEC_PP_WRITTEN_OFF} from "./Constants";
import {
    HELP_REP_PAYMENT_PLANS,
    setState,
    SM_PAYMENT_PLANS_ACTIVE,
    SM_PAYMENT_PLANS_FINISHED,
    SM_PAYMENT_PLANS_SUSPENDED,
    SM_PAYMENT_PLANS_WRITTEN_OFF,
    SM_PAYMENTS_PLANS,
    SM_REPORTS_PAYMENT_PLANS_PDF,
    stateRequest
} from "../../../actions/stateManagement";
import {
    HM_notImplemented,
    HM_PreviewAccountItem,
    HM_REP_PAYMENT_PLANS_DOWNLOAD,
    HM_REP_PAYMENT_PLANS_PRINT,
    HM_REP_PAYMENT_PLANS_SHOW,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {ICON_DOWNLOAD, ICON_HELP, ICON_PRINT, ICON_SCREEN,} from "../../../icons";
import * as Actions from "../../../actions";
import {PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../actions";
import PreviewPatientDocument from "../PatientDetails/dialogs/PreviewPatientDocument";
import PaymentPlansActive from "./Sections/PaymentPlansActive";
import ReportErrorBoundary from "./Utils";
import PaymentPlansFinished from "./Sections/PaymentPlansFinished";
import PaymentPlansSuspended from "./Sections/PaymentPlansSuspended";
import PaymentPlansWrittenOff from "./Sections/PaymentPlansWrittenOff";
import {TAB_CHANGE, TAB_EXIT_SAVE} from "../Housekeeping/Constants";
import {getReportPDF, RES_REPORT_PAYMENT_PLANS} from "../../../actions/reports";
import moment from "moment";
import {ac} from "../../../index";
import {dateTemplateShort} from "../../PatientDynamicItems/Utils";
import PaymentPlanningPDF from "./PDFs/PaymentPlanningPDF";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {tb_boilerPlateRight, tb_boilerPlateRight2, tb_boilerPlateRightCurrency} from "../../Utils";
import {TextPaymentPlans} from "../../../Messages";

export class ConnectedPaymentPlanning extends TabBaseComponent {

    constructor(props) {
        super(props);
        this.state = {

            stateManagementId: SM_PAYMENTS_PLANS.id,
            currentTotal: 0.0,

            canSave: {
                status: false,
                activeIndex: 0,
                source: {
                    id: SM_PAYMENTS_PLANS.id,
                    action: RES_REPORT_PAYMENT_PLANS.CLEAR.action,
                },
                activePlanTotal: 0.0,
                suspendedPlanTotal: 0.0,
                writtenOffPlanTotal: 0.0,
                finishedPlanTotal: 0.0,
            },
        };

        this.onShowReport = this.onShowReport.bind(this);
        this.onPrintReport = this.onPrintReport.bind(this);
        this.onDownload = this.onDownload.bind(this);

        this.onCloseClick = this.onCloseClick.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onExit = this.onExit.bind(this);
        this.exit = this.exit.bind(this);

        this.exitState = TAB_CHANGE;

        this.toolbarCallbacks = {
            [TB_SAVE_AND_EXIT.id]: this.exit,
            [HM_REP_PAYMENT_PLANS_PRINT.id]: this.onPrintReport,
            [HM_REP_PAYMENT_PLANS_SHOW.id]: this.onShowReport,
            [HM_REP_PAYMENT_PLANS_DOWNLOAD.id]: this.onDownload,
        };

        this.indexes = [RES_REPORT_PAYMENT_PLANS.ACTIVE_PDF, RES_REPORT_PAYMENT_PLANS.FINISHED_PDF, RES_REPORT_PAYMENT_PLANS.SUSPENDED_PDF, RES_REPORT_PAYMENT_PLANS.WRITTEN_OFF_PDF];
        this.headings = [SM_PAYMENT_PLANS_ACTIVE, SM_PAYMENT_PLANS_FINISHED, SM_PAYMENT_PLANS_SUSPENDED, SM_PAYMENT_PLANS_WRITTEN_OFF];
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_REPORT_ACT_PPS:

                    const activePlanTotal = _.sumBy(this.props.paymentPlansAct, plan => plan.planTotal);

                    this.setState({currentTotal: activePlanTotal, activePlanTotal});
                    break;
                case Actions.RECEIVE_REPORT_SUS_PPS:

                    const suspendedPlanTotal = _.sumBy(this.props.paymentPlansSus, plan => plan.planTotal);

                    this.setState({currentTotal: suspendedPlanTotal, suspendedPlanTotal});
                    break;
                case Actions.RECEIVE_REPORT_WRO_PPS:

                    const writtenOffPlanTotal = _.sumBy(this.props.paymentPlansWro, plan => plan.planTotal);

                    this.setState({currentTotal: writtenOffPlanTotal, writtenOffPlanTotal});
                    break;
                case Actions.RECEIVE_REPORT_FIN_PPS:

                    const finishedPlanTotal = _.sumBy(this.props.paymentPlansFin, plan => plan.planTotal);

                    this.setState({currentTotal: finishedPlanTotal, finishedPlanTotal});
                    break;
                case Actions.WSM_UPDATES:
                    break;
                default:
                    break;
            }
        }
    }

    onShowReport(event) {

        const onCloseClick = this.props.onCloseClick;
        const onTabUpdate = this.props.onTabUpdate;

        let reportType = null;

        switch (this.state.index) {
            case SEC_PP_ACTIVE:
                reportType = SM_PAYMENT_PLANS_ACTIVE.id;
                break;
            case SEC_PP_SUSPENDED:
                reportType = SM_PAYMENT_PLANS_SUSPENDED.id;
                break;
            case SEC_PP_WRITTEN_OFF:
                reportType = SM_PAYMENT_PLANS_WRITTEN_OFF.id;
                break;
            case SEC_PP_FINISHED:
                reportType = SM_PAYMENT_PLANS_FINISHED.id;
                break;
            default :
                break;
        }

        this.props.onPCButtonClick(
            {
                edited: false,
                content:
                    <TabPanel key={reportType} header={SM_REPORTS_PAYMENT_PLANS_PDF.label}
                              leftIcon={SM_REPORTS_PAYMENT_PLANS_PDF.tabIcon}>
                        <PaymentPlanningPDF onCloseClick={onCloseClick}
                                            onTabUpdate={onTabUpdate}
                                            reportType={reportType}/>
                    </TabPanel>,
            }
        )
    }

    onPrintReport(event) {

        let reportType = this.indexes[this.state.index];

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${this.headings[this.state.index]} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
                const params = {};
                this.props.getReportPDF(reportType, params);
            })
        }
    }

    onDownload() {

        fetch(`https://${ac.getBASERESTURL()}/Reports/paymentPlans.pdf?mcId=${ac.getMcId()}`)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `PayPlans${moment().format('YYYY_MM_DD')}.pdf`;
                    a.click();
                });
            });
    }

    onDoubleClick(e) {

        this.setState({selectedPlan: e.data}, () => {
            this.onShowDetails(e.data);
        });
    }

    exit() {
        this.exitState = TAB_EXIT_SAVE;
        this.onSaveExit();
    }

    onClose() {
        if (this.state.canSave.status) {
            this.props.setState(this.props.id, null);
            this.setState({showSaveDialog: true});
        } else {
            this.onExit();
        }
    }

    onExit() {

        this.props.onCloseClick({key: this.props.id});
    }

    onCloseClick() {
        this.props.onCloseClick({key: this.props.id});
    }

    showDialogs() {

        const contents = [];

        if (this.state[HM_PreviewAccountItem.id]) {
            contents.push(
                <PreviewPatientDocument documentDetails={this.state.accountItemDetails}
                                        targetId={HM_PreviewAccountItem.id}
                                        onOkDialog={this.onRequestSendAccountItem}
                                        onHideDialog={this.onHideMenuEntry}
                />
            )
        } else {
            contents.push(ShowMessageDialog(this, HM_notImplemented))
        }
        return contents;
    }

    onTabChange(index) {

        super.onTabChange(index)

        switch (index) {
            case SEC_PP_ACTIVE:
                this.setState({currentTotal: this.state.activePlanTotal})
                break;
            case SEC_PP_SUSPENDED:
                this.setState({currentTotal: this.state.suspendedPlanTotal})
                break;
            case SEC_PP_WRITTEN_OFF:
                this.setState({currentTotal: this.state.writtenOffPlanTotal})
                break;
            case SEC_PP_FINISHED:
                this.setState({currentTotal: this.state.finishedPlanTotal})
                break;
            default :
                this.setState({currentTotal: 0.0})
                break;
        }
    }

    render() {

        this.tabStack = [];

        this.tabStack.push(
            {
                key: Sections.SEC_PP_ACTIVE,
                section: Sections.SEC_PP_ACTIVE,
                content: <TabPanel key={Sections.SEC_PP_ACTIVE}
                                   leftIcon={SM_PAYMENT_PLANS_ACTIVE.tabIcon}
                                   header={SM_PAYMENT_PLANS_ACTIVE.label}>
                    <PaymentPlansActive onChange={this.onChange}
                                        toolbarCallbacks={this.toolbarCallbacks}
                                        onPCButtonClick={this.props.onPCButtonClick}
                                        onCloseClick={this.props.onCloseClick}
                    />
                </TabPanel>
            }
        );

        this.tabStack.push(
            {
                key: Sections.SEC_PP_FINISHED,
                section: Sections.SEC_PP_FINISHED,
                content: <TabPanel key={Sections.SEC_PP_FINISHED}
                                   leftIcon={SM_PAYMENT_PLANS_FINISHED.tabIcon}
                                   header={SM_PAYMENT_PLANS_FINISHED.label}>
                    <PaymentPlansFinished onChange={this.onChange}
                                          toolbarCallbacks={this.toolbarCallbacks}
                                          onPCButtonClick={this.props.onPCButtonClick}
                                          onCloseClick={this.props.onCloseClick}
                    />
                </TabPanel>
            }
        );

        this.tabStack.push(
            {
                key: Sections.SEC_PP_SUSPENDED,
                section: Sections.SEC_PP_SUSPENDED,
                content: <TabPanel key={Sections.SEC_PP_SUSPENDED}
                                   leftIcon={SM_PAYMENT_PLANS_SUSPENDED.tabIcon}
                                   header={SM_PAYMENT_PLANS_SUSPENDED.label}>
                    <PaymentPlansSuspended onChange={this.onChange}
                                           toolbarCallbacks={this.toolbarCallbacks}
                                           onPCButtonClick={this.props.onPCButtonClick}
                                           onCloseClick={this.props.onCloseClick}
                    />
                </TabPanel>
            }
        );

        this.tabStack.push(
            {
                key: Sections.SEC_PP_WRITTEN_OFF,
                section: Sections.SEC_PP_WRITTEN_OFF,
                content: <TabPanel key={Sections.SEC_PP_WRITTEN_OFF}
                                   leftIcon={SM_PAYMENT_PLANS_WRITTEN_OFF.tabIcon}
                                   header={SM_PAYMENT_PLANS_WRITTEN_OFF.label}>
                    <PaymentPlansWrittenOff onChange={this.onChange}
                                            toolbarCallbacks={this.toolbarCallbacks}
                                            onPCButtonClick={this.props.onPCButtonClick}
                                            onCloseClick={this.props.onCloseClick}
                    />
                </TabPanel>
            }
        );

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div id="detailPanel">

                    <Toolbar
                            right={<React.Fragment>

                                {tb_boilerPlateRightCurrency(key, this.state.currentTotal, true)}
                                {tb_boilerPlateRight2(this.onShowReport, ICON_SCREEN, 'Show ' + TextPaymentPlans, key++, false)}
                                {tb_boilerPlateRight2(this.onPrintReport, ICON_PRINT, 'Print ' + TextPaymentPlans, key++, false)}
                                {tb_boilerPlateRight(this.onDownload, ICON_DOWNLOAD, 'Download ' + TextPaymentPlans, key++, true)}
                                {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                            </React.Fragment>}
                    />

                    {this.showDialogs()}

                    <TabView scrollable={true}
                             style={{paddingTop: '5px'}}
                             activeIndex={this.state.canSave.activeIndex}
                             onTabChange={(e) => {
                                 this.onTabChange(e.index)
                             }}>

                        {this.tabStack.map(tab => {
                            return tab.content
                        })}

                    </TabView>
                </div>
            </ReportErrorBoundary>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        paymentPlansActLoaded: state.reports.paymentPlansActLoaded,
        paymentPlansAct: state.reports.paymentPlansAct,

        paymentPlansSusLoaded: state.reports.paymentPlansSusLoaded,
        paymentPlansSus: state.reports.paymentPlansSus,

        paymentPlansWroLoaded: state.reports.paymentPlansWroLoaded,
        paymentPlansWro: state.reports.paymentPlansWro,

        paymentPlansFinLoaded: state.reports.paymentPlansFinLoaded,
        paymentPlansFin: state.reports.paymentPlansFin,

        message: state.stateManagement.message,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        stateRequest: (source) => dispatch(stateRequest(source)),
        getReportPDF: (type, params) => dispatch(getReportPDF(type, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const PaymentPlanning = connect(mapStateToProps, mapDispatchToProps)(ConnectedPaymentPlanning);

export default PaymentPlanning;
