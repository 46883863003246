import React from 'react';
import _ from 'lodash';
import {AccordionTab} from 'primereact/components/accordion/Accordion';
import {Button} from 'primereact/components/button/Button';

import {
    __CHIRAL_CONTACT_DETAILS__,
    APP_CREATED,
    APP_MOVING,
    APP_TPUNPLACED,
    CH_INVOICE, HM_ADD_WORK_REQUIRED,
    TB_EXIT,
    TB_SAVE,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {
    ICON_HELP,
    ICON_SAVE_DISABLED,
    ICON_SAVE_ENABLED,
} from "../../../icons";
import {
    TB_ADD_APPOINTMENT,
    TB_APPOINTMENT_NOT_SPECIFIED,
    TB_APPOINTMENTS_CLOSE,
    TB_APPOINTMENTS_OPEN, TB_CONNECT_TO_XRAY,
    TB_DELETE_APPOINTMENT,
    TB_EDIT_APPOINTMENT_TITLE,
    TB_GOTO_APPOINTMENT,
    TB_HIDE_TREATMENT_ADD_EDIT,
    TB_ON_EDIT_APPOINTMENT,
    TB_PATIENT_DETAILS,
    TB_PATIENT_DOC_CREATE,
    TB_PATIENT_DOC_PRESCRIPTION,
    TB_PATIENT_DOC_REFERRER,
    TB_PATIENT_DOC_UPLOAD,
    TB_PATIENT_HANDOVER, TB_PATIENT_IMG_SCAN,
    TB_PATIENT_IMG_UPLOAD,
    TB_PATIENT_NOTE,
    TB_PATIENT_SALES,
    TB_PATIENT_SALES_NHS,
    TB_PATIENT_TPLANS,
    TB_PLACE_APPOINTMENT,
    TB_SHOW_TREATMENT_ADD,
    TB_TREATMENT_ADD,
    TB_TREATMENT_COMPLETE,
    TB_TREATMENT_DELETE,
    TB_TREATMENT_EDIT,
    TB_USING_BUTTON
} from "../PatientDetails/Constants";
import Appointment from "./Sections/Appointment";
import {dateTemplateVeryShort, durationTemplate} from "../../PatientDynamicItems/Utils";
import {
    tb_boilerPlate,
    tb_boilerPlate2, tb_boilerPlateJC,
    tb_boilerPlateRight,
    tb_boilerPlateRight2,
    tb_boilerPlateRightAge,
    tb_boilerPlateRightCurrency
} from "../../Utils";
import {TB_CHECK_IN, TB_CHECK_OUT, TB_CHECKED_OUT} from "./Constants";
import moment from "moment";
import {HELP_APPOINTMENT_INDEXS} from "../../../actions/stateManagement";

export const appointmentToolBar = (callbacks, {
    open,
    canSave,
    appointment
}, payable, genderIcon, patientData, salesTotal, activeIndex) => {

    let key = Math.floor(Math.random() * 1000);

    const canSaveFlag = canSave.status;
    const saveIcon = canSaveFlag ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;
    const saveAndExitText = canSaveFlag ? TB_SAVE_AND_EXIT.text : TB_EXIT.text;

    const checkInToChair = appointment.inSurgeryTime === null;
    const checkInOutButtonDisabled = appointment.inSurgeryTime !== null && appointment.leftSurgeryTime !== null;

    const checkInOutButton = checkInToChair ? TB_CHECK_IN : checkInOutButtonDisabled ? TB_CHECKED_OUT : TB_CHECK_OUT;

    const salesButton = patientData.nhspatient ? tb_boilerPlate2(callbacks[TB_PATIENT_SALES_NHS.id], TB_PATIENT_SALES_NHS.icon, !payable, TB_PATIENT_SALES_NHS.text, key++) : tb_boilerPlate2(callbacks[TB_PATIENT_SALES.id], TB_PATIENT_SALES.icon, !payable, TB_PATIENT_SALES.text, key++);
    const openClose = open ? tb_boilerPlate(callbacks[TB_APPOINTMENTS_CLOSE.id], TB_APPOINTMENTS_CLOSE.icon, TB_APPOINTMENTS_CLOSE.text, key++) : tb_boilerPlate(callbacks[TB_APPOINTMENTS_OPEN.id], TB_APPOINTMENTS_OPEN.icon, TB_APPOINTMENTS_OPEN.text, key++)

    return {
        left:
            <React.Fragment>
                {tb_boilerPlate2(callbacks[TB_SAVE.id], saveIcon, !canSaveFlag, TB_SAVE.text, key++)}

                <i className='p-toolbar-group-left fa separator24'/>

                {tb_boilerPlate(callbacks[TB_PATIENT_DETAILS.id], genderIcon, TB_PATIENT_DETAILS.text, key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_TPLANS.id], TB_PATIENT_TPLANS.icon, TB_PATIENT_TPLANS.text, key++)}

                <i className='p-toolbar-group-left fa separator24'/>

                {tb_boilerPlate(callbacks[TB_PATIENT_NOTE.id], TB_PATIENT_NOTE.icon, TB_PATIENT_NOTE.text, key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_DOC_CREATE.id], TB_PATIENT_DOC_CREATE.icon, TB_PATIENT_DOC_CREATE.text, key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_IMG_SCAN.id], TB_PATIENT_IMG_SCAN.icon, TB_PATIENT_IMG_SCAN.text, key++)}
                {tb_boilerPlate(callbacks[TB_CONNECT_TO_XRAY.id], TB_CONNECT_TO_XRAY.icon, TB_CONNECT_TO_XRAY.text, key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_IMG_UPLOAD.id], TB_PATIENT_IMG_UPLOAD.icon, TB_PATIENT_IMG_UPLOAD.text, key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_DOC_UPLOAD.id], TB_PATIENT_DOC_UPLOAD.icon, TB_PATIENT_DOC_UPLOAD.text, key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_DOC_REFERRER.id], TB_PATIENT_DOC_REFERRER.icon, TB_PATIENT_DOC_REFERRER.text, key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_DOC_PRESCRIPTION.id], TB_PATIENT_DOC_PRESCRIPTION.icon, TB_PATIENT_DOC_PRESCRIPTION.text, key++)}

                <i className='p-toolbar-group-left fa separator24'/>

                {salesButton}
                {openClose}
                {tb_boilerPlate(callbacks[TB_ADD_APPOINTMENT.id], TB_ADD_APPOINTMENT.icon, TB_ADD_APPOINTMENT.text, key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_HANDOVER.id], TB_PATIENT_HANDOVER.icon, TB_PATIENT_HANDOVER.text, key++)}

                <i className='p-toolbar-group-left fa separator24'/>

                {tb_boilerPlate(callbacks[HM_ADD_WORK_REQUIRED.id], HM_ADD_WORK_REQUIRED.icon, HM_ADD_WORK_REQUIRED.message, key++)}
            </React.Fragment>,
        right:
            <React.Fragment>
                {tb_boilerPlateJC(key++, patientData.patientJourney ? patientData.patientJourney.stage : '', true)}
                {tb_boilerPlateRightCurrency(key++, salesTotal, true)}
                {tb_boilerPlateRightAge(key++, patientData.dateOfBirth, true)}
                {tb_boilerPlateRight2(callbacks[checkInOutButton.id], checkInOutButton.icon, checkInOutButton.text, key++, checkInOutButtonDisabled)}
                {tb_boilerPlateRight((e) => {window.open(HELP_APPOINTMENT_INDEXS[activeIndex])}, ICON_HELP, 'Knowledge Base', key++, true)}
                {tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, saveAndExitText, key)}

            </React.Fragment>
    }
};

const insertOptionalCommit = (callbacks, key, appointment, targetId, payable) => {

    if (appointment.appointmentId === targetId) {

        try {
            const target = appointment.patient.nhspatient ? TB_PATIENT_SALES_NHS : TB_PATIENT_SALES;

            return (
                <Button tooltip={target.text}
                        tooltipOptions={{position: 'left'}}
                        icon={target.icon16}
                        key={key++}
                        disabled={!payable}
                        onClick={(e) => {
                            callbacks[TB_USING_BUTTON.id](true);
                            callbacks[target.id](true);
                        }}
                />
            )
        } catch (error) {
            console.log(`insertOptionalCommit :: ${error}`);
        }
    }
}

export const addAppointments = ({
                                    treatmentPlan,
                                    providers,
                                    appointmentTypes,
                                    accountGroups,
                                    templateNotes,
                                    onChange,
                                    favourites,
                                    treatmentCodes,
                                    appointmentId,
                                    treatmentDisplay,
                                    selectedAppointmentId
                                }, callbacks, selectedCharge, showContextMenus, showCompleteness, mode, showAddTreatment, showEditTreatment, addFromCharting, currentSelectedItem, currentChartedEntry, payable) => {

    let key = Math.floor(Math.random() * 1000);
    const content = [];

    const sortedAppointments = _.orderBy(treatmentPlan.appointments, 'start', (o) => {
        return moment(o.start).format('YYYYMMDD');
    }, ['asc']);

    sortedAppointments.forEach((appointment, index) => {

        if (appointment.status === APP_CREATED || appointment.status === APP_TPUNPLACED || appointment.status === APP_MOVING) {

            const placeable = appointment.appointmentId < -1 || appointment.status === APP_TPUNPLACED;

            const navigable = appointment.status === APP_CREATED;
            const provider = appointment.appointmentWith;

            const fullySpecified_text = placeable ? TB_PLACE_APPOINTMENT.text : TB_APPOINTMENT_NOT_SPECIFIED.text;
            const fullySpecified_icon = placeable ? TB_PLACE_APPOINTMENT.icon : TB_APPOINTMENT_NOT_SPECIFIED.icon;

            const providerUsername = provider === null ? '' : provider.username === null ? '-' : `${provider.username}`;

            const activeAppointment = selectedAppointmentId === appointment.appointmentId ? '*' : ' ';

            const dateDuration = appointment.status === APP_CREATED ? dateTemplateVeryShort(appointment.start) : durationTemplate(appointment.start, appointment.end);

            const header = <div className='p-accordion-header-text'>
                        <div className="items-margin d-flex d-align-center">
                            <span className='p-panel-title' style={{ marginRight: 15 }}>{`${activeAppointment} (${providerUsername}) ${dateDuration} : ${appointment.shortDescription}`.trim()}</span>
                        </div>
                        <div className="items-margin d-flex d-align-center">
                            {insertOptionalCommit(callbacks, key, appointment, appointmentId, payable)}

                            {placeable &&
                            <Button tooltipOptions={{position: 'left'}}
                                    tooltip={fullySpecified_text}
                                    icon={fullySpecified_icon}
                                    key={TB_USING_BUTTON.id}
                                    onClick={(e) => {
                                        callbacks[TB_USING_BUTTON.id](true);
                                        callbacks[TB_PLACE_APPOINTMENT.id](appointment);
                                    }}/>
                            }
                            <Button tooltipOptions={{position: 'left'}}
                                    tooltip={TB_EDIT_APPOINTMENT_TITLE.text}
                                    icon={TB_EDIT_APPOINTMENT_TITLE.icon}
                                    key={TB_EDIT_APPOINTMENT_TITLE.id}
                                    onClick={(e) => {
                                        callbacks[TB_USING_BUTTON.id](true);
                                        callbacks[TB_EDIT_APPOINTMENT_TITLE.id](appointment);
                                    }}/>
                            <Button tooltipOptions={{position: 'left'}}
                                    tooltip={TB_DELETE_APPOINTMENT.text}
                                    icon={TB_DELETE_APPOINTMENT.icon}
                                    key={TB_DELETE_APPOINTMENT.id}
                                    disabled={appointment.status === APP_CREATED}
                                    onClick={(e) => {
                                        callbacks[TB_USING_BUTTON.id](true);
                                        callbacks[TB_DELETE_APPOINTMENT.id](appointment);
                                    }}/>
                            <Button tooltipOptions={{position: 'left'}}
                                    tooltip={TB_GOTO_APPOINTMENT.text}
                                    icon={TB_GOTO_APPOINTMENT.icon}
                                    key={TB_GOTO_APPOINTMENT.id}
                                    disabled={!navigable}
                                    onClick={(e) => {
                                        callbacks[TB_USING_BUTTON.id](true);
                                        callbacks[TB_GOTO_APPOINTMENT.id](appointment);
                                    }}
                            />
                        </div>
                    </div>;

            content.push(
                <AccordionTab key={index}
                              headerTemplate={header}>
                    <Appointment key={`app_${index}`}
                                 treatmentDisplay={mode}
                                 treatmentPlan={treatmentPlan}
                                 appointment={appointment}
                                 selectedCharge={selectedCharge}
                                 providers={providers}
                                 appointmentTypes={appointmentTypes}
                                 accountGroups={accountGroups}
                                 templateNotes={templateNotes}
                                 onComplete={callbacks[TB_TREATMENT_COMPLETE.id]}
                                 onChange={onChange}
                                 onUpdateAppointment={callbacks[TB_ON_EDIT_APPOINTMENT.id]}
                                 showContextMenus={showContextMenus}
                                 showCompleteness={showCompleteness}
                                 favourites={favourites}
                                 treatmentCodes={treatmentCodes}
                                 onAddTreatment={callbacks[TB_TREATMENT_ADD.id]}
                                 onEditTreatment={callbacks[TB_TREATMENT_EDIT.id]}
                                 onDeleteTreatment={callbacks[TB_TREATMENT_DELETE.id]}
                                 showAddTreatment={showAddTreatment}
                                 showEditTreatment={showEditTreatment}
                                 onShowAddEditTreatment={callbacks[TB_SHOW_TREATMENT_ADD.id]}
                                 onHideAddEditTreatment={callbacks[TB_HIDE_TREATMENT_ADD_EDIT.id]}
                                 addFromCharting={addFromCharting}
                                 currentSelectedItem={currentSelectedItem}
                                 currentChartedEntry={currentChartedEntry}
                                 selectedAppointmentId={selectedAppointmentId}
                    />
                </AccordionTab>
            )
        }
    });
    return content;
};

export const calcTotal = (patientCharges, possibleDiscount) => {

    let total = 0.0;
    let discount = 0.0;

    patientCharges.forEach(charge => {

        if (!charge.item.discountCharge) {
            total = total + (charge.chargedPrice * charge.quantity);
        }
    });

    if (possibleDiscount !== null) {
        discount = possibleDiscount.chargedPrice;
        total = total - (total * (discount / 100.0));
    }
    return total;
};

export const getSalesTPTotal = (treatmentPlan) => {

    const charges = treatmentPlan.unassignedCharges ? treatmentPlan.unassignedCharges : [];

    // as this might be part of a treatment plan or a partial charging which does not include the discount code we must look for it.
    let possibleDiscount = null;

    // first build a set of receipt-able charges
    const receiptCharges = charges.filter(charge => {

        if (charge.item.discountCharge)
            possibleDiscount = charge;
        return true;
    });

    // next check that if only one charge it is not a discount code
    if (receiptCharges.length === 1) {
        if (receiptCharges[0].item.discountCharge)
            return {result: false, value: null, message: "Single Discount Code"};
    }

    return calcTotal(receiptCharges, possibleDiscount);
}

export const getSalesTotal = (charges) => {

    // as this might be part of a treatment plan or a partial charging which does not include the discount code we must look for it.
    let possibleDiscount = null;

    let accountGroup = null;

    // first build a set of receipt-able charges
    const receiptCharges = charges.filter(charge => {
            if (charge.status === CH_INVOICE) {

                accountGroup = charge.accountGroup;

                if (charge.item.discountCharge)
                    possibleDiscount = charge;
                return true;
            } else return false
        }
    );

    // next check that if only one charge it is not a discount code
    if (receiptCharges.length === 1) {
        if (receiptCharges[0].item.discountCharge)
            return {result: false, value: null, message: "Single Discount Code"};
    }

    const salesTotalValue = calcTotal(receiptCharges, possibleDiscount);
    return {result: true, value: salesTotalValue, message: null, accountGroup};
};

export default class AppointmentErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>Something went wrong in the Appointment section.</h1>
                    <h2>{__CHIRAL_CONTACT_DETAILS__}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}

export const findAccountGroup = (accountGroups, providerId) => {

    let accountGroup = null;
    const membersGroups = [];

    accountGroups.forEach(group => {

        const membersGroup = _.find(group.members, member => providerId === member.id);

        if (membersGroup) {
            membersGroups.push(group);
        }
    });

    let needAccountGroup = false;

    switch (membersGroups.length) {

        case 0 :
            accountGroup = _.find(accountGroups, group => group.defaultAG);
            break;
        case 1 :
            accountGroup = {id: membersGroups[0].id};
            break;
        default:
            needAccountGroup = true;
            break;
    }
    return {needAccountGroup, accountGroup};
}