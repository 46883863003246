import React from 'react';
import _ from 'lodash';

import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import {Accordion} from 'primereact/components/accordion/Accordion';
import {Button} from 'primereact/components/button/Button';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Panel} from 'primereact/components/panel/Panel';
import Treatment from "./Treatment";
import {findAssignedCharges, TP_UNASSIGNED_CHARGE} from "../Utils";
import {BaseComponent} from "../../../BaseComponent";
import AddTreatment from "../../AppointmentDetails/Dialogs/AddTreatment";
import {
    HM_AddTreatment,
    HM_AppointmentComment,
    HM_DeleteAppointment,
    HM_DeleteAppointmentUnassignFirst,
    HM_DeleteTreatment,
    HM_EditTreatment,
    HM_UnAssignTreatment,
    TREATMENTS_TP_LEFT,
    TREATMENTS_TP_RIGHT
} from "../../../../Constants";
import {addAppointments} from "../../AppointmentDetails/Utils";
import {
    TB_ADD_APPOINTMENT,
    TB_APPOINTMENTS_CLOSE,
    TB_APPOINTMENTS_OPEN,
    TB_DELETE_APPOINTMENT,
    TB_EDIT_APPOINTMENT_TITLE,
    TB_ON_EDIT_APPOINTMENT,
    TB_PLACE_APPOINTMENT,
    TB_SHOW_TREATMENT_ADD,
    TB_SHOW_TREATMENT_EDIT,
    TB_TREATMENT_COMPLETE,
    TB_USING_BUTTON
} from "../../PatientDetails/Constants";
import AppointmentComment from '../../../FixedItems/Diary/dialogs/AppointmentComment';
import {getChartedItemDetails} from "../../../Charting/Utils/general";

export default class TreatmentsAppointments extends BaseComponent {

    constructor(props) {
        super();
        this.state = {
            activeIndex: 0,
            open: true,

            selectedCharge: {id: null},

            [HM_DeleteTreatment.id]: false,
            [HM_DeleteAppointment.id]: false,
            [HM_DeleteAppointmentUnassignFirst.id]: false,
            [HM_AppointmentComment.id]: false,
        };

        this.cmAddEditDelete = null;
        this.assignedCharges = [];
        this.usingTBButtons = false;

        this.onOpenClose = this.onOpenClose.bind(this);
        this.onHide = this.onHide.bind(this);
        this.showDialogs = this.showDialogs.bind(this);
        this.onEditAppointmentTitle = this.onEditAppointmentTitle.bind(this);
        this.onDeleteAppointment = this.onDeleteAppointment.bind(this);
        this.onUpdateAppointment = this.onUpdateAppointment.bind(this);
        this.onUpdateTBButton = this.onUpdateTBButton.bind(this);

        this.callbacks = {
            [TB_USING_BUTTON.id]: this.onUpdateTBButton,
            [TB_EDIT_APPOINTMENT_TITLE.id]: this.onEditAppointmentTitle,
            [TB_ON_EDIT_APPOINTMENT.id]: this.onUpdateAppointment,
            [TB_TREATMENT_COMPLETE.id]: props.onComplete,
            [TB_DELETE_APPOINTMENT.id]: this.onDeleteAppointment,
            [TB_PLACE_APPOINTMENT.id]: props.onPlaceAppointment,
        };
        this.assignedCharges = findAssignedCharges(props.treatmentPlan.appointments);
    };

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.treatmentPlan !== this.props.treatmentPlan) {
            console.log(`componentDidUpdate`);
            this.assignedCharges = findAssignedCharges(this.props.treatmentPlan.appointments);
            this.setState({treatmentPlan: this.props.treatmentPlan})
        }
    }

    onDragEnd = (event) => {

        if (event.destination === null || event.destination.droppableId === event.source.droppableId)
            return;

        this.props.onDragEnd(event);
    };

    onOpenClose() {

        const plan = this.props.treatmentPlan;

        if (this.state.open) {
            this.setState({open: !this.state.open, activeIndex: []})
        } else {
            this.setState({open: !this.state.open, activeIndex: plan.appointments.map((item, index) => index)});
        }
    };

    onEditAppointmentTitle(appointment) {
        this.onShowMenuEntry({item: {target: HM_AppointmentComment.id}});
        this.appointment = appointment;
    }

    onDeleteAppointment(appointment) {
        this.onShowMenuEntry({item: {target: HM_DeleteAppointment.id}});
        this.appointment = appointment;
    }

    onUpdateTBButton(newState) {

        this.usingTBButtons = newState;
    }

    onHide(target, localTarget) {
        if (this.props[target]) {
            this.props.onHideMenuEntry(target);
        } else {
            this.onHideMenuEntry(localTarget);
        }
    }

    showDialogs() {

        const appointmentFilter = this.state.appointment ? (this.props.selectedAppointmentId === this.state.appointment.appointmentId) : true;

        if (this.props.showAddTreatment && appointmentFilter) {
            return (
                <AddTreatment editing={false}
                              addFromCharting={this.props.addFromCharting}
                              currentSelectedItem={this.props.currentSelectedItem}
                              currentChartedEntry={this.props.currentChartedEntry}
                              appointment={null}
                              treatmentPlan={this.props.treatmentPlan}
                              header={HM_AddTreatment.header}
                              visible={true}
                              onHideDialog={() => {
                                  this.props.onHideAddEditTreatment();
                              }}
                              onOkDialog={(treatment) => {
                                  treatment.accountGroup = this.accountGroup !== undefined ? this.accountGroup : null;
                                  this.props.onAddTreatment(treatment);
                              }}
                              treatmentCodes={this.props.treatmentCodes}
                              favourites={this.props.favourites}
                />
            )
        } else if (this.props.showEditTreatment && appointmentFilter) {
            return (
                <AddTreatment editing={true}
                              addFromCharting={this.props.addFromCharting}
                              currentChartedEntry={this.props.currentChartedEntry}
                              appointment={this.state.appointment}
                              treatmentPlan={this.props.treatmentPlan}
                              header={HM_EditTreatment.header}
                              visible={true}
                              onHideDialog={() => {
                                  this.props.onHideAddEditTreatment();
                              }}
                              onOkDialog={(treatment) => {
                                  this.props.onEditTreatment(treatment);
                              }}
                              treatmentCodes={this.props.treatmentCodes}
                              favourites={this.props.favourites}
                              item={this.state.selectedCharge}
                />
            )
        } else if (this.state[HM_DeleteTreatment.id]) {

            const assigned = _.findIndex(this.assignedCharges, chargeId => chargeId === this.state.selectedCharge.id) !== -1;

            if (assigned) {
                const footer = <div>
                    <Button label="OK" icon="fa fa-check" onClick={() => {
                        this.onHideMenuEntry(HM_DeleteTreatment.id)
                    }}/>
                    <Button label="No" icon="fas fa-times" onClick={() => {
                        this.onHideMenuEntry(HM_DeleteTreatment.id)
                    }}/>
                </div>;
                return (
                    <Dialog header={HM_UnAssignTreatment.header}
                            visible={true}
                            modal={true}
                            footer={footer}
                            onHide={() => {
                                this.onHideMenuEntry(HM_DeleteTreatment.id)
                            }}>
                        {HM_UnAssignTreatment.message}
                    </Dialog>
                )
            } else {
                const footer = <div>
                    <Button label="Yes" icon="fa fa-check" onClick={() => {
                        this.props.onDeleteTreatment(this.state.selectedCharge);
                        this.onHideMenuEntry(HM_DeleteTreatment.id)
                    }}/>
                    <Button label="No" icon="fas fa-times" onClick={() => {
                        this.onHideMenuEntry(HM_DeleteTreatment.id)
                    }}/>
                </div>;
                return (
                    <Dialog header={HM_DeleteTreatment.header}
                            visible={true}
                            modal={true}
                            footer={footer}
                            onHide={() => {
                                this.onHideMenuEntry(HM_DeleteTreatment.id)
                            }}>
                        {HM_DeleteTreatment.message}
                    </Dialog>
                )
            }
        } else if (this.state[HM_AppointmentComment.id]) {

            return (
                <AppointmentComment text={HM_AppointmentComment}
                                    onHideDialog={() => {
                                        this.onUpdateTBButton(false);
                                        this.onHideMenuEntry(HM_AppointmentComment.id)
                                    }}
                                    onOkDialog={this.onUpdateAppointment}
                                    event={null}
                                    appointment={this.appointment}
                />
            )
        } else if (this.state[HM_DeleteAppointment.id]) {

            const assigned = Boolean(this.appointment.charges) && this.appointment.charges.length > 0;

            if (assigned) {
                const footer = <div>
                    <Button label="OK" icon="fa fa-check" onClick={() => {
                        this.onHideMenuEntry(HM_DeleteAppointment.id);
                        this.onUpdateTBButton(false);
                    }}/>
                </div>;
                return (
                    <Dialog header={HM_DeleteAppointmentUnassignFirst.header}
                            visible={true}
                            modal={true}
                            footer={footer}
                            onHide={() => {
                                this.onHideMenuEntry(HM_DeleteTreatment.id);
                                this.onUpdateTBButton(false);
                            }}>
                        {HM_DeleteAppointmentUnassignFirst.message}
                    </Dialog>
                )
            } else {
                const footer = <div>
                    <Button label="Yes" icon="fa fa-check" onClick={() => {
                        this.props.onDeleteAppointment(this.appointment);
                        this.onHideMenuEntry(HM_DeleteAppointment.id);
                        this.onUpdateTBButton(false);
                    }}/>
                    <Button label="No" icon="fas fa-times" onClick={() => {
                        this.onHideMenuEntry(HM_DeleteAppointment.id);
                        this.onUpdateTBButton(false);
                    }}/>
                </div>;
                return (
                    <Dialog header={HM_DeleteAppointment.header}
                            visible={true}
                            modal={true}
                            footer={footer}
                            onHide={() => {
                                this.onHideMenuEntry(HM_DeleteAppointment.id);
                                this.onUpdateTBButton(false);
                            }}>
                        {HM_DeleteAppointment.message}
                    </Dialog>
                )
            }
        }
    }

    onUpdateAppointment(appointment) {

        this.onUpdateTBButton(false);
        this.onHideMenuEntry(HM_AppointmentComment.id);
        this.props.onUpdateAppointment(appointment);
    }

    addUnassignedChargesHeader() {

        const borderStyle = {borderStyle: 'solid', borderColor: '#ebebeb', borderWidth: '1px'};
        return (
            <div className="p-grid p-fluid form-group p-col-12">
                <div style={borderStyle} className="p-col-1">Code</div>
                <div style={borderStyle} className='p-col-6'>Description</div>
                <div style={borderStyle} className="p-col-2">Site</div>
                <div style={{...borderStyle, textAlign: 'right'}} className="p-col-1">Qty</div>
                <div style={{...borderStyle, textAlign: 'right'}} className="p-col-2">Fee</div>
            </div>
        );
    }

    render() {

        const openCloseIcon = this.state.open ? TB_APPOINTMENTS_CLOSE.icon : TB_APPOINTMENTS_OPEN.icon;
        const openCloseText = this.state.open ? TB_APPOINTMENTS_CLOSE.text : TB_APPOINTMENTS_OPEN.text;

        const appointmentId = this.state.appointment ? this.state.appointment.appointmentId : null;

        const header = <div className='p-panel-header'>
                    <div className="items-margin d-flex d-align-center">
                        <span className='p-panel-title' style={{ marginRight: 15 }}>Treatments</span>
                        <Button id='fix-add-icon' tooltip={HM_AddTreatment.header}
                                tooltipOptions={{position: 'right'}}
                                icon={HM_AddTreatment.icon}
                                onClick={(e) => {
                                    this.props.onShowAddEditTreatment(false, null, appointmentId, TB_SHOW_TREATMENT_ADD.id);
                                }}
                        />
                    </div>
                    <div className="items-margin d-flex d-align-center">
                        <Button icon={TB_ADD_APPOINTMENT.icon}
                                tooltip={TB_ADD_APPOINTMENT.text}
                                tooltipOptions={{position: 'top'}}
                                onClick={this.props.onAddAppointment}
                        />
                        <Button icon={openCloseIcon}
                                tooltip={openCloseText}
                                tooltipOptions={{position: 'top'}}
                                onClick={this.onOpenClose}
                        />
                    </div>
                </div>;

        const {unassignedCharges} = this.props.treatmentPlan;

        let sortedUnassignedCharges = _.orderBy(unassignedCharges, 'id', 'asc');
        sortedUnassignedCharges = _.map(sortedUnassignedCharges, (charge) => {
            if (charge.chartEntry) {
                charge.details = getChartedItemDetails(charge.chartEntry)
            } else {
                charge.details = charge.site;
            }
            return charge;
        });

        const itemsAddEditDelete = [
            {
                label: HM_AddTreatment.label,
                icon: HM_AddTreatment.icon,
                command: () => this.props.onShowAddEditTreatment(false, null, null, TB_SHOW_TREATMENT_ADD.id),
                target: HM_AddTreatment.id
            },
            {
                label: HM_EditTreatment.label,
                icon: HM_EditTreatment.icon,
                command: () => this.props.onShowAddEditTreatment(false, null, null, TB_SHOW_TREATMENT_EDIT.id),
                target: HM_EditTreatment.id
            },
            {
                label: HM_DeleteTreatment.header,
                icon: HM_DeleteTreatment.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteTreatment.id
            },
        ];

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>

                <ContextMenu model={itemsAddEditDelete} ref={el => this.cmAddEditDelete = el} style={{width: 250}}/>

                {this.showDialogs()}

                <div className="p-grid p-fluid" style={{marginTop: '5px'}}>
                    <div className="p-col-6">
                        <Droppable droppableId="treatments">
                            {provided => (
                                <div {...provided.droppableProps} ref={provided.innerRef}
                                     onContextMenu={(e) => {
                                         this.cmAddEditDelete.hide(e);
                                     }}
                                >
                                    <Panel headerTemplate={header}>

                                        {this.addUnassignedChargesHeader()}

                                        {/*iterate through all treatments*/}
                                        {sortedUnassignedCharges.map((charge, index) => {

                                            const assigned = _.findIndex(this.assignedCharges, chargeId => chargeId === charge.id) !== -1;

                                            return (
                                                <Draggable draggableId={`${TP_UNASSIGNED_CHARGE}_${charge.id}`}
                                                           index={index}
                                                           key={index}
                                                           isDragDisabled={assigned}
                                                >
                                                    {(provided2, snapshot2) => (
                                                        <Treatment charge={charge}
                                                                   treatmentDisplay={TREATMENTS_TP_LEFT}
                                                                   key={`charge_${index}`}
                                                                   provided={provided2}
                                                                   snapshot={snapshot2}
                                                                   assigned={assigned}
                                                                   showCompleteness={false}
                                                                   treatmentPlanning={this.props.treatmentPlanning}
                                                                   innerRef={provided2.innerRef}
                                                                   highlighted={false}
                                                                   onSelection={() => {
                                                                       this.setState({selectedCharge: charge})
                                                                   }}
                                                                   onContextMenu={(e) => {
                                                                       this.cmAddEditDelete.show(e);
                                                                       this.setState({selectedCharge: charge})
                                                                   }}
                                                                   onComplete={this.props.onComplete}
                                                        />
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </Panel>
                                </div>
                            )}
                        </Droppable>
                    </div>
                    <div className="p-col-6 tp-appointment-list" style={{paddingTop: '2px'}}>
                        <Accordion activeIndex={this.state.activeIndex}
                                   onTabChange={(e) => {
                                       if (!this.usingTBButtons)
                                           this.setState({activeIndex: e.index});
                                   }}
                                   multiple={true}
                                   key={`key__${this.state.activeIndex}`}
                        >
                            {/*right hand side column with context menu for assigned charges*/}
                            {addAppointments(this.props,
                                this.callbacks,
                                this.state.selectedCharge,
                                false,
                                false,
                                TREATMENTS_TP_RIGHT,
                                this.props.showAddTreatment,
                                this.props.showEditTreatment,
                                this.props.addFromCharting,
                                this.props.currentSelectedItem,
                                this.props.currentChartedEntry)}
                        </Accordion>
                    </div>
                </div>
            </DragDropContext>
        );
    }
}
