import React from "react";
import _ from "lodash";
import {SELECTED_ITEM_COLOUR} from "../Constants";
import {COLOR_ChiralPalette} from "../../../Client/Constants";
import {ICON_GOTO} from "../../../../icons";
import {Card} from "primereact/card";
import moment from "moment";
import {durationTemplate} from "../../../PatientDynamicItems/Utils";

export class PaletteMovingComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            moveEvent: this.props.moveEvent,
            selectedItem: this.props.selectedItem,
            menuActive: false,
            showRollover: false,
        }
        this.myRef = React.createRef();

        this.paddingStyle = {paddingTop: 5, paddingLeft: 10, paddingRight: 5};

        this.timer = null;
    }

    onClickHandler = () => {
        this.props.onItemSelected(this.state.moveEvent);
    }

    onRightClickHandler = (e) => {
        this.props.onShowRightClickMoves(e, this.state.moveEvent);
    }

    onMouseOverHandler = (e) => {

        if (!this.state.menuActive) {
            this.timer = setTimeout(() => this.setState({showRollover: true}), 1000);
        }
    }

    onMouseOutHandler = (e) => {
        if (this.timer) {
            this.setState({showRollover: false}, () => {
                clearTimeout(this.timer);
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedItem !== prevProps.selectedItem) {
            this.setState({selectedItem: this.props.selectedItem})
        }
    }

    componentDidMount() {

        try {
            this.myRef.current.addEventListener('click', this.onClickHandler)
            this.myRef.current.addEventListener('contextmenu', this.onRightClickHandler)
            this.myRef.current.addEventListener('mouseover', this.onMouseOverHandler)
            this.myRef.current.addEventListener('mouseout', this.onMouseOutHandler)
        } catch (error) {
            console.log(`Missing parent event node adding listener`);
        }
    }

    showOptionalRollover = (appointmentWith, duration) => {

        const {patientFullName, pjStatus, shortDescription, start, end} = this.state.moveEvent;

        const startTime = moment(new Date(start)).format(`DD-MM-YYYY HH:mm`);
        const endTime = moment(new Date(end)).format(`HH:mm`);

        return this.state.showRollover ?
            <Card title={patientFullName} style={{ width: '20rem', marginBottom: '2em', zIndex:99 }}>
                <p className="p-m-0" style={{lineHeight: '1.5'}}>
                    {`Status : ${pjStatus}`}
                </p>
                <p className="p-m-0" style={{lineHeight: '1.5'}}>
                    {`${shortDescription}`}
                </p>
                <p className="p-m-0" style={{lineHeight: '1.5'}}>
                    {`With : ${appointmentWith.fullName}`}
                </p>
                <p className="p-m-0" style={{lineHeight: '1.5'}}>
                    {`On : ${startTime} - ${endTime}`}
                </p>
                <p className="p-m-0" style={{lineHeight: '1.5'}}>
                    {`For : ${duration}`}
                </p>
            </Card>
            :
            null;
    };

    render() {

        const {moveEvent} = this.state;
        const {id, providerId, patientFullName, start, end} = moveEvent;

        let index = 0;

        const appointmentWith = _.find(this.props.usersShort, user => user.id === providerId);

        const backgroundColor = id === this.state.selectedItem ? SELECTED_ITEM_COLOUR : null;
        const duration = durationTemplate(start, end);
        const content = `${patientFullName} (${appointmentWith.username}) - ${duration}`;

        return (
            <div key={index++}
                 id={id}
                 style={{fontWeight: 'bold', ...this.paddingStyle, backgroundColor}}
                 ref={this.myRef}
                 className='diary-palette-component'
            >
                <i className={ICON_GOTO} style={{fontSize: '20px', paddingRight: '15px', color: COLOR_ChiralPalette}}/>
                {content}
                {this.showOptionalRollover(appointmentWith, duration)}
            </div>
        )
    }

    componentWillUnmount() {

        try {
            this.myRef.current.removeEventListener('click', this.onRightClickHandler)
            this.myRef.current.removeEventListener('contextmenu', this.onClickHandler)
            this.myRef.current.removeEventListener('mouseover', this.onMouseOverHandler)
            this.myRef.current.removeEventListener('mouseout', this.onMouseOutHandler)
        } catch (error) {
            console.log(`Missing parent event node removing listener`);
        }
    }
}
