import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {TabBaseComponent} from "../../TabBaseComponent";
import {RES_EMAIL_SHOT, sendEmailMailShot, sendEmailMailShotTest} from "../../../actions/mailShots";
import {
    HM_notImplemented,
    TB_SAVE,
    TB_SAVE_AND_EXIT,
    TB_SEND_EMAIL_MAIL_SHOT,
    TB_SEND_EMAIL_MAIL_SHOT_TEST
} from "../../../Constants";
import {ICON_CLOSE, ICON_OK, ICON_SAVE_DISABLED, ICON_SAVE_ENABLED,} from "../../../icons";
import {TAB_EXIT, TAB_EXIT_SAVE, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import EmailMailShotHistory from "./History";
import EmailMailShot from "./EmailMailShot";
import {EMS_HISTORY} from "./Constants";
import EmailMailShotErrorBoundary, {emailMailShotHistoryToolBar, emailMailShotToolBar} from "./Utils";
import {setState, SM_EMAIL_MAIL_SHOT, SM_EMAIL_MAIL_SHOTS, stateRequest} from "../../../actions/stateManagement";

class ConnectedEmailMailShots extends TabBaseComponent {

    constructor(props) {
        super(props);

        const source = {
            id: SM_EMAIL_MAIL_SHOTS.id,
            action: RES_EMAIL_SHOT.CLEAR.action,
            saveState: false,
            saveObjects: false,
        };

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_EMAIL_MAIL_SHOTS.id,

                selectedShots: [],

                canSave: {status: false, activeIndex: 0, source},
                showSaveDialog: false,
            }
        }

        this.tabStack = [];
        this.index = 0;

        this.onClose = this.onClose.bind(this);
        this.onSaveNoDialog = this.onSaveNoDialog.bind(this);

        this.toolbarCallbacks = {

            [TB_SAVE.id]: this.onSaveNoDialog,
            [TB_SAVE_AND_EXIT.id]: this.onClose,

            [HM_notImplemented.id]: this.onNotImplemented,
            [TB_SEND_EMAIL_MAIL_SHOT]: this.onSendMailShot,
            [TB_SEND_EMAIL_MAIL_SHOT_TEST]: this.onSendMailShotTest,
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message.type !== prevProps.message.type) {

            switch (this.props.message.type) {
                case RES_EMAIL_SHOT.SAVE.action:

                    this.exitState = TAB_PARENT_CHANGE;

                    const newState = {...this.state};
                    _.set(newState, 'canSave.status', false);
                    _.set(newState, 'showSaveDialog', false);

                    this.setState(newState, () => {
                        this.props.onTabUpdate({key: SM_EMAIL_MAIL_SHOTS.id}, false);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onSendMailShot = () => {

        this.props.sendMailShot({emsId: this.state.selectedEMS.id});
    }

    onSendMailShotTest = () => {

        this.props.sendMailShotTest({emsId: this.state.selectedEMS.id, to: this.props.loginIdentity.id});
    }

    onAddEMSTab = (shot) => {

        const index = _.findIndex(this.state.selectedShots, (target) => target.id === shot.id);

        const selectedShots = [...this.state.selectedShots];

        if (index === -1) {
            selectedShots.push(shot);

            this.setState({selectedShots, canSave: {...this.state.canSave, activeIndex: selectedShots.length}});
        } else {
            this.setState({selectedShots, canSave: {...this.state.canSave, activeIndex: index + 1}});
        }
    }

    onChange = (event) => {

        const source = {
            id: this.state.stateManagementId,
            action: RES_EMAIL_SHOT.SAVE.action,
            saveState: true,
            saveObjects: false,
        };

        const newState = {...this.state};

        if (event === null) {
            _.set(newState, 'canSave.status', true);
            _.set(newState, 'canSave.source', source);

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            })
        } else if (event.owner === 'selectedEMS') {

            _.set(newState, event.owner, event.value);

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });
        } else if (event.owner === 'canSave.status') { // this will be propagated up from the sub tabs

            _.set(newState, event.owner, event.value);
            _.set(newState, 'canSave.status', true);
            _.set(newState, 'canSave.source', source);

            const index = _.findIndex(this.state.selectedShots, (target) => target.id === this.state.selectedEMS.id);

            const shot = {...newState.selectedShots[index]};
            shot.save = event.value;
            newState.selectedEMS = shot;

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        } else {

            _.set(newState, event.owner, event.value);
            _.set(newState, 'canSave.status', true);
            _.set(newState, 'canSave.source', source);

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        }
    }

    onClose = () => {
        const newState = {...this.state};

        if (this.state.canSave.status) {
            this.exitState = TAB_EXIT_SAVE;

            _.set(newState, 'showSaveDialog', true);
            _.set(newState, 'canSave.source.action', RES_EMAIL_SHOT.SAVE_CLEAR.action);

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });
        } else {
            this.onExit();
        }
    }

    onExit = () => {
        const newState = {...this.state};
        this.exitState = TAB_EXIT;

        _.set(newState, 'showSaveDialog', false);
        _.set(newState, 'canSave.source.action', RES_EMAIL_SHOT.CLEAR.action);

        this.setState(newState, () => {
            this.props.onTabUpdate({key: this.state.stateManagementId}, false);
            this.props.onTabCloseClick({key: this.state.stateManagementId});
            this.props.setState(this.state.stateManagementId, null);
        });
    }

    showDialogs() {
        return null;
    }

    render() {

        this.tabStack = [];
        this.index = 0;

        const footer = <div>
            <Button label="Yes" icon={ICON_OK} onClick={() => {
                this.setState({showSaveDialog: false}, () => {
                    this.props.stateRequest(this.state.canSave.source);
                });
            }}/>
            <Button label="No" icon={ICON_CLOSE} onClick={this.onExit}/>
        </div>;

        this.tabStack.push(
            {
                index: this.index++,
                section: EMS_HISTORY, content:
                    <TabPanel key='emsHistory'
                              header="Mail Shots">

                        <EmailMailShotHistory onChange={this.onChange}
                                              onAddEMSTab={this.onAddEMSTab}
                                              onCloseClick={this.props.onCloseClick}
                                              onTabUpdate={this.props.onTabUpdate}
                                              onAdditionalClick={this.props.onPCButtonClick}
                        />
                    </TabPanel>
            });

        this.state.selectedShots.forEach(shot => {
            this.tabStack.push(
                {
                    index: this.index++,
                    key: this.index++,
                    section: `${SM_EMAIL_MAIL_SHOT.id}_${shot.id}`, content:
                        <TabPanel key={`ems_${this.index++}`}
                                  header={shot.title}>

                            <EmailMailShot key={`ems_${this.index++}`}
                                           shot={shot}
                                           emsShotId={shot.id}
                                           editing={shot.editing}
                                           onChange={this.onChange}
                                           onCloseClick={this.props.onCloseClick}
                                           onTabUpdate={this.props.onTabUpdate}
                                           onAdditionalClick={this.props.onPCButtonClick}
                                           parentId={SM_EMAIL_MAIL_SHOTS.id}
                            />
                        </TabPanel>
                });
        });
        const canSave = this.state.canSave.status;
        const saveIcon = canSave ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

        const toolbar = this.state.canSave.activeIndex === 0 ? emailMailShotHistoryToolBar(this.toolbarCallbacks, saveIcon, canSave) : emailMailShotToolBar(this.toolbarCallbacks, saveIcon, canSave);

        return (
            <EmailMailShotErrorBoundary>
                <div id="detailPanel">

                    {this.showDialogs()}

                    <Toolbar {...toolbar}/>

                    <Dialog header="Save Resource"
                            footer={footer}
                            visible={this.state.showSaveDialog}
                            modal={true}
                            onHide={() => this.onCancel(RES_EMAIL_SHOT.CLEAR.action)}>
                        The form has been modified, save these changes?
                    </Dialog>

                    <TabView scrollable={true}
                             style={{paddingTop: '5px'}}
                             activeIndex={this.state.canSave.activeIndex}
                             onTabChange={(e) => {
                                 this.onTabChange(e.index)
                             }}>

                        {this.tabStack.map(tab => {
                            return tab.content
                        })}

                    </TabView>
                </div>
            </EmailMailShotErrorBoundary>
        )
    }
}

const MapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        loginIdentity: state.login.user,

        currentState: state.stateManagement[SM_EMAIL_MAIL_SHOTS.id],
    }
};

const MapDispatchToProps = dispatch => {

    return {
        sendMailShot: (params) => dispatch(sendEmailMailShot(RES_EMAIL_SHOT.SEND, params)),
        sendMailShotTest: (params) => dispatch(sendEmailMailShotTest(RES_EMAIL_SHOT.SEND, params)),
        stateRequest: (source) => dispatch(stateRequest(source)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const EmailMailShots = connect(MapStateToProps, MapDispatchToProps)(ConnectedEmailMailShots);

export default EmailMailShots;

