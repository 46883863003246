import {APPOINTMENT_COLOUR_SCHEME, DAY_LIST_TEXT_FORMAT} from "../Preferences/Constants";
import {
    __CHIRAL_CONTACT_DETAILS__,
    HO_ACTION_BOOK_TP_APP, HO_ACTION_BOOK_TP_APPS_AND_CHARGE, HO_ACTION_CHARGE,
    HO_ACTION_CREATE_NEXT_APP,
    HO_ACTION_CREATE_NEXT_APP_AND_CHARGE
} from "../../../Constants";
import React from "react";

const defaultHeader = {
    left: 'prev,next,today',
    center: "title",
    right: "month,agendaWeek,agendaDay",
    min: new Date(),
    max: new Date(),
    slotDuration: 15
};

export const taskIconSelector = (handoverTask) => {

    switch (handoverTask.action) {
        case HO_ACTION_CREATE_NEXT_APP.name:
        case HO_ACTION_BOOK_TP_APP.name:
            return 'calendarAdd16.png';
        case HO_ACTION_CREATE_NEXT_APP_AND_CHARGE.name:
        case HO_ACTION_BOOK_TP_APPS_AND_CHARGE.name:
            return 'taskCharge16.png';
        case HO_ACTION_CHARGE.name:
            return 'money_pound16.png';
        default:
            return 'calendarAdd16.png';
    }
};

export default class DiaryErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>Something went wrong in this Diary section.</h1>
                    <h2>{__CHIRAL_CONTACT_DETAILS__}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}

export const getDiaryIds = (state, ownProps) => {

    const currentDate = state.diary.currentDate;
    const unplacedAppointment = state.diary.unplacedAppointment;

    const providerColourScheme = state.objectStore[APPOINTMENT_COLOUR_SCHEME];
    const dayListTextFormat = state.objectStore[DAY_LIST_TEXT_FORMAT] ? state.objectStore[DAY_LIST_TEXT_FORMAT] === 'true' : false;
    const userId = state.login.userId;

    const appointmentDiaryTypesLoaded = Boolean(state.housekeeping.appointmentDiaryTypesLoaded);
    const appointmentTypes = appointmentDiaryTypesLoaded ? state.housekeeping.appointmentDiaryTypes.appointmentTypes : [];
    const diaryEventTypes = appointmentDiaryTypesLoaded ? state.housekeeping.appointmentDiaryTypes.diaryEventTypes : [];

    const eventsLoaded = Boolean(state.diary.eventsLoaded) ? state.diary.eventsLoaded : false;
    const clinicianId = (ownProps.options && ownProps.options.clinician) === undefined ? '' : ownProps.options.clinician.id;
    const cliniciansEventsLoaded = state.diary[`cliniciansEventsLoaded_${clinicianId}`] === undefined ? false : state.diary[`cliniciansEventsLoaded_${ownProps.options.clinician.id}`];
    const weekEventsLoaded = Boolean(state.diary.weekEventsLoaded) ? state.diary.weekEventsLoaded : false;

    const diaryHeader = eventsLoaded ? state.diary.header === undefined ? defaultHeader : state.diary.header : defaultHeader;
    const cliniciansHeader = cliniciansEventsLoaded ? state.diary.cliniciansHeader === undefined ? defaultHeader : state.diary[`cliniciansHeader_${ownProps.options.clinician.id}`] : defaultHeader;
    const weekHeader = weekEventsLoaded ? state.diary.weekHeader === undefined ? defaultHeader : state.diary.weekHeader : defaultHeader;
    const selectedWeekIndex = cliniciansEventsLoaded ? state.diary.cliniciansHeader === undefined ? 0 : state.diary[`selectedWeekIndex_${ownProps.options.clinician.id}`] : 0;

    diaryHeader.slotDuration = state.preferences.practiceDetails.timeDiv;
    cliniciansHeader.slotDuration = state.preferences.practiceDetails.timeDiv;
    weekHeader.slotDuration = state.preferences.practiceDetails.timeDiv;

    const diaryResources = eventsLoaded ? state.diary.resources : [];
    const clinicianResources = cliniciansEventsLoaded ? state.diary[`clinicianResources_${ownProps.options.clinician.id}`] : [];
    const weekResources = weekEventsLoaded ? state.diary.weekResources : [];

    const unscheduledResources = eventsLoaded ? state.diary.unscheduledResources : [];

    const diaryEvents = eventsLoaded ? state.diary.events : [];
    const cliniciansEvents = cliniciansEventsLoaded ? state.diary[`cliniciansEvents_${ownProps.options.clinician.id}`] : [];
    const weekEvents = weekEventsLoaded ? state.diary.weekEvents : [];

    const daysActivePopUpsLoaded = state.diary.daysActivePopUpsLoaded;
    const daysActivePopUps = state.diary.daysActivePopUps;

    const dropDownsLoaded = state.dropDowns.dropDownsLoaded;
    const providers = state.dropDowns.providers;
    const titles = dropDownsLoaded ? state.dropDowns.titles : [];

    const movingEventsLoaded = Boolean(state.diary.movingEventsLoaded) ? state.diary.movingEventsLoaded : false;
    const movingEvents = movingEventsLoaded ? state.diary.movingEvents : [];
    const recallEvents = movingEventsLoaded ? state.diary.recallEvents : [];
    const diaryTasks = movingEventsLoaded ? state.diary.diaryTasks : [];

    const newDiaryEvent = state.diary.newDiaryEvent;

    const labWorkSearchComplete = state.findLaboratories.labWorkSearchComplete;
    const labWorkResults = state.findLaboratories.labWorkResults;

    return {

        currentDate,
        unplacedAppointment,

        userId,

        appointmentDiaryTypesLoaded,
        appointmentTypes,
        diaryEventTypes,

        dropDownsLoaded,
        providers,

        diaryEvents,
        eventsLoaded,

        cliniciansEvents,
        cliniciansEventsLoaded,

        weekEvents,
        weekEventsLoaded,

        diaryHeader,
        cliniciansHeader,
        weekHeader,

        diaryResources,
        clinicianResources,
        weekResources,

        unscheduledResources,
        providerColourScheme,
        dayListTextFormat,

        movingEventsLoaded,
        movingEvents,
        recallEvents,
        diaryTasks,

        titles,

        newDiaryEvent,

        daysActivePopUpsLoaded,
        daysActivePopUps,

        labWorkSearchComplete,
        labWorkResults,

        selectedWeekIndex,
    }
};
