import React from 'react';
import _ from 'lodash';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {DE_TYPE_ONLINE_BOOKING, DE_TYPE_VIDEO_CONF, HM_notImplemented, SR_CLINIC} from "../../../../Constants";
import {AppointmentComponent} from "./DiaryAppointmentComponent";
import {EventComponent} from "./DiaryEventComponent";

export const showConfirmed = (event) => {

    if (event.discriminator === SR_CLINIC && event.confirmed) {
        return <i className={`fa flagGreen16`}/>
    } else
        return null;
};

export const showNHSPatient = (event) => {

    if (event.discriminator === SR_CLINIC && event.source.nhspatient) {
        return <i className={`fa nhsLogo16`}/>
    } else
        return <i className="fa-solid fa-square-p"></i>
};

export const showAppointmentType = (event, appointmentTypes) => {

    if (event.type.id === null)
        return null;

    const det = _.find(appointmentTypes, det => det.id === event.type.id);

    switch (det.onlineType) {
        case DE_TYPE_ONLINE_BOOKING.name:
            return <i className={DE_TYPE_ONLINE_BOOKING.iconName}/>
        case DE_TYPE_VIDEO_CONF.name:
            return <i className={DE_TYPE_VIDEO_CONF.iconName}/>
        default:
            const iconName = det.iconName ? det.iconName : '';
            return <i className={`fa ${iconName.replace(/\./g, "_")}`}/>
    }
};

export const showWorkRequired = (event) => {

    if (event.discriminator === SR_CLINIC && event.workRequired) {
        return <i className={`fa workRequired16`}/>
    } else
        return null;
};

export const showAppointmentText = (event, privacyOn, practiceDetails) => {

    if (privacyOn) {
        return `${event.patientFullname} : (${event.title})`.replace(/\w/g, "x");
    } else {

        const knownAs = event.source.knownAs.trim();
        const name = knownAs === '' ? event.patientFullname : `${event.patientFullname} (${knownAs})`;
        const assignedTo = event.assignedTo ? ` (${event.assignedTo})` : '';

        if (!practiceDetails.appointmentDetails || practiceDetails.appointmentDetails) {
            return event.title === undefined ? `${name}${assignedTo}` : `${name}${assignedTo} : [${event.title}] <${event.source.pjStatus}>`;
        } else {
            return name;
        }
    }
};

export const DiaryEventContent = ({event}, privacyOn, appointmentTypes, diary, onShowRightClick, practiceDetails) => {

    switch (event.discriminator) {
        case SR_CLINIC:

            return <AppointmentComponent event={event}
                                         privacyOn={privacyOn}
                                         appointmentTypes={appointmentTypes}
                                         diary={diary}
                                         onShowRightClick={onShowRightClick}
                                         id={event.id}
                                         practiceDetails={practiceDetails}
            />
        default:
            return <EventComponent event={event}
                                   diary={diary}
                                   onShowRightClick={onShowRightClick}
                                   id={event.id}
            />
    }
};

export const findEvent = (events, eventId) => {

    const index = _.findIndex(events, (event) => {
        return event.id === parseInt(eventId, 10);
    });
    return events[index];
};

export const getDiaryReservationType = (events) => {

    const index = _.findIndex(events, (event) => {
        return event.reservationType;
    });
    return events[index];
};

export const ShowQuestionDialog = (parent, hm, onOK) => {

    if (!parent.state[hm.id]) {
        return null;
    }

    const footer = <div key='sqd_0'>
        <Button label="Yes" icon="fa fa-check" onClick={() => {
            onOK();
        }}/>
        <Button label="No" icon="fas fa-times" onClick={() => {
            parent.onHideMenuEntry(hm.id);
        }}/>
    </div>;

    return (

        <Dialog header={hm.header}
                visible={parent.state[hm.id]}
                modal={true}
                resizable={true}
                footer={footer}
                onHide={() => {
                    parent.onHideMenuEntry(hm.id);
                }}>
            {hm.message}
        </Dialog>
    )
};

export const ShowQuestionDialog2 = (parent, hm, onOK, onNotOK) => {

    if (!parent.state[hm.id]) {
        return null;
    }

    const footer = <div key='sqd_0'>
        <Button label="Yes" icon="fa fa-check" onClick={() => {
            onOK();
        }}/>
        <Button label="No" icon="fas fa-times" onClick={() => {
            onNotOK()
        }}/>
    </div>;

    return (

        <Dialog header={hm.header}
                visible={parent.state[hm.id]}
                modal={true}
                resizable={true}
                footer={footer}
                onHide={() => {
                    parent.onHideMenuEntry(hm.id);
                }}>
            {hm.message}
        </Dialog>
    )
};

export const ShowQuestionDialog3 = (parent, hm, onFirst, onSecond, {confirmationRescheduleEmail, confirmationRescheduleSMS}) => {

    if (!parent.state[hm.id]) {
        return null;
    }

    const secondDisabled = !(confirmationRescheduleEmail || confirmationRescheduleSMS);

    const footer = <div key='sqd_0'>
        <Button label={hm.firstLabel} icon="fa fa-check" onClick={() => {
            onFirst(false);
        }}/>
        <Button label={hm.secondLabel}
                icon="fas fa-check"
                disabled={secondDisabled}
                onClick={() => {
                    onSecond(true)
                }}/>
        <Button label='Cancel' icon="fas fa-times" onClick={() => {
            parent.onHideMenuEntry(hm.id);
        }}/>
    </div>;

    return (

        <Dialog header={hm.header}
                visible={parent.state[hm.id]}
                modal={true}
                resizable={true}
                footer={footer}
                onHide={() => {
                    parent.onHideMenuEntry(hm.id);
                }}>
            {hm.message}
        </Dialog>
    )
};

export const ShowMessageDialog = (parent, hm, callback, _message) => {

    const visible = parent.state[hm.id];

    if (!visible) {
        return null;
    }

    const footer = <div>
        <Button label="Close" icon="fas fa-times" onClick={() => {
            if (callback) {
                callback(hm.id);
            } else {
                parent.onHideMenuEntry(hm.id);
            }
        }}/>
    </div>;

    const message = _message === undefined ? '' : _message;
    return (

        <Dialog header={hm.header}
                visible={true}
                modal={true}
                resizable={false}
                footer={footer}
                onHide={() => {
                    parent.onHideMenuEntry(hm.id);
                }}>
            {`${hm.message} ${message}`.trim()}
        </Dialog>
    )
};

export const ShowOKMessageDialog = (parent, hm, callback, _message) => {

    const visible = parent.state[hm.id];

    if (!visible) {
        return null;
    }

    const footer = <div>
        <Button label="OK" icon="fas fa-times" onClick={() => {
            if (callback !== undefined) {
                callback(hm.id);
            } else {
                parent.onHideMenuEntry(hm.id);
            }
        }}/>
    </div>;

    const message = _message === undefined ? '' : _message;
    return (

        <Dialog header={hm.header}
                visible={true}
                modal={true}
                resizable={false}
                footer={footer}
                onHide={() => {
                    parent.onHideMenuEntry(hm.id);
                }}>
            {`${hm.message} ${message}`.trim()}
        </Dialog>
    )
};

export const ShowUnimplementedDialog = (parent) => {

    const footer = <div>
        <Button label="Close" icon="fas fa-times" onClick={() => {
            parent.onHideMenuEntry(HM_notImplemented.id);
        }}/>
    </div>;

    return (

        <Dialog header={HM_notImplemented.header}
                visible={parent.state[HM_notImplemented.id]}
                modal={true}
                resizable={false}
                footer={footer}
                onHide={() => {
                    parent.onHideMenuEntry(HM_notImplemented.id);
                }}>
            {HM_notImplemented.message}
        </Dialog>
    )
};

export const ShowMessageDialogWithCallBack = (parent, hm, callback) => {

    if (!parent.state[hm.id]) {
        return null;
    }

    const footer = <div>
        <Button label="Close" icon="fas fa-times" onClick={() => {
            callback(hm.id)
        }}/>
    </div>;

    return (

        <Dialog header={hm.header}
                visible={parent.state[hm.id]}
                modal={true}
                resizable={false}
                footer={footer}
                onHide={() => {
                    callback(hm.id)
                }}>
            {hm.message}
        </Dialog>
    )
};
