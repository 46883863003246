import _ from "lodash";

import {TAB_CHANGE, TAB_EXIT, TAB_EXIT_SAVE, TAB_PARENT_CHANGE, TAB_SAVE} from "./FixedItems/Housekeeping/Constants";
import * as Capabilities from "./Capabilities";
import {PDFBaseComponent} from "./PDFBaseComponent";

export class TabBaseComponent extends PDFBaseComponent {

    constructor(props) {
        super(props);

        this.exitState = TAB_PARENT_CHANGE;

        this.tabStack = [];
        this.index = 0;

        this.filterSection = this.filterSection.bind(this);

        this.onSaveNoDialog = this.onSaveNoDialog.bind(this);
        this.onSaveExit = this.onSaveExit.bind(this);
        this.onCancel = this.onCancel.bind(this);

        this.onShowMenuEntry = this.onShowMenuEntry.bind(this);
        this.onHideMenuEntry = this.onHideMenuEntry.bind(this);
        this.onNotImplemented = this.onNotImplemented.bind(this);

        this.onChange = this.onChange.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.setValue = this.setValue.bind(this);
    }

    componentDidMount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }

    componentWillUnmount() {

        if (this.state.canSave.source) {

            switch (this.exitState) {
                case TAB_EXIT:

                    if (this.state.canSave.source) {
                        this.props.stateRequest(this.state.canSave.source);
                        this.props.onTabUpdate({key: this.state.stateManagementId}, false);
                    }
                    break;
                    
                case TAB_EXIT_SAVE:

                    if (this.state.canSave.source) {
                        this.props.stateRequest(this.state.canSave.source);
                        this.props.onTabUpdate({key: this.state.stateManagementId}, false);
                    }
                    break;

                case TAB_CHANGE:
                case TAB_PARENT_CHANGE:

                    this.props.setState(this.state.stateManagementId, {...this.state});
                    break;

                default:
                    break;
            }
        }
    }

    onCancel(action) {

        this.exitState = TAB_EXIT;

        this.setState({
            showSaveDialog: false,
            canSave: {status: false, activeIndex: 0, source: {action}},
        }, () => {
            this.props.onCloseClick({key: this.state.stateManagementId});
        });
    }

    filterSection(control, content) {

        if (this.props.capabilities === undefined || _.includes(this.props.capabilities, control) || control === Capabilities.AID_ALL) {
            content.index = this.index++;
            this.tabStack.push(content)
        }
    }

    onChange(event) {

        const owner = _.get(this.state, event.owner);

        let newState = {...this.state};

        switch (event.owner) {
            case 'selectedClaim.providerPIN' :
            case 'provider.providerPIN' :

                if (event.value.length < 7) {
                    if (isNaN(event.value)) {
                        _.set(newState, 'pinValid', false);
                    } else {
                        _.set(newState, 'pinValid', event.value.length === 6);
                        _.set(newState, event.owner, event.value);
                    }
                }
                // avoid updating the tab edited flag if claim delete dialog owner
                if (event.owner === 'selectedClaim.providerPIN') {
                    this.setState(newState);
                    return;
                }
                break;
            case 'selectedClaim.narrative' :
                // avoid updating the tab edited flag if claim delete dialog owner
                _.set(newState, event.owner, event.value);
                this.setState(newState);
                return;
            default:
                if (Array.isArray(owner)) {
                    if (event.delete) {
                        _.remove(owner, event.value)
                    } else {
                        owner.push(event.value);
                    }
                    _.set(newState, event.owner, owner);
                } else {
                    _.set(newState, event.owner, event.value);
                }
                break;
        }

        newState.canSave.status = true;
        newState.canSave.source = event.source;
        this.setState(newState, () => {
            this.props.onTabUpdate({key: this.props.id}, true);
        });
    }

    onSaveExit() {

        if (this.state.canSave.status) {

            this.setState({showSaveDialog: true})
        } else {

            this.exitState = TAB_EXIT;

            this.setState({canSave: {status: false}});
            this.props.onTabUpdate({key: this.props.id}, false);
            this.props.onCloseClick({key: this.props.id});
        }
    }

    onPageFlex(e, target, rows) {
        if (rows !== undefined)
            this.setState({[target]: e.first, [rows]: e.rows});
        else
            this.setState({[target]: e.first});
        this.save = true;
    }

    onSaveNoDialog = () => {

        this.exitState = TAB_SAVE;
        const {canSave} = this.state;

        if (canSave.source.saveState) {
            this.props.stateRequest(canSave.source);
        }

        if (canSave.source.saveObjects) {
            this.props.setObjectStore(canSave.source);
        }
    }

    onTabChange(index) {

        this.exitState = TAB_CHANGE;
        const {canSave} = this.state;

        canSave.activeIndex = index;

        this.setState({canSave}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });
    }

    setValue(target, value) {
        this.setState({[target]: value});
    }
}
