import * as Actions from './index';
import {RES_HOUSEKEEPING, RES_HOUSEKEEPING_DOC_CLASSIFICATIONS, RES_TEMPLATE_NOTES} from "./housekeeping";
import _ from 'lodash'
import {ac} from '../index'
import {RES_PREFERENCES} from "./preferences";
import {RES_CLINICIAN_DIARY, RES_PRACTICE_DIARY} from "./diary";
import {RES_TREATMENT_PLAN_BY_APP_ID, RES_TREATMENT_PLAN_BY_ID, RES_TREATMENT_PLANNING} from "./treatmentPlanning";
import {
    RES_FIND_PATIENTS,
    RES_PATIENT_DETAILS,
    RES_PATIENT_MHFS, RES_PATIENT_PAYMENTS,
    RES_PATIENT_QUESTIONNAIRE,
    RES_PATIENT_XRAY_RECORDS
} from "./personal";
import {RES_getRecallsDue} from "./recalls";
import {RES_FIND_USERS, RES_getUsersAndCapabilities} from "./users";
import {savePlan, savePlan2} from "./Utils/treatmentPlanning";
import {savePaymentPlan} from "./Utils/paymentPlanning";
import {
    patientMHFNoChange,
    savePatient,
    savePatientMHF,
    savePatientPortal,
    savePatientQuestionnaire, saveTabletPatient
} from "./Utils/personal";
import {saveHouskeeping} from "./Utils/housekeeping";
import {savePreferences} from "./Utils/preferences";
import {saveNoteFavourites, saveTemplateNotes} from "./Utils/templateNotes";
import {RES_getRemindersDue} from "./reminders";
import {
    TAB_MedicalHistoryUpdate,
    TAB_PatientConciege,
    TAB_PatientConsentForms,
    TAB_PatientDetailsUpdate,
    TAB_PaymentPlanSigns,
    TAB_Questionnaire,
    TAB_TreatmentPlanSigns
} from "../components/Tablet/Constants";
import {saveRoleCapabilities, saveUserCapabilities} from "./Utils/users";
import {RES_PAYMENT_PLAN_BY_ID, RES_PAYMENT_PLANNING, RES_PP_DUE_INSTALLMENTS} from "./paymentPlanning";
import {
    RES_REPORT_DAILY_CANCELS,
    RES_REPORT_DAILY_DELETES,
    RES_REPORT_DAILY_TRANSACTIONS,
    RES_REPORT_DAILYCASH,
    RES_REPORT_IN_CREDIT, RES_REPORT_MONTHLY_SHARE,
    RES_REPORT_MONTHS_CASH,
    RES_REPORT_PAT_JOURNEYS,
    RES_REPORT_PAYMENT_PLANS,
    RES_REPORT_VOIDS,
    RES_REPORT_WEEKLY_CANCELS,
    RES_REPORT_WEEKLY_DELETES, RES_REPORT_WEEKLY_SHARE,
    RES_REPORT_WEEKLY_XRAY,
    RES_REPORT_WORK_RECEIVED,
    RES_REPORT_WORK_REQUESTED,
    RES_REPORT_WORK_REQUIRED_DUE,
    RES_REPORT_WRITE_OFFS,
    RES_REPORT_YEARS_CASH
} from "./reports";
import {RES_FIND_REFERRERS, RES_REFERRER_DETAILS} from "./findReferrers";
import {saveReferrer} from "./Utils/referrers";
import {
    ICON_CERTIFICATE,
    ICON_GIFT,
    ICON_LAB,
    ICON_LOCATION_ARROW,
    ICON_PICKUP,
    ICON_SEARCH,
    ICON_UPLOAD
} from "../icons";
import {RES_EMAIL_SHOT, RES_SMS_SHOT} from "./mailShots";
import {addEmailShot, addSMSShot, saveEmailShot, saveSMSShot} from "./Utils/mailShots";
import {RES_SUBSCRIPTION_DETAILS} from "./admin";
import {RES_FIND_LABORATORIES, RES_LABORATORY_DETAILS, RES_WORK_REQUIRED} from "./findLaboratories";
import {RES_NHS_MANAGEMENT_CLAIMS} from "./nhsManagement";
import {ME_UploadPayments, NewPassword} from "../Constants";
import {RES_getAccountGroups} from "./accountGroups";
import {saveAccountGroups} from "./Utils/accountGroups";

// help urls

export const CLIENT_KNOWLEDGE_BASE = `http://chiraldocs.hopto.org/knowledge-base/client`;

export const KNOWLEDGE_BASE = `http://chiraldocs.hopto.org/knowledge-base/`;
export const CATEGORIES_BASE = `http://chiraldocs.hopto.org/article-categories/`;

export const HELP_PRACTICE_DIARY = `${KNOWLEDGE_BASE}/the-practice-diary/`;
export const HELP_CLINICIAN_DIARY = `${KNOWLEDGE_BASE}/the-clinician-diary/`;
export const HELP_PRACTICE_WEEK_DIARY = `${KNOWLEDGE_BASE}/the-practice-week-diary/`;

export const HELP_ADD_PATIENT = `${KNOWLEDGE_BASE}/add-a-new-patient/`;
export const HELP_FIND_PATIENT = `${KNOWLEDGE_BASE}/find-patient/`;

export const HELP_PATIENT_SUMMARY = `${KNOWLEDGE_BASE}/patient-summary/`;
export const HELP_PATIENT_TREATMENT = `${KNOWLEDGE_BASE}/patient-treatment-history-2/`;
export const HELP_PATIENT_ACCOUNT = `${KNOWLEDGE_BASE}/account-history/`;
export const HELP_PATIENT_RECALLS = `${KNOWLEDGE_BASE}/recall-settings/`;
export const HELP_PATIENT_REMINDERS = `${KNOWLEDGE_BASE}/reminder-settings/`;
export const HELP_PATIENT_PERSONAL = `${KNOWLEDGE_BASE}/patient-personal-details/`;
export const HELP_PATIENT_BILLING_GROUP = `${KNOWLEDGE_BASE}/patient-billing-group/`;
export const HELP_PATIENT_NOTES = `${KNOWLEDGE_BASE}/patient-notes/`;
export const HELP_PATIENT_MED_HISTORY = `${KNOWLEDGE_BASE}/patient-medical-history/`;
export const HELP_PATIENT_DOCUMENTS = `${KNOWLEDGE_BASE}/patient-documents/`;
export const HELP_PATIENT_IMAGES = `${KNOWLEDGE_BASE}/patient-images/`;
export const HELP_PATIENT_ORTHO_ASS = `${KNOWLEDGE_BASE}/patient-orthodontic-assessment/`;
export const HELP_PATIENT_ASSESSMENT = `${KNOWLEDGE_BASE}/patient-assessment-2/`;
export const HELP_PATIENT_PERIO = `${KNOWLEDGE_BASE}/patient-periodontal-chart-2/`;
export const HELP_PATIENT_NHS_REG = `${KNOWLEDGE_BASE}/patient-nhs-registration-details/`;
export const HELP_PATIENT_NHS_HIST = `${KNOWLEDGE_BASE}/patient-nhs-claim-history/`;

export const HELP_PATIENT_INDEXS = [
    HELP_PATIENT_SUMMARY, HELP_PATIENT_TREATMENT, HELP_PATIENT_ACCOUNT, HELP_PATIENT_RECALLS, HELP_PATIENT_REMINDERS, HELP_PATIENT_PERSONAL, HELP_PATIENT_BILLING_GROUP,
    HELP_PATIENT_NOTES, HELP_PATIENT_MED_HISTORY, HELP_PATIENT_DOCUMENTS, HELP_PATIENT_IMAGES, HELP_PATIENT_ORTHO_ASS, HELP_PATIENT_ASSESSMENT, HELP_PATIENT_PERIO,
    HELP_PATIENT_NHS_REG, HELP_PATIENT_NHS_HIST
];

export const HELP_PATIENT_ACCESS = `${KNOWLEDGE_BASE}/patient-access/`;

export const HELP_APPOINTMENT_INDEXS = [
    HELP_PATIENT_SUMMARY, HELP_PATIENT_TREATMENT, HELP_PATIENT_ACCOUNT, HELP_PATIENT_RECALLS, HELP_PATIENT_REMINDERS, HELP_PATIENT_PERSONAL, HELP_PATIENT_BILLING_GROUP,
    HELP_PATIENT_NOTES, HELP_PATIENT_MED_HISTORY, HELP_PATIENT_DOCUMENTS, HELP_PATIENT_IMAGES, HELP_PATIENT_ORTHO_ASS, HELP_PATIENT_ASSESSMENT, HELP_PATIENT_PERIO,
    HELP_PATIENT_NHS_REG, HELP_PATIENT_NHS_HIST
];

export const HELP_ADD_USER = `${KNOWLEDGE_BASE}/add-a-new-user/`;
export const HELP_FIND_USER = `${KNOWLEDGE_BASE}/find-user/`;
export const HELP_USER_CAPS = `${KNOWLEDGE_BASE}/user-capabilities/`;

export const HELP_USER_PAGE_PERSONAL = `${KNOWLEDGE_BASE}/user-details-personal/`;
export const HELP_USER_PAGE_APP_HIST = `${KNOWLEDGE_BASE}/user-appointment-history/`;
export const HELP_USER_PAGE_PATIENTS = `${KNOWLEDGE_BASE}/users-patients/`;
export const HELP_USER_PAGE_UDA = `${KNOWLEDGE_BASE}/user-uda-management/`;
export const HELP_USER_CAPABILITIES = `${KNOWLEDGE_BASE}/user-capabilities/`;
export const HELP_USER_ACCOUNT_GROUPS = `${KNOWLEDGE_BASE}/user-account-groups/`;
export const HELP_USER_SHOW_WITH = `${KNOWLEDGE_BASE}/user-show-with/`;

export const HELP_TEMPLATES_SYSTEM = `${KNOWLEDGE_BASE}/templates-system/`;
export const HELP_TEMPLATES_PATIENT = `${KNOWLEDGE_BASE}/templates-patient/`;
export const HELP_TEMPLATE_NOTES = `${KNOWLEDGE_BASE}/template-notes/`;

export const HELP_PATIENT_PAYMENT_UPLOADS = `${KNOWLEDGE_BASE}/patient-payment-uploads/`;

export const HELP_USER_INDEXS = [
    HELP_USER_PAGE_PERSONAL, HELP_USER_PAGE_APP_HIST, HELP_USER_PAGE_PATIENTS, HELP_USER_PAGE_UDA
];

export const HELP_ADD_REFERRER = `${KNOWLEDGE_BASE}/add-a-new-referrer/`;
export const HELP_FIND_REFERRER = `${KNOWLEDGE_BASE}/find-referrer/`;

export const HELP_REFERRER_PAGE_PERSONAL = `${KNOWLEDGE_BASE}/referrer-details-personal/`;
export const HELP_REFERRER_PAGE_PATIENTS = `${KNOWLEDGE_BASE}/referrer-patients/`;

export const HELP_REFERRER_INDEXS = [
    HELP_REFERRER_PAGE_PERSONAL, HELP_REFERRER_PAGE_PATIENTS
];

export const HELP_ADD_LABORATORY = `${KNOWLEDGE_BASE}/add-a-new-laboratory/`;
export const HELP_FIND_LABORATORY = `${KNOWLEDGE_BASE}/find-laboratory/`;

export const HELP_LABORATORY_PAGE_DETAILS = `${KNOWLEDGE_BASE}/laboratory-details/`;
export const HELP_LABORATORY_PAGE_PATIENTS = `${KNOWLEDGE_BASE}/laboratory-patients/`;

export const HELP_LABORATORY_INDEXS = [
    HELP_LABORATORY_PAGE_DETAILS, HELP_LABORATORY_PAGE_PATIENTS
];

export const HELP_HOUSE_KEEPING = `${CATEGORIES_BASE}/house-keeping/`;
export const HELP_HOUSE_KEEPING_TITLES = `${KNOWLEDGE_BASE}/housekeeping-titles/`;
export const HELP_HOUSE_KEEPING_TREATMENT_CODES = `${KNOWLEDGE_BASE}/treatment-codes-2/`;
export const HELP_HOUSE_KEEPING_COST_CENTERS = `${KNOWLEDGE_BASE}/housekeeping-cost-centers/`;
export const HELP_HOUSE_KEEPING_TREATMENT_FAVS = `${KNOWLEDGE_BASE}/housekeeping-treatment-favorites/`;
export const HELP_HOUSE_KEEPING_BASE_CHART_FAVS = `${KNOWLEDGE_BASE}/base-charting-favourites/`;
export const HELP_HOUSE_KEEPING_DIARY_SCHEDULES = `${KNOWLEDGE_BASE}/base-charting-favourites/`;
export const HELP_HOUSE_KEEPING_DIARY_TYPES = `${KNOWLEDGE_BASE}/base-charting-favourites/`;
export const HELP_HOUSE_KEEPING_RECALL_TYPES = `${KNOWLEDGE_BASE}/base-charting-favourites/`;
export const HELP_HOUSE_KEEPING_PAT_JOURNEY = `${KNOWLEDGE_BASE}/base-charting-favourites/`;
export const HELP_HOUSE_KEEPING_MED_CONDITIONS = `${KNOWLEDGE_BASE}/base-charting-favourites/`;
export const HELP_HOUSE_KEEPING_QUESTIONNAIRE = `${KNOWLEDGE_BASE}/base-charting-favourites/`;
export const HELP_HOUSE_KEEPING_CANCELS = `${KNOWLEDGE_BASE}/base-charting-favourites/`;
export const HELP_HOUSE_KEEPING_OCCUPATIONS = `${KNOWLEDGE_BASE}/base-charting-favourites/`;

export const HELP_HSK_INDEXS = [
    HELP_HOUSE_KEEPING_TITLES, HELP_HOUSE_KEEPING_TREATMENT_CODES, HELP_HOUSE_KEEPING_COST_CENTERS, HELP_HOUSE_KEEPING_TREATMENT_FAVS, HELP_HOUSE_KEEPING_BASE_CHART_FAVS,
    HELP_HOUSE_KEEPING_DIARY_SCHEDULES, HELP_HOUSE_KEEPING_DIARY_TYPES, HELP_HOUSE_KEEPING_RECALL_TYPES, HELP_HOUSE_KEEPING_PAT_JOURNEY, HELP_HOUSE_KEEPING_MED_CONDITIONS,
    HELP_HOUSE_KEEPING_QUESTIONNAIRE, HELP_HOUSE_KEEPING_CANCELS, HELP_HOUSE_KEEPING_OCCUPATIONS
];

export const HELP_PREF_ACCOUNTING = `${KNOWLEDGE_BASE}/accounting/`;
export const HELP_PREF_CHART_SYSTEMS = `${KNOWLEDGE_BASE}/charting-systems/`;
export const HELP_PREF_DIARY_SETTINGS = `${KNOWLEDGE_BASE}/diary-settings/`;
export const HELP_PREF_DIARY_COL_CODES = `${KNOWLEDGE_BASE}/diary-colour-selection/`;
export const HELP_PREF_EMAIL_SETTINGS = `${KNOWLEDGE_BASE}/email-account-settings/`;
export const HELP_PREF_ESENDEX = `${KNOWLEDGE_BASE}/esendex/`;
export const HELP_PREF_PRACTICE_DETS = `${KNOWLEDGE_BASE}/practice-details/`;
export const HELP_PREF_PRACTICE_LOCS = `${KNOWLEDGE_BASE}/practice-locations/`;
export const HELP_PREF_PRACTICE_HOURS = `${KNOWLEDGE_BASE}/practice-hours/`;
export const HELP_PREF_NHS_REGS = `${KNOWLEDGE_BASE}/nhs-registration/`;
export const HELP_PREF_RECS_REMS = `${KNOWLEDGE_BASE}/recalls-reminders/`;
export const HELP_PREF_REPS_EMAILS_SMS = `${KNOWLEDGE_BASE}/reports-emails-sms/`;

export const HELP_PREF_INDEXS = [
    HELP_PREF_ACCOUNTING,
    HELP_PREF_CHART_SYSTEMS,
    HELP_PREF_DIARY_SETTINGS,
    HELP_PREF_DIARY_COL_CODES,
    HELP_PREF_EMAIL_SETTINGS,
    HELP_PREF_ESENDEX,
    HELP_PREF_PRACTICE_DETS,
    HELP_PREF_PRACTICE_LOCS,
    HELP_PREF_PRACTICE_HOURS,
    HELP_PREF_NHS_REGS,
    HELP_PREF_RECS_REMS,
    HELP_PREF_REPS_EMAILS_SMS
];

export const HELP_TEMP_NOTES_NOTES = `${KNOWLEDGE_BASE}/template-notes/`;
export const HELP_TEMP_NOTES_FAVS = `${KNOWLEDGE_BASE}/template-favvs/`;

export const HELP_TEMP_NOTES_INDEXS = [
    HELP_TEMP_NOTES_NOTES,
    HELP_TEMP_NOTES_FAVS,
];

export const HELP_RECALLS_DUE = `${KNOWLEDGE_BASE}/recalls-due/`;
export const HELP_REMINDERS_DUE = `${KNOWLEDGE_BASE}/reminders-due/`;

export const HELP_ONLINE_REGISTRATIONS = `${KNOWLEDGE_BASE}/online-registrations/`;
export const HELP_WORK_REQUESTED = `${KNOWLEDGE_BASE}/work-requested/`;
export const HELP_WORK_REQUIRED = `${KNOWLEDGE_BASE}/work-required/`;
export const HELP_WORK_RECEIVED = `${KNOWLEDGE_BASE}/work-received/`;

export const HELP_QUESTIONNAIRES_PRECOMPLETE = `${KNOWLEDGE_BASE}/questionnaires-precomplete/`;
export const HELP_QUESTIONNAIRES_INCOMPLETE = `${KNOWLEDGE_BASE}/questionnaires-incomplete/`;
export const HELP_QUESTIONNAIRES_COMPLETE = `${KNOWLEDGE_BASE}/questionnaires-complete/`;

export const HELP_DIARY_SORT_ORDER = `${KNOWLEDGE_BASE}/diary-sort-order/`;

export const HELP_DIARY_INDEXS = [
    HELP_DIARY_SORT_ORDER,
];

export const HELP_REP_OUTSTANDING_ACCS = `${CATEGORIES_BASE}/report-outstanding-accounts/`;
export const HELP_REP_ACCS_IN_CREDIT = `${CATEGORIES_BASE}/report-accounts-in-credit/`;
export const HELP_REP_UNINV_CHARGES = `${CATEGORIES_BASE}/report-uninvoiced-charges/`;
export const HELP_REP_INCOMPLETE_CHARGES = `${CATEGORIES_BASE}/report-incomplete-charges/`;
export const HELP_REP_DAYS_CASH = `${CATEGORIES_BASE}/report-days-cash/`;
export const HELP_REP_DAILY_TRANS = `${CATEGORIES_BASE}/report-daily-transactions/`;
export const HELP_REP_WEEKS_SHARE = `${CATEGORIES_BASE}/report-weeks-share/`;
export const HELP_REP_MONTHLY_TRANS = `${CATEGORIES_BASE}/report-monthly-transactions/`;
export const HELP_REP_MONTHS_CASH = `${CATEGORIES_BASE}/report-months-cash/`;
export const HELP_REP_MONTHS_SHARE = `${CATEGORIES_BASE}/report-months-share/`;
export const HELP_REP_YEARS_CASH = `${CATEGORIES_BASE}/report-years-cash/`;
export const HELP_REP_YEARS_SHARE = `${CATEGORIES_BASE}/report-years-share/`;
export const HELP_REP_PAYMENT_PLANS = `${CATEGORIES_BASE}/report-payment-plans/`;
export const HELP_REP_VOIDS = `${CATEGORIES_BASE}/report-voids/`;
export const HELP_REP_WRITE_OFFS = `${CATEGORIES_BASE}/report-write-offs/`;
export const HELP_REP_COST_CENTERS = `${CATEGORIES_BASE}/report-cost-centers/`;
export const HELP_REP_DAYS_CANCELS = `${CATEGORIES_BASE}/report-days-cancel/`;
export const HELP_REP_WEEKS_CANCELS = `${CATEGORIES_BASE}/report-weeks-cancel/`;
export const HELP_REP_WEEKS_FTAS = `${CATEGORIES_BASE}/report-weeks-ftas/`;
export const HELP_REP_WEEKS_XRAY_SUMMARY = `${CATEGORIES_BASE}/report-weeks-xray-summary/`;
export const HELP_REP_WEEKS_XRAY_REPORT = `${CATEGORIES_BASE}/report-weeks-xray-report/`;
export const HELP_REP_WEEKS_SEDATION_SUMMARY = `${CATEGORIES_BASE}/report-weeks-sedation-summary/`;
export const HELP_REP_WEEKS_SEDATION_REPORT = `${CATEGORIES_BASE}/report-weeks-sedation-report/`;
export const HELP_REP_RECALL_MGMT = `${CATEGORIES_BASE}/report-recall-management/`;
export const HELP_REP_PATIENT_JOURNEY = `${CATEGORIES_BASE}/report-patient-journey/`;
export const HELP_REP_TREATMENT_CODES = `${CATEGORIES_BASE}/report-treatment-codes/`;
export const HELP_REP_CLINICIAN_USAGE = `${CATEGORIES_BASE}/report-clinician-usage/`;
export const HELP_REP_COP9 = `${CATEGORIES_BASE}/report-cop9/`;
export const HELP_REP_KPIS = `${CATEGORIES_BASE}/report-practice-analytics/`;
export const HELP_REP_NHS_MANAGEMENT = `${CATEGORIES_BASE}/report-nhs-management/`;
export const HELP_REP_INSTALLMENTS = `${CATEGORIES_BASE}/report-pp-installments/`;

export const HELP_RELEASE_NOTES = `${CATEGORIES_BASE}/release-notes/`;

export const SM_404_PAGE_ERROR = {
    route: '/notFound',
}

export const SM_404_CLOUD_PAGE_ERROR = {
    route: '/cloudNotFound',
}

export const SM_500_CLOUD_PAGE_ERROR = {
    route: '/cloudInactive',
}

export const SM_404_CLOUD_LAB_PAGE_ERROR = {
    route: '/cloudLabNotFound',
}

export const SM_PRACTICE_CONTROL_CENTER = {
    id: 'SM_PRACTICE_CONTROL_CENTER',
    label: 'Home',
    icon: 'fa pcc16',
    tabIcon: 'fas fa-home'
};

export const SM_ADMIN_CONTROL_CENTER = {
    id: 'SM_ADMIN_CONTROL_CENTER',
    label: 'Home',
    icon: 'fas fa-home',
    tabIcon: 'fas fa-home'
};

export const SM_PRACTICE_DIARY = {
    loaded: 'SM_PRACTICE_DIARYLoaded',
    id: 'SM_PRACTICE_DIARY',
    label: 'Practice Diary',
    bigIcon: 'fa-regular fa-calendar-day',
    icon: 'fa-regular fa-calendar-day',
    tabIcon: 'fa-regular fa-calendar-day'
};

export const SM_CLINICIAN_DIARY = {
    loaded: 'SM_CLINICIAN_DIARYLoaded',
    id: 'SM_CLINICIAN_DIARY',
    label: 'Clinician Diary',
    bigIcon: 'fa-regular fa-calendar-circle-user',
    icon: 'fa-regular fa-calendar-circle-user',
    tabIcon: 'fa-regular fa-calendar-circle-user'
};

export const SM_CLINICIAN_WEEK_DIARY = {
    loaded: 'SM_CLINICIAN_WEEKLoaded',
    id: 'SM_CLINICIAN_WEEK',
    label: 'Clinician Week Diary',
    icon: 'fa clinicianWeekDiary48',
    tabIcon: 'clinicianWeekDiary16'
};

export const SM_PRACTICE_WEEK_DIARY = {
    loaded: 'SM_PRACTICE_WEEKLoaded',
    id: 'SM_PRACTICE_WEEK',
    label: 'Practice Week Diary',
    bigIcon: 'fa-regular fa-calendar-range',
    icon: 'fa-regular fa-calendar-range',
    tabIcon: 'fa-regular fa-calendar-range'
};

export const SM_templatesSystem = {
    loaded: 'SM_templatesSystemLoaded',
    id: 'SM_templatesSystem',
    label: '',
    icon: '',
    tabIcon: '',
    help: HELP_TEMPLATES_SYSTEM,
};

export const SM_templatesPatient = {
    loaded: 'SM_templatesPatientLoaded',
    id: 'SM_templatesPatient',
    label: '',
    icon: '',
    tabIcon: '',
    help: HELP_TEMPLATES_PATIENT,
};

// upload payment related

export const SM_UploadPayments = {
    loaded: 'SM_UploadPaymentsLoaded',
    id: 'SM_UploadPayments',
    label: 'Upload Payments',
    icon: ICON_UPLOAD,
    tabIcon: ICON_UPLOAD,
};

// template note related

export const SM_TEMPLATE_NOTES = {
    loaded: 'SM_TEMPLATE_NOTESLoaded',
    id: 'SM_TEMPLATE_NOTES',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_TEMPLATE_NOTES_Notes = {
    loaded: 'SM_TEMPLATE_NOTES_NotesLoaded',
    id: 'SM_TEMPLATE_NOTES_Notes',
    label: 'Notes',
    icon: 'fa accountsEdit16',
    tabIcon: 'fa accountsEdit16',
};

export const SM_TEMPLATE_NOTES_Favs = {
    loaded: 'SM_TEMPLATE_NOTES_FavsLoaded',
    id: 'SM_TEMPLATE_NOTES_Favs',
    label: 'Favourites',
    icon: 'fa-solid fa-stars',
    tabIcon: 'fa-solid fa-stars',
};

// appointment related

export const SM_APPOINTMENT = {
    loaded: 'SM_APPOINTMENT_LOADED',
    id: 'SM_APPOINTMENT',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_ONLINE = {
    loaded: 'SM_ONLINE_LOADED',
    id: 'SM_ONLINE',
    label: 'Online',
    icon: 'fas fa-globe',
    tabIcon: 'fas fa-globe',
};

export const SM_ONLINE_REGISTRATION = {
    loaded: 'SM_ONLINE_REGISTRATION_LOADED',
    id: 'SM_ONLINE_REGISTRATION',
    label: 'Online Registrations',
    icon: 'fas fa-clipboard-list',
    tabIcon: 'fas fa-clipboard-list',
};

export const SM_ONLINE_REGISTRATION_PDF = {
    loaded: 'SM_ONLINE_REGISTRATION_PDF_LOADED',
    id: 'SM_ONLINE_REGISTRATION_PDF',
    label: 'Online Registrations PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf'
};

export const SM_VIDEO_CONFERENCES = {
    loaded: 'SM_VIDEO_CONFERENCES_LOADED',
    id: 'SM_VIDEO_CONFERENCES',
    label: 'Video Appointments',
    icon: 'fas fa-video',
    tabIcon: 'fas fa-video'
};

export const SM_ONLINE_BOOKING = {
    loaded: 'SM_VIDEO_CONFERENCES_LOADED',
    id: 'SM_VIDEO_CONFERENCES',
    label: 'Online Bookings',
    icon: 'fas fa-calendar-plus',
    tabIcon: 'fas fa-calendar-plus'
};

// Compliance related

export const SM_COMPLIANCE = {
    loaded: 'SM_COMPLIANCE_LOADED',
    id: 'SM_COMPLIANCE',
    label: 'Compliance',
    icon: 'far fa-thumbs-up',
    tabIcon: 'far fa-thumbs-up',
};

export const SM_COMPLIANCE_STAFF_CONDITION = {
    loaded: 'SM_COMPLIANCE_STAFF_CONDITION_LOADED',
    id: 'SM_COMPLIANCE_STAFF_CONDITION',
    label: 'Staff Welfare',
    icon: 'fa complianceUsers24',
    tabIcon: 'fa complianceUsers16'
};

export const SM_COMPLIANCE_PATIENT_FORMS = {
    loaded: 'SM_COMPLIANCE_PATIENTS_FORMS_LOADED',
    id: 'SM_COMPLIANCE_PATIENTS_FORMS',
    label: 'Patient Forms',
    icon: 'fa compliancePatient24',
    tabIcon: 'fa compliancePatient16'
};

export const SM_COMPLIANCE_PATIENT_QUESTIONNAIRE = {
    loaded: 'SM_COMPLIANCE_PATIENTS_QUESTIONNAIRE_LOADED',
    id: 'SM_COMPLIANCE_PATIENTS_QUESTIONNAIRE',
    label: 'Complete Questionnaire',
    icon: 'far fa-thumbs-up',
    tabIcon: 'far fa-thumbs-up',
};

export const SM_COMPLIANCE_QUESTIONNAIRE = {
    loaded: 'SM_COMPLIANCE_PATIENTS_QUESTIONNAIRE_LOADED',
    id: 'SM_COMPLIANCE_PATIENTS_QUESTIONNAIRE',
    label: 'Questionnaire',
    icon: 'fa compliancePatient24',
    tabIcon: 'fa compliancePatient16'
};

// Payment plan related

export const SM_PAYMENTS_PLANS = {
    loaded: 'SM_PAYMENTS_PLANSLoaded',
    id: 'SM_PAYMENTS_PLANS',
};

export const SM_PaymentPlaning = {
    loaded: 'SM_PaymentPlaningLoaded',
    id: 'SM_PaymentPlaning',
    label: 'Payment Planing',
    icon: 'fas fa-list-ol',
    tabIcon: 'fas fa-list-ol'
};

export const SM_PAYMENT_PLAN_ISSUE_INVOICES = {
    loaded: 'SM_PAYMENT_PLAN_ISSUE_INVOICESLoaded',
    id: 'SM_PAYMENT_PLAN_ISSUE_INVOICES',
    label: 'Issue Plan Invoices',
    icon: 'fas fa-share-square',
    tabIcon: 'fas fa-share-square',
};

// treatment plan related

export const SM_TreatmentPlaning = {
    loaded: 'SM_TreatmentPlaningLoaded',
    id: 'SM_TreatmentPlaning',
    label: '',
    icon: 'fas fa-clipboard-list',
    tabIcon: 'fas fa-clipboard-list',
};

export const SM_TreatmentPlanHistory = {
    loaded: 'SM_TreatmentPlanHistoryLoaded',
    id: 'SM_TreatmentPlanHistory',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_TreatmentPlan = {
    loaded: 'SM_TreatmentPlanLoaded',
    id: 'SM_TreatmentPlan',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_APPTreatmentPlan = {
    loaded: 'SM_APPTreatmentPlanLoaded',
    id: 'SM_APPTreatmentPlan',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PAYMENT_PLAN = {
    loaded: 'SM_PAYMENT_PLAN_LOADED',
    id: 'SM_PAYMENT_PLAN',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PAYMENT_PLAN_INSTALLMENTS = {
    loaded: 'SM_PAYMENT_PLAN_INSTALLMENTS_LOADED',
    id: 'SM_PAYMENT_PLAN_INSTALLMENTS',
    label: '',
    icon: '',
    tabIcon: ''
};

// user related

export const SM_USER_SEARCH = {
    loaded: 'SM_USER_SEARCH_LOADED',
    id: 'SM_USER_SEARCH',
    label: 'Find Users',
    bigIcon: 'fas fa-search',
    icon: 'fas fa-search',
    tabIcon: 'fas fa-search'
};

export const SM_USER_DATA = {
    loaded: 'SM_USER_DATA_LOADED',
    id: 'SM_USER_DATA',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

export const SM_ADD_USER = {
    loaded: 'SM_USER_ADD_LOADED',
    id: 'SM_ADD_USER',
    label: 'Add User',
    bigIcon: 'fas fa-user-plus',
    icon: 'fas fa-user-plus',
    tabIcon: 'fas fa-user-plus',
};

export const SM_USER_CAPABILITIES = {
    loaded: 'SM_USER_CAPABILITIES_LOADED',
    id: 'SM_USER_CAPABILITIES',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

export const SM_ACCOUNT_GROUPS = {
    loaded: 'SM_ACCOUNT_GROUPS_LOADED',
    id: 'ACCOUNT_GROUPS',
    label: 'Account Groups',
    bigIcon: 'fas fa-folder-open',
    icon: 'fas fa-folder-open',
    tabIcon: 'fas fa-folder-open'
};

export const SM_TRAINING = {
    loaded: 'SM_TRAINING_LOADED',
    id: 'SM_TRAINING',
    label: 'Book Training',
    bigIcon: 'fa fa-graduation-cap',
    icon: 'fa fa-graduation-cap',
    tabIcon: 'fa fa-graduation-cap'
};

// referrer related

export const SM_REFERRER_SEARCH = {
    loaded: 'SM_REFERRER_SEARCH_LOADED',
    id: 'SM_REFERRER_SEARCH',
    label: 'Find Referrers',
    bigIcon: 'fas fa-search',
    icon: 'fas fa-search',
    tabIcon: 'fas fa-search'
};

export const SM_REFERRER_DATA = {
    loaded: 'SM_REFERRER_SEARCH_DATA',
    id: 'SM_REFERRER_DATA',
    label: 'Open Patient Summary',
    icon: 'fas fa-people-arrows',
    tabIcon: 'fas fa-people-arrows',
};

export const SM_REFERRER_PATIENT_DATA = {
    loaded: 'SM_REFERRER_PATIENT_DATA_LOADED',
    id: 'SM_REFERRER_PATIENT_DATA',
    label: 'Open Patient Summary',
    icon: 'fas fa-people-arrows',
    tabIcon: 'fas fa-people-arrows',
};

export const SM_REFERRER_ADD = {
    id: 'SM_REFERRER_ADD',
    label: 'Add Referrer',
    icon: 'fas fa-user-plus',
    tabIcon: 'fas fa-user-plus'
};

export const SM_REFERRER_DETAILS = {
    id: 'SM_REFERRER_DETAILS',
    label: 'Referrer Details',
    icon: ICON_LOCATION_ARROW,
    tabIcon: ICON_LOCATION_ARROW
};

// laboratory related

export const SM_LABORATORY_SEARCH = {
    loaded: 'SM_LABORATORY_SEARCH_LOADED',
    id: 'SM_LABORATORY_SEARCH',
    label: 'Find Laboratories',
    bigIcon: 'fas fa-search',
    icon: 'fas fa-search',
    tabIcon: 'fas fa-search'
};

export const SM_LABORATORY_DATA = {
    loaded: 'SM_LABORATORY_DATA_LOADED',
    id: 'SM_LABORATORY_DATA',
    label: 'Open Laboratory Summary',
    icon: 'fas fa-wrench',
    tabIcon: 'fas fa-wrench',
};

export const SM_LABORATORY_DETAILS_DATA = {
    loaded: 'SM_LABORATORY_DETAILS_DATA_LOADED',
    id: 'SM_LABORATORY_DETAILS_DATA',
    label: 'Open Laboratory Summary',
    icon: 'fas fa-wrench',
    tabIcon: 'fas fa-wrench',
};

export const SM_LABORATORY_PATIENT_DATA = {
    loaded: 'SM_LABORATORY_PATIENT_DATA_LOADED',
    id: 'SM_LABORATORY_PATIENT_DATA',
    label: 'Open Patient Summary',
    icon: 'fas fa-wrench',
    tabIcon: 'fas fa-wrench',
};

export const SM_LABORATORY_ADD = {
    id: 'SM_LABORATORY_ADD',
    label: 'Add Laboratory',
    icon: 'fas fa-wrench',
    tabIcon: 'fas fa-wrench'
};

export const SM_WORK_REQUESTED_REPORT = {
    id: 'SM_WORK_REQUESTED_REPORT',
    label: 'Lab Work Raised',
    icon: ICON_LAB,
    tabIcon: ICON_LAB
};

export const SM_WORK_REQUESTED_REPORT_PDF = {
    loaded: 'SM_WORK_REQUESTED_REPORT_PDF_LOADED',
    id: 'SM_WORK_REQUESTED_REPORT_PDF',
    label: 'Lab Work Raised Report PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf'
};

export const SM_WORK_REQUIRED_REPORT = {
    id: 'SM_WORK_REQUIRED_REPORT',
    label: 'Lab Work Due',
    icon: ICON_PICKUP,
    tabIcon: ICON_PICKUP
};

export const SM_WORK_REQUIRED_REPORT_PDF = {
    loaded: 'SM_WORK_REQUIRED_REPORT_PDF_LOADED',
    id: 'SM_WORK_REQUIRED_REPORT_PDF',
    label: 'Lab Work Due Report PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf'
};

export const SM_WORK_RECEIVED_REPORT = {
    id: 'SM_WORK_RECEIVED_REPORT',
    label: 'Lab Work Received',
    icon: ICON_GIFT,
    tabIcon: ICON_GIFT
};

export const SM_WORK_RECEIVED_REPORT_PDF = {
    loaded: 'SM_WORK_RECEIVED_REPORT_PDF_LOADED',
    id: 'SM_WORK_RECEIVED_REPORT_PDF',
    label: 'Lab Work Received Report PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf'
};

export const SM_PATIENT_FOR_APPS = {
    loaded: 'SM_PATIENT_FOR_APPS_LOADED',
    id: 'SM_PATIENT_FOR_APPS',
    label: '',
    icon: '',
    tabIcon: '',
};

// patient related

export const SM_PATIENT_MAIL_SHOT = {
    loaded: 'SM_PATIENT_MAIL_SHOT_Loaded',
    id: 'SM_PATIENT_MAIL_SHOT',
    label: 'Patient Mail Shot',
    icon: 'far fa-paper-plane',
    tabIcon: 'far fa-paper-plane'
};

export const SM_EMAIL_MAIL_SHOTS = {
    loaded: 'SM_EMAIL_MAIL_SHOTS_Loaded',
    id: 'SM_EMAIL_MAIL_SHOTS',
    label: 'Email Mail Shots',
    icon: 'far fa-email',
    tabIcon: 'far fa-email'
};

export const SM_EMAIL_MAIL_SHOT = {
    loaded: 'SM_EMAIL_MAIL_SHOT_Loaded',
    id: 'SM_EMAIL_MAIL_SHOT',
    label: 'Email Mail Shot',
    icon: 'far fa-email',
    tabIcon: 'far fa-email'
};

export const SM_EMAIL_MAIL_SHOT_SEND = {
    id: 'SM_EMAIL_MAIL_SHOT_SEND',
    label: 'Send Email Mail Shot',
    icon: 'far fa-share-square',
    tabIcon: 'far fa-share-square'
};

export const SM_EMAIL_MAIL_SHOT_TEST_SEND = {
    id: 'SM_EMAIL_MAIL_SHOT_TEST_SEND',
    label: 'Send Test Email Mail Shot',
    icon: 'fas fa-question',
    tabIcon: 'fas fa-question'
};

export const SM_EMAIL_RECIPIENTS = {
    loaded: 'SM_EMAIL_RECIPIENTS_Loaded',
    id: 'SM_EMAIL_MAIL_RECIPIENTS',
};

export const SM_EMAIL_MAIL_SHOT_HISTORY = {
    loaded: 'SM_EMAIL_MAIL_SHOT_HISTORY_Loaded',
    id: 'SM_EMAIL_MAIL_SHOT_HISTORY',
    label: 'Email Mail Shot History',
    icon: 'far fa-email',
    tabIcon: 'far fa-email'
};

export const SM_SMS_MAIL_SHOTS = {
    loaded: 'SM_SMS_MAIL_SHOTS_Loaded',
    id: 'SM_SMS_MAIL_SHOTS',
    label: 'SMS Mail Shots',
    icon: 'far fa-sms',
    tabIcon: 'far fa-sms'
};

export const SM_SMS_MAIL_SHOT = {
    loaded: 'SM_SMS_MAIL_SHOT_Loaded',
    id: 'SM_SMS_MAIL_SHOT',
    label: 'SMS Mail Shot',
    icon: 'far fa-sms',
    tabIcon: 'far fa-sms'
};

export const SM_SMS_RECIPIENTS = {
    loaded: 'SM_SMS_RECIPIENTS_Loaded',
    id: 'SM_SMS_MAIL_RECIPIENTS',
};

export const SM_SMS_MAIL_SHOT_HISTORY = {
    loaded: 'SM_SMS_MAIL_SHOT_HISTORY_Loaded',
    id: 'SM_SMS_MAIL_SHOT_HISTORY',
    label: 'SMS Mail Shot History',
    icon: 'far fa-sms',
    tabIcon: 'far fa-sms'
};

export const SM_SMS_MAIL_SHOT_SEND = {
    id: 'SM_SMS_MAIL_SHOT_SEND',
    label: 'Send SMS Mail Shot',
    icon: 'far fa-share-square',
    tabIcon: 'far fa-share-square'
};

export const SM_SMS_MAIL_SHOT_TEST_SEND = {
    id: 'SM_SMS_MAIL_SHOT_TEST_SEND',
    label: 'Send Test SMS Mail Shot',
    icon: 'fas fa-question',
    tabIcon: 'fas fa-question'
};

export const SM_PATIENT_SEARCH = {
    loaded: 'SM_PATIENT_SEARCH_LOADED',
    id: 'SM_PATIENT_SEARCH',
    label: 'Find Patients',
    bigIcon: 'fas fa-search',
    icon: 'fas fa-search',
    tabIcon: 'fas fa-search'
};

export const SM_PATIENT_DATA = {
    loaded: 'SM_PATIENT_DATA_LOADED',
    id: 'SM_PATIENT_DATA',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

export const SM_PATIENT_NOK_DATA = {
    loaded: 'SM_PATIENT_NOK_DATA_LOADED',
    id: 'SM_PATIENT_NOK_DATA',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

export const SM_PATIENT_POPUPS = {
    loaded: 'SM_PATIENT_POPUPS_LOADED',
    id: 'SM_PATIENT_POPUPS',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

export const SM_APPOINTMENT_POPUPS = {
    loaded: 'SM_APPOINTMENT_POPUPS_LOADED',
    id: 'SM_APPOINTMENT_POPUPS',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

export const SM_PATIENT_XRAY_RECORDS = {
    loaded: 'SM_PATIENT_XRAY_RECORDS_LOADED',
    id: 'SM_PATIENT_XRAY_RECORDS',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

export const SM_PATIENT_SEDATION_RECORDS = {
    loaded: 'SM_PATIENT_SEDATION_RECORDS_LOADED',
    id: 'SM_PATIENT_SEDATION_RECORDS',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

export const SM_PATIENT_PORTAL_DATA = {
    loaded: 'SM_PATIENT_PORTAL_DATA_LOADED',
    id: 'SM_PATIENT_PORTAL_DATA',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

export const SM_patientDataShort = {
    loaded: 'SM_patientDataShortLoaded',
    id: 'SM_patientDataShort',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_APPOINTMENT_NOTES = {
    loaded: 'SM_PATIENT_APPOINTMENT_NOTES_LOADED',
    id: 'SM_PATIENT_APPOINTMENT_NOTES',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_NOTES = {
    loaded: 'SM_PATIENT_NOTES_LOADED',
    id: 'SM_PATIENT_NOTES',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_ADD_PATIENT = {
    loaded: 'templatesSystemLoaded',
    id: 'SM_ADD_PATIENT',
    label: 'Add Patient',
    bigIcon: 'fas fa-user-plus',
    icon: 'fas fa-user-plus',
    tabIcon: 'fas fa-user-plus'
};

export const SM_PATIENT_SALES = {
    loaded: 'SM_PATIENT_SALESLoaded',
    id: 'SM_PATIENT_SALES',
    label: 'Patient Sales',
    icon: 'fa patientSales24',
    tabIcon: 'patientSales16'
};

export const SM_CONTACT_LISTS = {
    loaded: 'SM_CONTACT_LISTSLoaded',
    id: 'SM_CONTACT_LISTS',
    label: 'Contact Lists',
    icon: 'fa patients24',
    tabIcon: 'patients16'
};

export const SM_PATIENT_APPOINTMENT_SUMMARY = {
    loaded: 'SM_PATIENT_APPOINTMENT_SUMMARY_LOADED',
    id: 'SM_PATIENT_APPOINTMENT_SUMMARY',
    label: 'Appointment Summary',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_APPOINTMENT_SUMMARY_PDF = {
    loaded: 'SM_PATIENT_APPOINTMENT_SUMMARY_PDF_LOADED',
    id: 'SM_PATIENT_APPOINTMENT_SUMMARY_PDF',
    label: 'Appointment Summary PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'AppointmentSummary',
};

export const SM_PATIENT_HISTORY_SUMMARY = {
    loaded: 'SM_PATIENT_APPOINTMENT_HISTORY_LOADED',
    id: 'SM_PATIENT_APPOINTMENT_HISTORY',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_APPOINTMENT_HISTORY_PDF = {
    loaded: 'SM_PATIENT_APPOINTMENT_HISTORY_PDF_LOADED',
    id: 'SM_PATIENT_APPOINTMENT_HISTORY_PDF',
    label: 'Appointment History PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'AppointmentHistory',
};

export const SM_PATIENT_NOTE_HISTORY_PDF = {
    loaded: 'SM_PATIENT_NOTE_HISTORY_PDF_LOADED',
    id: 'SM_PATIENT_NOTE_HISTORY_PDF',
    label: 'Note History PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'NoteHistory',
};

export const SM_PATIENT_TREATMENT_HISTORY = {
    loaded: 'SM_PATIENT_TREATMENT_HISTORY_LOADED',
    id: 'SM_TREATMENT_HISTORY',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_APPOINTMENT_TREATMENT_HISTORY = {
    loaded: 'SM_PATIENT_APPOINTMENT_TREATMENT_HISTORY_LOADED',
    id: 'SM_TREATMENT_APPOINTMENT_HISTORY',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_LAB_HISTORY = {
    loaded: 'SM_PATIENT_LAB_HISTORY_LOADED',
    id: 'SM_LAB_HISTORY',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW = {
    loaded: 'SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW_LOADED',
    id: 'SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW',
    label: 'Account History Overview',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_ACCOUNT_HISTORY = {
    loaded: 'SM_PATIENT_ACCOUNT_HISTORY_LOADED',
    id: 'SM_PATIENT_ACCOUNT_HISTORY',
    label: 'Account History',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_ACCOUNT_HISTORY_PDF = {
    loaded: 'SM_PATIENT_ACCOUNT_HISTORY_PDF_LOADED',
    id: 'SM_PATIENT_ACCOUNT_HISTORY_PDF',
    label: 'Account History PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'AccountHistory'
};

export const SM_PATIENT_ACCOUNT_ITEM_PDF = {
    loaded: 'SM_PATIENT_ACCOUNT_ITEM_PDF_LOADED',
    id: 'SM_PATIENT_ACCOUNT_ITEM_PDF',
    label: 'Account Item PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'AccountItem'
};

export const SM_PAYMENT_PLAN_HISTORY = {
    loaded: 'SM_PAYMENT_PLAN_HISTORY_LOADED',
    id: 'SM_PAYMENT_PLAN_HISTORY',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_DOCUMENTS = {
    loaded: 'SM_PATIENT_DOCUMENTS_LOADED',
    id: 'SM_PATIENT_DOCUMENTS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_DOCUMENT_PDF = {
    loaded: 'SM_PATIENT_DOCUMENT_PDF_LOADED',
    id: 'SM_PATIENT_DOCUMENT_PDF',
    label: 'Patient PDF Document',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'PatientDocument',
};

export const SM_PATIENT_IMAGES = {
    loaded: 'SM_PATIENT_IMAGES_LOADED',
    id: 'SM_PATIENT_IMAGES',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_BILLING_GROUP = {
    loaded: 'SM_PATIENT_BILLING_GROUP_LOADED',
    id: 'SM_PATIENT_BILLING_GROUP',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_RECALLS = {
    loaded: 'SM_PATIENT_RECALLS_LOADED',
    id: 'SM_PATIENT_RECALLS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_PRSS = {
    loaded: 'SM_PATIENT_PRSS_LOADED',
    id: 'SM_PATIENT_PRSS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_PASS = {
    loaded: 'SM_PATIENT_PASS_LOADED',
    id: 'SM_PATIENT_PASS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_MHFS = {
    loaded: 'SM_PATIENT_MHFS_LOADED',
    id: 'SM_PATIENT_MHFS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_MHF = {
    loaded: 'SM_PATIENT_MHF_LOADED',
    id: 'SM_PATIENT_MHF',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_QUES = {
    loaded: 'SM_PATIENT_QUES_LOADED',
    id: 'SM_PATIENT_QUES',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_QUES_QUERY = {
    loaded: 'SM_PATIENT_QUES_QUERY_LOADED',
    id: 'SM_PATIENT_QUES_QUERY',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_MCONS = {
    loaded: 'SM_PATIENT_MCONS_LOADED',
    id: 'SM_PATIENT_MCONS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_REMINDERS = {
    loaded: 'SM_PATIENT_REMINDERS_LOADED',
    id: 'SM_PATIENT_REMINDERS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_ASSESSMENTS = {
    loaded: 'SM_PATIENT_ASSESSMENTS_LOADED',
    id: 'SM_PATIENT_ASSESSMENTS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_ASSESSMENTS_ORTHO = {
    loaded: 'SM_PATIENT_ASSESSMENTS_ORTHO_LOADED',
    id: 'SM_PATIENT_ASSESSMENTS_ORTHO',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_APPOINTMENT_ASSESSMENTS = {
    loaded: 'SM_PATIENT_ASSESSMENTS_APPOINTMENT_LOADED',
    id: 'SM_PATIENT_APPOINTMENT_ASSESSMENTS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO = {
    loaded: 'SM_PATIENT_ASSESSMENTS_APPOINTMENT_ORTHO_LOADED',
    id: 'SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_patientChart = {
    loaded: 'SM_patientChartLoaded',
    id: 'SM_patientChart',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PERIO_CHART = {
    loaded: 'SM_PERIO_CHART_Loaded',
    id: 'SM_PERIO_CHART',
    label: 'Perio CHart',
    icon: '',
    tabIcon: ''
};

export const SM_APPOINTMENT_PERIO_CHART = {
    loaded: 'SM_APPOINTMENT_PERIO_CHART_Loaded',
    id: 'SM_APPOINTMENT_PERIO_CHART',
    label: 'Perio CHart',
    icon: '',
    tabIcon: ''
};

export const SM_NHSRegistration = {
    loaded: 'SM_NHSRegistrationLoaded',
    id: 'SM_NHSRegistration',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_NHSClaimHistory = {
    loaded: 'SM_NHSClaimHistoryLoaded',
    id: 'SM_NHSClaimHistory',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_ACCESS = {
    loaded: 'SM_PATIENT_ACCESS_Loaded',
    id: 'SM_PATIENT_ACCESS',
    label: 'Patient Access',
    icon: 'fas fa-user-check',
    tabIcon: 'fas fa-user-check'
};

export const SM_XRAYS = {
    label: 'XRay Reports',
    icon: 'fas fa-x-ray',
    tabIcon: 'fas fa-x-ray',
};

export const SM_SEDATION = {
    label: 'Sedation Report',
    icon: 'fas fa-male',
    tabIcon: 'fas fa-male',
};

export const SM_QUESTIONNAIRES = {
    label: 'Questionnaires',
    icon: 'fas fa-clipboard-list',
    tabIcon: 'fas fa-clipboard-list',
};

export const SM_COMPLETED_QUESTIONNAIRES = {
    loaded: 'SM_COMPLETED_QUESTIONNAIRES_Loaded',
    id: 'SM_COMPLETED_QUESTIONNAIRES',
    menu: 'Completed(s)',
    label: 'Completed Questionnaire(s)',
    icon: 'far fa-check-square',
    tabIcon: 'far fa-check-square',
};

export const SM_PRECOMPLETE_QUESTIONNAIRES = {
    loaded: 'SM_PRECOMPLETE_QUESTIONNAIRES_Loaded',
    id: 'SM_PRECOMPLETE_QUESTIONNAIRES',
    menu: 'Pre-completed(s)',
    label: 'Pre-completed Questionnaire(s)',
    icon: 'fas fa-th-large',
    tabIcon: 'fas fa-th-large',
};

export const SM_COMPLETE_QUESTIONNAIRE = {
    id: 'SM_COMPLETE_QUESTIONNAIRE',
    menu: 'Complete Questionnaire',
    icon: 'far fa-check-square',
    tabIcon: 'far fa-check-square',
};

export const SM_INCOMPLETE_QUESTIONNAIRES = {
    loaded: 'SM_INCOMPLETE_QUESTIONNAIRES_Loaded',
    id: 'SM_INCOMPLETE_QUESTIONNAIRES',
    menu: 'Incomplete(s)',
    label: 'Incomplete Questionnaire(s)',
    icon: 'far fa-square',
    tabIcon: 'far fa-square',
};

export const SM_XRAY_SUMMARY = {
    loaded: 'SM_XRAY_SUMMARY_Loaded',
    id: 'SM_XRAY_SUMMARY',
    menu: 'XRay Summary',
    label: 'XRay Weekly Summary',
    icon: 'fas fa-chart-pie',
    tabIcon: 'fas fa-chart-pie',
};

export const SM_XRAY_SUMMARY_PDF = {
    loaded: 'SM_XRAY_SUMMARY_PDF_Loaded',
    id: 'SM_XRAY_SUMMARY_PDF',
    menu: 'XRay Summary PDF',
    label: 'XRay Weekly Summary PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'XRaySummary',
};

export const SM_XRAY_REPORT = {
    loaded: 'SM_XRAY_REPORT_Loaded',
    id: 'SM_XRAY_REPORT',
    menu: 'XRay Report',
    label: 'XRay Weekly Report',
    icon: 'fas fa-chart-area',
    tabIcon: 'fas fa-chart-area'
};

export const SM_XRAY_REPORT_PDF = {
    loaded: 'SM_XRAY_REPORT_PDF_Loaded',
    id: 'SM_XRAY_REPORT_PDF',
    menu: 'XRay Report PDF',
    label: 'XRay Weekly Report PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'xrayWeeklyReport',
};

export const SM_SEDATION_SUMMARY = {
    loaded: 'SM_SEDATION_SUMMARY_Loaded',
    id: 'SM_SEDATION_SUMMARY',
    menu: 'Sedation Summary',
    label: 'Sedation Weekly Summary',
    icon: 'fas fa-chart-pie',
    tabIcon: 'fas fa-chart-pie',
};

export const SM_SEDATION_SUMMARY_PDF = {
    loaded: 'SM_SEDATION_SUMMARY_PDF_Loaded',
    id: 'SM_SEDATION_SUMMARY_PDF',
    menu: 'Sedation Summary PDF',
    label: 'Sedation Weekly Summary PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'sedationSummary',
};

export const SM_SEDATION_REPORT = {
    loaded: 'SM_SEDATION_REPORT_Loaded',
    id: 'SM_SEDATION_REPORT',
    menu: 'Sedation Report',
    label: 'Sedation Weekly Report',
    icon: 'fas fa-chart-area',
    tabIcon: 'fas fa-chart-area'
};

export const SM_SEDATION_REPORT_PDF = {
    loaded: 'SM_SEDATION_REPORT_PDF_Loaded',
    id: 'SM_SEDATION_REPORT_PDF',
    menu: 'Sedation Report PDF',
    label: 'Sedation Weekly Report PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'sedationWeeklyReport',
};

export const SM_ANALYTICS = {
    loaded: 'SM_ANALYTICS_Loaded',
    id: 'SM_ANALYTICS',
    label: 'Practice Analytics',
    icon: 'fas fa-chart-bar',
    tabIcon: 'fas fa-chart-bar'
};

// main login routes

export const SM_CLOUD_LOGIN = {
    id: 'SM_CLOUD_LOGIN',
    route: "/",
};

export const SM_CLOUD_LOGGED_IN = {
    loaded: 'SM_CLOUD_LOGGED_IN_Loaded',
    id: 'SM_CLOUD_LOGGED_IN',
    route: "/main/practiceControlCenter",
    label: 'Cloud Logged In',
    icon: 'tablet24',
    tabIcon: 'tablet16'
};

export const SM_FORCE_LOGOUT = {
    id: 'SM_FORCE_LOGOUT',
};

export const SM_CLOUD_ADMIN_LOGIN = {
    id: 'SM_CLOUD_ADMIN_LOGGED_OUT',
    route: "/__ADMIN__",
};
export const SM_CLOUD_ADMIN_LOGGED_IN = {
    loaded: 'SM_CLOUD_ADMIN_LOGGED_IN_Loaded',
    id: 'SM_CLOUD_ADMIN_LOGGED_IN',
    route: "/main/adminControlCenter",
    label: 'Cloud Admin Logged In',
    icon: 'tablet24',
    tabIcon: 'tablet16'
};

export const SM_CLOUD_PORTAL_LOGIN = {
    id: 'SM_CLOUD_PORTAL_LOGIN',
    route: `/Client/:portalReference?`,
};

export const SM_CLOUD_PORTAL_LOGGED_IN = {
    loaded: 'SM_CLOUD_PORTAL_LOGGED_IN_Loaded',
    id: 'SM_CLOUD_PORTAL_LOGGED_IN',
    route: "/main/clientControlCenter",
    label: 'Portal Logged In',
    icon: 'tablet24',
    tabIcon: 'tablet16'
};

export const SM_CLOUD_LAB_LOGIN = {
    id: 'SM_CLOUD_LAB_LOGIN',
    route: `/Laboratory`,
};

export const SM_CLOUD_LAB_LOGGED_IN = {
    loaded: 'SM_CLOUD_LAB_LOGGED_IN_Loaded',
    id: 'SM_CLOUD_LAB_LOGGED_IN',
    route: "/main/labControlCenter",
    label: 'Laboratory Logged In',
    icon: 'tablet24',
    tabIcon: 'tablet16'
};

export const SM_CLOUD_PW_FORGOT = {
    loaded: 'SM_CLOUD_PW_FORGOT_Loaded',
    id: 'SM_CLOUD_PW_FORGOT',
    route: "/forgotCloud",
    label: 'Cloud Forgotten Password',
    icon: 'tablet24',
    tabIcon: 'tablet16'
};

// tablet related

export const SM_CLIENT_PW_FORGOT = {
    loaded: 'SM_CLIENT_PW_FORGOT_Loaded',
    id: 'SM_CLIENT_PW_FORGOT',
    route: "/forgotClient",
    label: 'Client Forgotten Password',
    icon: 'tablet24',
    tabIcon: 'tablet16'
};

export const SM_TABLET_LOGGED_IN = {
    loaded: 'SM_TABLET_LOGGED_IN_Loaded',
    id: 'SM_TABLET_LOGGED_IN',
    route: '/main/tablet',
    label: 'Tablet Logged In',
    icon: 'tablet24',
    tabIcon: 'tablet16'
};

export const SM_TABLET_REGISTRATIONS = {
    loaded: 'SM_TABLET_REGISTRATIONS_Loaded',
    id: 'SM_TABLET_REGISTRATIONS',
    label: 'Tablet Registrations',
    icon: 'fas fa-tablet-alt',
    tabIcon: 'fas fa-tablet-alt'
};

export const SM_TAB_CONCIEGE = {
    loaded: 'SM_TAB_CONCIEGE_LOADED',
    id: 'SM_TAB_CONCIEGE',
    route: '/main/conciege',
    exitRoute: '/main/conciegeExit',
    label: 'Patient Checkin',
    type: TAB_PatientConciege,
    detail: 'Added to checkin',
    icon: 'fal fa-house-return',
};

export const SM_TAB_PATIENT_DETAILS = {
    loaded: 'SM_TAB_PATIENT_DETAILS_LOADED',
    id: 'SM_TAB_PATIENT_DETAILS',
    route: '/main/patientDetails',
    exitRoute: '/main/patientDetailsExit',
    label: 'Patient Details',
    type: TAB_PatientDetailsUpdate,
    detail: 'Added to details list',
    icon: 'fas fa-tablet-alt',
};

export const SM_TAB_PATIENT_MED_CON = {
    loaded: 'SM_TAB_PATIENT_MED_CON_LOADED',
    id: 'SM_TAB_PATIENT_MED_CON',
    route: '/main/patientMedCons',
    exitRoute: '/main/patientMedConsExit',
    label: 'Medical History',
    type: TAB_MedicalHistoryUpdate,
    detail: 'Added to medical history list',
    icon: 'fas fa-tablet-alt',
};

export const SM_TAB_PATIENT_MED_CON_SIG = {
    label: 'Patient Signature',
};

export const SM_TAB_PATIENT_QUESTIONNAIRE = {
    loaded: 'SM_TAB_PATIENT_QUESTIONNAIRE_LOADED',
    id: 'SM_TAB_PATIENT_QUESTIONNAIRE',
    route: '/main/questionnaire',
    exitRoute: '/main/questionnaireExit',
    label: 'Questionnaire',
    type: TAB_Questionnaire,
    detail: 'Added to questionnaire list',
    icon: 'fas fa-tablet-alt',
    tabIcon: 'fas fa-tablet-alt',
};

export const SM_TAB_PATIENT_QUESTIONNAIRE_SIG = {
    label: 'Patient Signature',
};

export const SM_TAB_PATIENT_CONSENT = {
    loaded: 'SM_TAB_PATIENT_CONSENT_LOADED',
    id: 'SM_TAB_PATIENT_CONSENT',
    route: '/main/patientConsent',
    exitRoute: '/main/patientConsentExit',
    label: 'Consent Forms',
    type: TAB_PatientConsentForms,
    detail: 'Added to consent form list',
    icon: 'fas fa-tablet-alt',
};

export const SM_TAB_PATIENT_CONSENT_SIG = {
    label: 'Patient Signature',
};

export const SM_TAB_PATIENT_CONSENT_SIG_SAVE = {
    label: 'Save This Signature',
};

export const SM_TAB_PATIENT_CONSENTS = {
    label: 'Patient Consent',
};

export const SM_TAB_GDPR_CONSENT_SIG = {
    label: 'Patient Signature',
};

export const SM_TAB_GDPR_CONSENT = {
    loaded: 'SM_TAB_GDPR_CONSENT_LOADED',
    id: 'SM_TAB_GDPR_CONSENT',
    label: 'GDPR Consent Form',
};

export const SM_TAB_PATIENT_SIGN_TREATS = {
    loaded: 'SM_TAB_PATIENT_SIGN_TREATS_LOADED',
    id: 'SM_TAB_PATIENT_SIGN_TREATS',
    route: '/main/patientSignTreats',
    exitRoute: '/main/patientSignTreatsExit',
    label: 'Sign Treatment Plans',
    type: TAB_TreatmentPlanSigns,
    detail: 'Added to treatment plan signing list',
    icon: 'fas fa-tablet-alt',
};

export const SM_TAB_PATIENT_SIGN_PPS = {
    loaded: 'SM_TAB_PATIENT_SIGN_PPS_LOADED',
    id: 'SM_TAB_PATIENT_SIGN_PPS',
    route: '/main/patientSignPPS',
    exitRoute: '/main/patientSignPPSExit',
    label: 'Sign Payment Plans',
    type: TAB_PaymentPlanSigns,
    detail: 'Added to payment plan signing list',
    icon: 'fas fa-tablet-alt',
};

export const SM_TAB_FEEDBACK = {
    loaded: 'SM_TAB_FEEDBACK_LOADED',
    id: 'SM_TAB_FEEDBACK',
    route: '/main/patientFeedback',
    exitRoute: '/main/patientFeedbackExit',
    label: 'Feedback',
    type: null,
    detail: null,
    icon: 'fas fa-tablet-alt',
};

export const SM_TAB_LOGOUT = {
    loaded: 'SM_TAB_LOGOUT',
    id: 'SM_TAB_LOGOUT',
    route: '/',
    label: 'Logout',
    type: null,
    detail: null,
    icon: 'fas fa-tablet-alt',
};

export const SM_TAB_PATIENT_DETAILS_SIG = {
    label: 'Patient Signature',
};

export const SM_PLAN_PROVIDERS = {
    loaded: 'SM_PLAN_PROVIDERS_Loaded',
    id: 'SM_PLAN_PROVIDERS',
    label: '',
    icon: '',
    tabIcon: ''
};

// Work Received Report

export const SM_WORK_RECEIVED_RECEIVED = {
    loaded: 'SM_WORK_RECEIVED_RECEIVEDLoaded',
    id: 'SM_WORK_RECEIVED_RECEIVED',
    label: 'Received',
    header: 'Work Received',
    icon: ICON_GIFT,
    tabIcon: ICON_GIFT
};

export const SM_WORK_RECEIVED_FOR_APPROVAL = {
    loaded: 'SM_WORK_RECEIVED_FOR_APPROVALLoaded',
    id: 'SM_WORK_RECEIVED_FOR_APPROVAL',
    label: 'Approval Required',
    header: 'For Approval',
    icon: ICON_CERTIFICATE,
    tabIcon: ICON_CERTIFICATE
};

// Payment Plans Report

export const SM_PAYMENT_PLANS = {
    id: 'SM_PAYMENT_PLANS',
};

export const SM_PAYMENT_PLANS_ACTIVE = {
    loaded: 'SM_PAYMENT_PLANS_ACTIVELoaded',
    id: 'SM_PAYMENT_PLANS_ACTIVE',
    label: 'Active',
    header: 'Active Payment Plans',
    icon: 'fa invoice24',
    tabIcon: 'fa invoice16'
};

export const SM_PAYMENT_PLANS_FINISHED = {
    loaded: 'SM_PAYMENT_PLANS_FINISHEDLoaded',
    id: 'SM_PAYMENT_PLANS_FINISHED',
    label: 'Finished',
    header: 'Finished Payment Plans',
    icon: 'fa invoice24',
    tabIcon: 'fa invoice16'
};

export const SM_PAYMENT_PLANS_SUSPENDED = {
    loaded: 'SM_PAYMENT_PLANS_SUSPENDEDLoaded',
    id: 'SM_PAYMENT_PLANS_SUSPENDED',
    label: 'Suspended',
    header: 'Suspended Payment Plans',
    icon: 'fa invoice24',
    tabIcon: 'fa invoice16'
};

export const SM_PAYMENT_PLANS_WRITTEN_OFF = {
    loaded: 'SM_PAYMENT_PLANS_WRITTEN_OFFLoaded',
    id: 'SM_PAYMENT_PLANS_WRITTEN_OFF',
    label: 'Written Off',
    header: 'Written Off Payment Plans',
    icon: 'fa invoice24',
    tabIcon: 'fa invoice16'
};

// Overdue Accounts Report

export const SM_OVER_DUE_ACCOUNTS = {
    loaded: 'SM_OVER_DUE_ACCOUNTSLoaded',
    id: 'SM_OVER_DUE_ACCOUNTS',
};

export const SM_OVER_DUE_30 = {
    loaded: 'SM_OVER_DUE_30Loaded',
    id: 'SM_OVER_DUE_30',
    label: 'Upto 30 Days',
    header: 'Invoices Due : Upto 30 Days',
    icon: 'fas fa-calendar-week',
    tabIcon: 'fas fa-calendar-week'
};

export const SM_OVER_DUE_3160 = {
    loaded: 'SM_OVER_DUE_3160Loaded',
    id: 'SM_OVER_DUE_3160',
    label: '31 to 60 Days',
    header: 'Overdue Invoices : 31 to 60 Days',
    icon: 'fas fa-calendar-week',
    tabIcon: 'fas fa-calendar-week'
};

export const SM_OVER_DUE_6190 = {
    loaded: 'SM_OVER_DUE_6190Loaded',
    id: 'SM_OVER_DUE_6190',
    label: '61 to 90 Days',
    header: 'Overdue Invoices : 61 to 90 Days',
    icon: 'fas fa-calendar-week',
    tabIcon: 'fas fa-calendar-week'
};

export const SM_OVER_DUE_91 = {
    loaded: 'SM_OVER_DUE_91Loaded',
    id: 'SM_OVER_DUE_91',
    label: 'Greater than 90 days',
    header: 'Overdue Invoices : Greater than 90 days',
    icon: 'fas fa-calendar-week',
    tabIcon: 'fas fa-calendar-week'
};

export const SM_OVER_DUE_ALL = {
    loaded: 'SM_OVER_DUE_ALLLoaded',
    id: 'SM_OVER_DUE_ALL',
    label: 'All Overdue',
    header: 'Overdue Invoices : All Overdue',
    icon: 'fas fa-calendar-week',
    tabIcon: 'fas fa-calendar-week'
};

// Preferences

export const SM_PREFERENCES = {
    loaded: 'SM_PREFERENCESLoaded',
    id: 'SM_PREFERENCES',
    label: 'Preferences',
    icon: 'fas fa-cog',
    tabIcon: 'fas fa-cog'
};

export const SM_PREFERENCES_ACS = {
    loaded: 'SM_PREFERENCES_ACSLoaded',
    id: 'SM_PREFERENCES_ACS',
    label: 'Accounting',
    icon: 'fa accountsEdit16',
    tabIcon: 'fa accountsEdit16',
};

export const SM_PREFERENCES_CRT = {
    loaded: 'SM_PREFERENCES_CRTLoaded',
    id: 'SM_PREFERENCES_CRT',
    label: 'Charting Systems',
    icon: 'fa preferences16',
    tabIcon: 'fa preferences16',
};

export const SM_PREFERENCES_DIS = {
    loaded: 'SM_PREFERENCES_DISLoaded',
    id: 'SM_PREFERENCES_DIS',
    label: 'Diary Settings',
    icon: 'fa practiceDiary16',
    tabIcon: 'fa practiceDiary16',
};

export const SM_PREFERENCES_DCS = {
    loaded: 'SM_PREFERENCES_DCSLoaded',
    id: 'SM_PREFERENCES_DCS',
    label: 'Diary Colour Selection',
    icon: 'fa colourPicker16',
    tabIcon: 'fa colourPicker16',
};

export const SM_PREFERENCES_EMAIL = {
    loaded: 'SM_PREFERENCES_EMAILLoaded',
    id: 'SM_PREFERENCES_EMAIL',
    label: 'Email Account Settings',
    icon: 'fa emailEdit16',
    tabIcon: 'fa emailEdit16',
};

export const SM_PREFERENCES_PDS = {
    loaded: 'SM_PREFERENCES_PDSLoaded',
    id: 'SM_PREFERENCES_PDS',
    label: 'Practice Details',
    icon: 'fa noteEdit16',
    tabIcon: 'fa noteEdit16',
};

export const SM_PREFERENCES_HOURS = {
    loaded: 'SM_PREFERENCES_HOURSLoaded',
    id: 'SM_PREFERENCES_HOURS',
    label: 'Practice Opening Hours',
    icon: 'fa hoursEdit16',
    tabIcon: 'fa hoursEdit16',
};

export const SM_PREFERENCES_NHS = {
    loaded: 'SM_PREFERENCES_NHSLoaded',
    id: 'SM_PREFERENCES_NHS',
    label: 'NHS Registration',
    icon: 'fa nhsLogo16',
    tabIcon: 'fa nhsLogo16',
};

export const SM_PREFERENCES_LOCATIONS = {
    loaded: 'SM_PREFERENCES_LOCATIONSLoaded',
    id: 'SM_PREFERENCES_LOCATIONS',
    label: 'Practice Locations',
    icon: 'fas fa-map-pin',
    tabIcon: 'fas fa-map-pin',
};

export const SM_PREFERENCES_RAR = {
    loaded: 'SM_PREFERENCES_RARLoaded',
    id: 'SM_PREFERENCES_RAR',
    label: 'Recalls & Reminders',
    icon: 'fa recallsReminders16',
    tabIcon: 'fa recallsReminders16',
};

export const SM_PREFERENCES_ESENDEX = {
    loaded: 'SM_PREFERENCES_ESENDEXLoaded',
    id: 'SM_PREFERENCES_ESENDEX',
    label: "Esendex",
    icon: 'fa esendex16',
    tabIcon: 'fa esendex16',
};

export const SM_PREFERENCES_REPORTS = {
    loaded: 'SM_PREFERENCES_REPORTSLoaded',
    id: 'SM_PREFERENCES_REPORTS',
    label: 'Reports / Emails / SMS',
    icon: 'fa reports16',
    tabIcon: 'fa reports16',
};

export const SM_PREFERENCES_XRAY_GATEWAY = {
    loaded: 'SM_PREFERENCES_XRAY_GATEWAYLoaded',
    id: 'SM_PREFERENCES_XRAY_GATEWAY',
    label: 'X-Ray Gateway',
    icon: 'fas fa-x-ray',
    tabIcon: 'fas fa-x-ray',
};

// House keeping

export const SM_HOUSEKEEPING = {
    loaded: 'SM_HOUSEKEEPINGLoaded',
    id: 'SM_HOUSEKEEPING',
    label: 'House Keeping',
    bigIcon: 'fas fa-tools',
    icon: 'fas fa-tools',
    tabIcon: 'fas fa-tools'
};

export const SM_HOUSEKEEPING_CSCHED = {
    loaded: 'SM_HOUSEKEEPING_CSCHEDLoaded',
    id: 'SM_HOUSEKEEPING_CSCHED',
    label: 'Diary Schedules',
    icon: 'fa clinicianSchedules48',
    tabIcon: 'fa clinicianSchedules16',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-clinicians-schedules/`
};

export const SM_HOUSEKEEPING_MCS = {
    loaded: 'SM_HOUSEKEEPING_MCSLoaded',
    id: 'SM_HOUSEKEEPING_MCS',
    label: 'Medical Conditions',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-medical-conditions/`
};

export const SM_HOUSEKEEPING_QUES = {
    loaded: 'SM_HOUSEKEEPING_QUESLoaded',
    id: 'SM_HOUSEKEEPING_QUES',
    label: 'Questionnaires',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-questionnaires/`
};

export const SM_HOUSEKEEPING_RECTYPES = {
    loaded: 'SM_HOUSEKEEPING_RECTYPESLoaded',
    id: 'SM_HOUSEKEEPING_RECTYPES',
    label: 'Recall Types',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-recall-types/`
};

export const SM_HOUSEKEEPING_CRS = {
    loaded: 'SM_HOUSEKEEPING_CRSLoaded',
    id: 'SM_HOUSEKEEPING_CRS',
    label: 'Cancellation Reasons',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-cancellation-reasons/`
};

export const SM_HOUSEKEEPING_JSTAGES = {
    loaded: 'SM_HOUSEKEEPING_JSTAGESLoaded',
    id: 'SM_HOUSEKEEPING_JSTAGES',
    label: 'Patient Journey Stages',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-patient-journey-stages/`
};

export const SM_HOUSEKEEPING_DOC_CLASSIFICATIONS = {
    loaded: 'SM_HOUSEKEEPING_DOC_CLASSIFICATIONSLoaded',
    id: 'SM_HOUSEKEEPING_DOC_CLASSIFICATIONS',
    label: 'Document Classifications',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-document-classifications/`
};

export const SM_HOUSEKEEPING_MEDICATIONS = {
    loaded: 'SM_HOUSEKEEPING_MEDICATIONSLoaded',
    id: 'SM_HOUSEKEEPING_MEDICATIONS',
    label: 'Medications',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-medications/`
};

export const SM_HOUSEKEEPING_OCCS = {
    loaded: 'SM_HOUSEKEEPING_OCCSLoaded',
    id: 'SM_HOUSEKEEPING_OCCS',
    label: 'Occupations',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-occupations/`
};

export const SM_HOUSEKEEPING_ASSCATS = {
    loaded: 'SM_HOUSEKEEPING_ASSCATSLoaded',
    id: 'SM_HOUSEKEEPING_ASSCATS',
    label: 'Assessment Category Forms',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-assessment-category-forms/`
};

export const SM_HOUSEKEEPING_CCS = {
    loaded: 'SM_HOUSEKEEPING_CCSLoaded',
    id: 'SM_HOUSEKEEPING_CCS',
    label: 'Cost Centers',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-cost-centers/`
};

export const SM_HOUSEKEEPING_TCODES = {
    loaded: 'SM_HOUSEKEEPING_TCODESLoaded',
    id: 'SM_HOUSEKEEPING_TCODES',
    label: 'Treatment Codes',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-treatment-codes/`
};

export const SM_HOUSEKEEPING_ADETS = {
    loaded: 'SM_HOUSEKEEPING_ADETSLoaded',
    id: 'SM_HOUSEKEEPING_ADETS',
    label: 'Diary Types',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-diary-types/`
};

export const SM_HOUSEKEEPING_FAVS = {
    loaded: 'SM_HOUSEKEEPING_FAVSLoaded',
    id: 'SM_HOUSEKEEPING_FAVS',
    label: 'Treatment Favourites',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-treatment-favorites/`
};

export const SM_HOUSEKEEPING_BASE_CHART_FAVS = {
    loaded: 'SM_HOUSEKEEPING_BASE_CHART_FAVSLoaded',
    id: 'SM_HOUSEKEEPING_BASE_CHART_FAVS',
    label: 'Base Charting Favourites',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-base-chart-favorites/`
};

export const SM_HOUSEKEEPING_TITLES = {
    loaded: 'SM_HOUSEKEEPING_TITLESLoaded',
    id: 'SM_HOUSEKEEPING_TITLES',
    label: 'Titles',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-titles/`
};

export const SM_TASKS = {
    loaded: 'SM_TASKSLoaded',
    id: 'SM_TASKS',
    label: 'Outstanding Tasks',
    bigIcon: 'fas fa-tasks',
    icon: 'fas fa-tasks',
    tabIcon: 'fas fa-tasks'
};

export const SM_REMINDERS_DUE = {
    loaded: 'SM_REMINDERS_DUELoaded',
    id: 'SM_REMINDERS_DUE',
    label: 'Reminders Due',
    bigIcon: 'fas fa-bookmark',
    icon: 'fas fa-bookmark',
    tabIcon: 'fas fa-bookmark'
};

export const SM_RECALLS_DUE = {
    loaded: 'SM_RECALLS_DUELoaded',
    id: 'SM_RECALLS_DUE',
    label: 'Recalls Due',
    bigIcon: 'fas fa-user-clock',
    icon: 'fas fa-user-clock',
    tabIcon: 'fas fa-user-clock'
};

export const SM_CANCELLATION_INTEREST = {
    loaded: 'SM_CANCELLATION_INTERESTLoaded',
    id: 'CANCELLATION_INTEREST',
    label: 'Cancellation',
    icon: 'fa cancInterest24',
    tabIcon: 'fa cancInterest16'
};

export const SM_RESEND_PORTAL_REFERENCE = {
    loaded: 'SM_RESEND_PORTAL_REFERENCELoaded',
    id: 'SM_RESEND_PORTAL_REFERENCE',
    label: 'Resend Portal Reference',
    icon: 'fas fa-share-square',
    tabIcon: ''
};

export const SM_CHART_ITEM_MAPPING = {
    id: 'SM_CHART_ITEM_MAPPING',
    label: 'Charting Item Mapping',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-chart-items/`
};

export const SM_HOUSEKEEPING_PAT_CONSENT_FORMS = {
    id: 'SM_HOUSEKEEPING_PAT_CONSENT_FORMS',
    label: 'Patient Consent Forms',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-patient-consent-forms/`
};

// Group Management related

export const SM_GROUP_MANAGEMENT = {
    loaded: 'SM_GROUP_MANAGEMENT_LOADED',
    id: 'SM_GROUP_MANAGEMENT',
    label: 'Group Management',
    icon: 'fas fa-users-cog',
    tabIcon: 'fas fa-users-cog'
};

// Admin related

export const HELP_ADD_SUBSCRIPTION = `${KNOWLEDGE_BASE}/add-a-new-subscription/`;

export const SM_ADMIN_PRACTICE_GROUPS = {
    loaded: 'SM_ADMIN_PRACTICE_GROUPS_LOADED',
    id: 'SM_ADMIN_PRACTICE_GROUPS',
    label: 'Practice Groups',
    bigIcon: 'fas fa-user-friends',
    icon: 'fas fa-user-friends',
    tabIcon: 'fas fa-user-friends',
};

export const SM_ADMIN_GROUP_SUBSCRIPTIONS = {
    loaded: 'SM_ADMIN_GROUP_SUBSCRIPTIONS_LOADED',
    id: 'SM_ADMIN_GROUP_SUBSCRIPTIONS',
    label: 'Practice Groups',
    bigIcon: 'fas fa-users',
    icon: 'fas fa-users',
    tabIcon: 'fas fa-users',
};

export const SM_ADMIN_ADD_SUBSCRIPTION = {
    loaded: 'SM_ADMIN_ADD_SUBSCRIPTION_LOADED',
    id: 'SM_ADMIN_GROUP_SUBSCRIPTIONS',
    label: 'Add Group Subscription',
    bigIcon: 'fas fa-user',
    icon: 'fas fa-user',
    tabIcon: 'fas fa-user',
};

export const SM_ADD_GROUP = {
    id: 'SM_ADD_GROUP',
    label: 'Add Group',
    bigIcon: 'fas fa-user-plus',
    icon: 'fas fa-user-plus',
    tabIcon: 'fas fa-user-plus'
};

export const SM_SUBSCRIPTION_DATA = {
    loaded: 'SM_SUBSCRIPTION_DATA_LOADED',
    id: 'SM_SUBSCRIPTION_DATA',
    label: 'Open Subscription Details',
    icon: 'fas fa-people-arrows',
    tabIcon: 'fas fa-people-arrows',
};

export const SM_SUBSCRIPTION_SEARCH = {
    loaded: 'SM_SUBSCRIPTION_SEARCH_LOADED',
    id: 'SM_SUBSCRIPTION_SEARCH',
    label: 'Find Subscriptions',
    bigIcon: 'fas fa-search',
    icon: 'fas fa-search',
    tabIcon: 'fas fa-search'
};

export const SM_ACTIVE_LOGINS = {
    loaded: 'SM_ACTIVE_LOGINS_LOADED',
    id: 'SM_ACTIVE_LOGINS',
    label: 'Active Logins',
    bigIcon: 'fas fa-user-lock',
    icon: 'fas fa-user-lock',
    tabIcon: 'fas fa-user-lock'
};

export const SM_PLACE_HOLDER = {
    loaded: 'SM_PLACE_HOLDERLoaded',
    id: 'SM_PLACE_HOLDER',
    label: 'TBA',
    bigIcon: 'fas fa-users-cog',
    icon: 'fas fa-users-cog',
    tabIcon: 'fas fa-users-cog'
};

// NHS Management related

export const SM_NHS_MANAGEMENT = {
    loaded: 'SM_NHS_MANAGEMENTLoaded',
    id: 'SM_NHS_MANAGEMENT',
    label: 'NHS Management',
    icon: 'fas fa-notes-medical',
    tabIcon: 'fas fa-notes-medical'
};

export const SM_NHS_MANAGEMENT_STACKED = {
    loaded: 'SM_NHS_MANAGEMENT_STACKEDLoaded',
    id: 'SM_NHS_MANAGEMENT_STACKED',
    label: 'Stacked',
    icon: 'fa notepadEdit16',
};

export const SM_NHS_MANAGEMENT_SUBMITTED = {
    loaded: 'SM_NHS_MANAGEMENT_SUBMITTEDLoaded',
    id: 'SM_NHS_MANAGEMENT_SUBMITTED',
    label: 'Submitted',
    icon: 'fa notepadEdit16',
};

export const SM_NHS_MANAGEMENT_REJECTED = {
    loaded: 'SM_NHS_MANAGEMENT_REJECTEDLoaded',
    id: 'SM_NHS_MANAGEMENT_REJECTED',
    label: 'Rejected',
    icon: 'fa notepadEdit16',
};

export const SM_NHS_MANAGEMENT_REMOVED = {
    loaded: 'SM_NHS_MANAGEMENT_REMOVEDLoaded',
    id: 'SM_NHS_MANAGEMENT_REMOVED',
    label: 'Removed',
    icon: 'fa notepadEdit16',
};

export const SM_NHS_MANAGEMENT_SCHEDULED = {
    loaded: 'SM_NHS_MANAGEMENT_SCHEDULEDLoaded',
    id: 'SM_NHS_MANAGEMENT_SCHEDULED',
    label: 'Scheduled',
    icon: 'fa notepadEdit16',
};

export const SM_NHS_FIND_AND_EDIT = {
    loaded: 'SM_NHS_FIND_AND_EDITLoaded',
    id: 'SM_NHS_FIND_AND_EDIT',
    label: 'Find and Edit Claim',
    icon: ICON_SEARCH,
};

// reports related 

export const SM_REPORTS = {
    id: 'SM_REPORTS',
    label: 'Reports',
    icon: 'fas fa-chart-area',
    tabIcon: 'fas fa-chart-area'
};

export const SM_REPORTS_OUTSTANDING_ACCOUNTS = {
    loaded: 'SM_REPORTS_OUTSTANDING_ACCOUNTSLoaded',
    id: 'SM_REPORTS_OUTSTANDING_ACCOUNTS',
    label: 'Outstanding Accounts',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_OUTSTANDING_ACCOUNTS_PDF = {
    loaded: 'SM_REPORTS_OUTSTANDING_ACCOUNTS_PDFLoaded',
    id: 'SM_REPORTS_OUTSTANDING_ACCOUNTS_PDF',
    label: 'Outstanding Accounts PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'OutstandingAccounts',
};

export const SM_REPORTS_REVENUES = {
    label: 'Revenues',
    icon: 'fas fa-chart-area',
    tabIcon: 'fas fa-chart-area'
};

export const SM_REPORTS_DAILY_CASH = {
    loaded: 'SM_REPORTS_DAILY_CASHLoaded',
    id: 'SM_REPORTS_DAILY_CASH',
    label: 'Daily Cash Book',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_DAILY_CASH_PDF = {
    loaded: 'SM_REPORTS_DAILY_CASH_PDFLoaded',
    id: 'SM_REPORTS_DAILY_CASH_PDF',
    label: 'Daily Cash Book PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'DailyCashBook',
};

export const SM_REPORTS_CANCELS = {
    label: 'Cancellations',
    icon: 'fas fa-chart-area',
    tabIcon: 'fas fa-chart-area'
};

export const SM_REPORTS_DAILY_CANCELS = {
    loaded: 'SM_REPORTS_DAILY_CANCELSLoaded',
    id: 'SM_REPORTS_DAILY_CANCELS',
    label: 'Daily Cancellations',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_ATTEND = {
    label: 'Non Attendance',
    icon: 'fas fa-chart-area',
    tabIcon: 'fas fa-chart-area'
};

export const SM_REPORTS_UTILISATION = {
    label: 'Utilisation',
    icon: 'fas fa-chart-area',
    tabIcon: 'fas fa-chart-area'
};

export const SM_REPORTS_DAILY_CANCELS_PDF = {
    loaded: 'SM_REPORTS_DAILY_CANCELS_PDFLoaded',
    id: 'SM_REPORTS_DAILY_CANCELS_PDF',
    label: 'Daily Cancellations PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'DailyCancels',
};

export const SM_REPORTS_WEEKLY_NON_ATTEND = {
    loaded: 'SM_REPORTS_WEEKLY_NON_ATTENDLoaded',
    id: 'SM_REPORTS_WEEKLY_NON_ATTEND',
    label: 'Weekly FTAs',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_WEEKLY_NON_ATTEND_PDF = {
    loaded: 'SM_REPORTS_WEEKLY_NON_ATTEND_PDFLoaded',
    id: 'SM_REPORTS_WEEKLY_NON_ATTEND_PDF',
    label: 'Weekly FTAs PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'WeeklyFTAs',
};

export const SM_REPORTS_RECALL_MANAGEMENT = {
    loaded: 'SM_REPORTS_RECALL_MANAGEMENTLoaded',
    id: 'SM_REPORTS_RECALL_MANAGEMENT',
    label: 'Recall Management',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_RECALL_MANAGEMENT_PDF = {
    loaded: 'SM_REPORTS_RECALL_MANAGEMENTLoaded_PDF',
    id: 'SM_REPORTS_RECALL_MANAGEMENT_PDF',
    label: 'Recall Management PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'RecallManagement',
};

export const SM_REPORTS_DAILY_DELETES = {
    loaded: 'SM_REPORTS_DAILY_DELETESLoaded',
    id: 'SM_REPORTS_DAILY_DELETES',
    label: 'Daily Deletions',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};


export const SM_REPORTS_DAILY_DELETES_PDF = {
    loaded: 'SM_REPORTS_DAILY_DELETES_PDFLoaded',
    id: 'SM_REPORTS_DAILY_DELETES_PDF',
    label: 'Daily Deletions PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'DailyDeletions',
};

export const SM_REPORTS_WEEKLY_CANCELS = {
    loaded: 'SM_REPORTS_WEEKLY_CANCELSLoaded',
    id: 'SM_REPORTS_WEEKLY_CANCELS',
    label: 'Weeks Cancellations',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_WEEKLY_CANCELS_PDF = {
    loaded: 'SM_REPORTS_WEEKLY_CANCELS_PDFLoaded',
    id: 'SM_REPORTS_WEEKLY_CANCELS_PDF',
    label: 'Weeks Cancellations PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'WeeksCancels',
};

export const SM_REPORTS_WEEKLY_FAILS = {
    loaded: 'SM_REPORTS_WEEKLY_FAILSLoaded',
    id: 'SM_REPORTS_WEEKLY_FAILS',
    label: 'Weeks Failed to Attends',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_WEEKLY_FAILS_PDF = {
    loaded: 'SM_REPORTS_WEEKLY_FAILS_PDFLoaded',
    id: 'SM_REPORTS_WEEKLY_FAILS_PDF',
    label: 'Weeks Failed to Attends PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'WeeksFTAs',
};

export const SM_REPORTS_WEEKLY_DELETES = {
    loaded: 'SM_REPORTS_WEEKLY_DELETESLoaded',
    id: 'SM_REPORTS_WEEKLY_DELETES',
    label: 'Weeks Deletions',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_WEEKLY_DELETES_PDF = {
    loaded: 'SM_REPORTS_WEEKLY_DELETES_PDFLoaded',
    id: 'SM_REPORTS_WEEKLY_DELETES_PDF',
    label: 'Weeks Deletions PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'WeeksDeletions',
};

export const SM_REPORTS_PAYMENT_PLANS = {
    loaded: 'SM_REPORTS_PAYMENT_PLANSLoaded',
    id: 'SM_REPORTS_PAYMENT_PLANS',
    label: 'Payment Plans',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_PAYMENT_PLANS_PDF = {
    loaded: 'SM_REPORTS_PAYMENT_PLANS_PDFLoaded',
    id: 'SM_REPORTS_PAYMENT_PLANS_PDF',
    label: 'Payment Plans PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'PaymentPlans',
};

// pr-392

export const SM_REPORTS_WEEKS_SHARE = {
    loaded: 'SM_REPORTS_WEEKS_SHARELoaded',
    id: 'SM_REPORTS_WEEKS_SHARE',
    label: 'Weekly Share',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_WEEKS_SHARE_PDF = {
    loaded: 'SM_REPORTS_WEEKS_SHARE_PDFLoaded',
    id: 'SM_REPORTS_WEEKS_SHARE_PDF',
    label: 'Weekly Share PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'WeeksShare'
};

export const SM_REPORTS_MONTHS_CASH = {
    loaded: 'SM_REPORTS_MONTHS_CASHLoaded',
    id: 'SM_REPORTS_MONTHS_CASH',
    label: 'Months Cash Book',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_MONTHS_CASH_PDF = {
    loaded: 'SM_REPORTS_MONTHS_CASH_PDFLoaded',
    id: 'SM_REPORTS_MONTHS_CASH_PDF',
    label: 'Months Cash Book PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'MonthsCashBook'
};

export const SM_REPORTS_MONTHS_SHARE = {
    loaded: 'SM_REPORTS_MONTHS_SHARELoaded',
    id: 'SM_REPORTS_MONTHS_SHARE',
    label: 'Monthly Share',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_MONTHS_SHARE_PDF = {
    loaded: 'SM_REPORTS_MONTHS_SHARE_PDFLoaded',
    id: 'SM_REPORTS_MONTHS_SHARE_PDF',
    label: 'Monthly Share PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'MonthsShare'
};

export const SM_REPORTS_YEARS_CASH = {
    loaded: 'SM_REPORTS_MONTHS_YEARSLoaded',
    id: 'SM_REPORTS_MONTHS_YEARS',
    label: 'Yearly Cash',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_YEARS_CASH_PDF = {
    loaded: 'SM_REPORTS_YEARS_CASH_PDFLoaded',
    id: 'SM_REPORTS_YEARS_CASH_PDF',
    label: 'Yearly Cash PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'YearsCashBook'
};

export const SM_REPORTS_YEARS_SHARE = {
    loaded: 'SM_REPORTS_YEARS_SHARELoaded',
    id: 'SM_REPORTS_YEARS_SHARE',
    label: 'Yearly Share',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_YEARS_SHARE_PDF = {
    loaded: 'SM_REPORTS_YEARS_SHARE_PDFLoaded',
    id: 'SM_REPORTS_YEARS_SHARE_PDF',
    label: 'Yearly Share PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'YearsShare'
};

export const SM_REPORTS_DAILY_TRANSACTIONS = {
    id: 'SM_REPORTS_DAILY_TRANSACTIONS',
    label: 'Daily Transactions',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_DAILY_TRANSACTIONS_PDF = {
    id: 'SM_REPORTS_DAILY_TRANSACTIONS_PDF',
    label: 'Daily Transactions PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'DailyTransactions',
};

export const SM_REPORTS_MONTHLY_TRANSACTIONS = {
    id: 'SM_REPORTS_MONTHLY_TRANSACTIONS',
    label: 'Monthly Transactions',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_MONTHLY_TRANSACTIONS_PDF = {
    id: 'SM_REPORTS_MONTHLY_TRANSACTIONS_PDF',
    label: 'Monthly Transactions PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'MonthlyTransactions',
};

export const SM_REPORTS_ACCOUNTS_IN_CREDIT = {
    loaded: 'SM_REPORTS_ACCOUNTS_IN_CREDITLoaded',
    id: 'SM_REPORTS_ACCOUNTS_IN_CREDIT',
    label: 'Accounts In Credit',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_ACCOUNTS_IN_CREDIT_PDF = {
    loaded: 'SM_REPORTS_ACCOUNTS_IN_CREDIT_PDFLoaded',
    id: 'SM_REPORTS_ACCOUNTS_IN_CREDIT_PDF',
    label: 'Accounts In Credit PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'AccountsInCredit'
};

export const SM_REPORTS_CHARGES = {
    label: 'Charges',
    icon: 'fas fa-chart-area',
    tabIcon: 'fas fa-chart-area'
};

export const SM_REPORTS_UNINVOICED_CHARGES = {
    loaded: 'SM_REPORTS_UNINVOICED_CHARGESLoaded',
    id: 'SM_REPORTS_UNINVOICED_CHARGES',
    label: 'Uninvoiced Charges',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_UNINVOICED_CHARGES_PDF = {
    loaded: 'SM_REPORTS_UNINVOICED_CHARGES_PDFLoaded',
    id: 'SM_REPORTS_UNINVOICED_CHARGES_PDF',
    label: 'Uninvoiced Charges PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'UninvCharges',
};

export const SM_REPORTS_VOIDED_TRANSACTIONS = {
    loaded: 'SM_REPORTS_VOIDED_TRANSACTIONSLoaded',
    id: 'SM_REPORTS_VOIDED_TRANSACTIONS',
    label: 'Voided Transactions',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_VOIDED_TRANSACTIONS_PDF = {
    loaded: 'SM_REPORTS_VOIDED_TRANSACTIONS_PDFLoaded',
    id: 'SM_REPORTS_VOIDED_TRANSACTIONS_PDF',
    label: 'Voided Transactions PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'Voids'
};

export const SM_REPORTS_WRITTEN_OFFS = {
    loaded: 'SM_REPORTS_WRITTEN_OFFSLoaded',
    id: 'SM_REPORTS_WRITTEN_OFFS',
    label: 'Write Offs',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_WRITTEN_OFFS_PDF = {
    loaded: 'SM_REPORTS_WRITTEN_OFFSLoaded_PDF',
    id: 'SM_REPORTS_WRITTEN_OFFS_PDF',
    label: 'Write Offs PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'WriteOffs'
};

export const SM_REPORTS_COST_CENTERS = {
    loaded: 'SM_REPORTS_COST_CENTERSLoaded',
    id: 'SM_REPORTS_COST_CENTERS',
    label: 'Cost Centers',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_COST_CENTERS_PDF = {
    loaded: 'SM_REPORTS_COST_CENTERSLoaded_PDF',
    id: 'SM_REPORTS_COST_CENTERS_PDF',
    label: 'Cost Centers PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf'
};

export const SM_REPORTS_INCOMPLETE_CHARGES = {
    loaded: 'SM_REPORTS_INCOMPLETE_CHARGESLoaded',
    id: 'SM_REPORTS_INCOMPLETE_CHARGES',
    label: 'Incomplete Charges',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_INCOMPLETE_CHARGES_PDF = {
    loaded: 'SM_REPORTS_INCOMPLETE_CHARGES_PDFLoaded',
    id: 'SM_REPORTS_INCOMPLETE_CHARGES_PDF',
    label: 'Incomplete Charges PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'IncompleteCharges'
};

export const SM_REPORTS_PATIENT_JOURNEY = {
    loaded: 'SM_REPORTS_PATIENT_JOURNEYLoaded',
    id: 'SM_REPORTS_PATIENT_JOURNEY',
    label: 'Patient Journey',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_PATIENT_JOURNEY_MAIN = {
    loaded: 'SM_REPORTS_PATIENT_MAIN_JOURNEYLoaded',
    id: 'SM_REPORTS_PATIENT_JOURNEY_MAIN',
    label: 'Patient Journey',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_PATIENT_JOURNEY_PDF = {
    loaded: 'SM_REPORTS_PATIENT_JOURNEY_PDFLoaded',
    id: 'SM_REPORTS_PATIENT_JOURNEY_PDF',
    label: 'Patient Journey PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'PatJourneys',
};

export const SM_REPORTS_PATIENT_JOURNEY_SS = {
    loaded: 'SM_REPORTS_PATIENT_JOURNEY_SSLoaded',
    id: 'SM_REPORTS_PATIENT_JOURNEY_SS',
    label: 'Patient Journey Snap Shot',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_PATIENT_JOURNEY_SS_PDF = {
    loaded: 'SM_REPORTS_PATIENT_JOURNEY_PDF_SSLoaded',
    id: 'SM_REPORTS_PATIENT_JOURNEY_PDF_SS',
    label: 'Patient Journey Snap Shot PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'PatJourneysSS',
};

export const SM_REPORTS_CODE_USAGE = {
    loaded: 'SM_REPORTS_CODE_USAGELoaded',
    id: 'SM_REPORTS_CODE_USAGE',
    label: 'Treatment Codes',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_CODE_SUMMARY = {
    loaded: 'SM_REPORTS_CODE_SummaryLoaded',
    id: 'SM_REPORTS_CODE_SUMMARY',
    label: 'Treatment Summary',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_CLINICIAN_USAGE = {
    loaded: 'SM_REPORTS_CLINICIAN_USAGELoaded',
    id: 'SM_REPORTS_CLINICIAN_USAGE',
    label: 'Clinician Usage',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_SMS_LIMITS = {
    loaded: 'SM_REPORTS_SMS_LIMITSLoaded',
    id: 'SM_REPORTS_SMS_LIMITS',
    label: 'SMS Limits',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_ACC_PPS = {
    loaded: 'SM_REPORTS_ACC_PPS_LOADED',
    id: 'SM_REPORTS_ACC_PPS',
    label: 'Active Payment Plans',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_COP_REPORTS = {
    label: 'Bracelands',
    icon: 'fas fa-chart-area',
    tabIcon: 'fas fa-chart-area'
};

export const SM_REPORTS_COP_REPORT1_10 = {
    loaded: 'SM_REPORTS_COP_REPORT10Loaded',
    id: 'SM_REPORTS_COP_REPORT1_10',
    label: 'Report 1 (10)',
    description: 'Bracelands 10 year report generation',
    filename: 'report1_10_',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar',
    type: '_10Years'
};

export const SM_REPORTS_COP_REPORT1_15 = {
    loaded: 'SM_REPORTS_COP_REPORT15Loaded',
    id: 'SM_REPORTS_COP_REPORT1_15',
    label: 'Report 1 (15)',
    description: 'Bracelands 15 year report generation',
    filename: 'report1_15_',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar',
    type: '_15Years'
};

export const SM_REPORTS_COP_REPORT1_20 = {
    loaded: 'SM_REPORTS_COP_REPORT20Loaded',
    id: 'SM_REPORTS_COP_REPORT1_20',
    label: 'Report 1 (20)',
    description: 'Bracelands 20 year report generation',
    filename: 'report1_10_',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar',
    type: '_20Years'
};

export const SM_REPORTS_COP_REPORT9 = {
    loaded: 'SM_REPORTS_COP_REPORT9Loaded',
    id: 'SM_REPORTS_COP_REPORT9',
    label: 'Report 9',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

export const SM_REPORTS_COP_MONTHLY_TRANS = {
    loaded: 'SM_REPORTS_COP_MONTHLY_TRANSLoaded',
    id: 'SM_REPORTS_COP_MONTHLY_TRANS',
    label: 'Monthly Transactions',
    icon: 'far fa-chart-bar',
    tabIcon: 'far fa-chart-bar'
};

//

export const stateRequest = (source, ...params) => {

    return (dispatch, getState) => {

        const sm = getState().stateManagement;

        switch (source.action) {

            case RES_HOUSEKEEPING.SAVE.action:
                saveHouskeeping(dispatch, sm);
                break;

            case RES_HOUSEKEEPING.SAVE_CLEAR.action:
                saveHouskeeping(dispatch, sm);
                dispatch({type: RES_HOUSEKEEPING.CLEAR.action});
                break;

            case RES_TREATMENT_PLAN_BY_ID.CLEAR_PDF.action:

                dispatch({type: RES_TREATMENT_PLAN_BY_ID.CLEAR_PDF.action, payload: source});
                break;

            case RES_PREFERENCES.SAVE.action:
                savePreferences(dispatch, sm);
                break;

            case RES_PREFERENCES.SAVE_CLEAR.action:
                savePreferences(dispatch, sm);
                dispatch({type: RES_PREFERENCES.CLEAR.action});
                break;

            case RES_CLINICIAN_DIARY.CLEAR.action:

                dispatch({type: RES_CLINICIAN_DIARY.CLEAR.action, payload: source.clinicianId});
                break;

            case RES_TREATMENT_PLAN_BY_ID.ADD.action:

                ac.fetchClient().post(`${RES_TREATMENT_PLAN_BY_ID.ADD.url}/?mcId=${ac.getMcId()}`, params[0])
                    .then(res => {

                        dispatch({type: RES_TREATMENT_PLAN_BY_ID.ADD.action, payload: res.data, id: res.data.id})
                    });
                break;

            /////////////////////////////////////////////////// Appointment

            case RES_TREATMENT_PLAN_BY_APP_ID.SAVE.action: {

                try {
                    const treatmentPlan = sm[source.smRef].data.treatmentPlan;
                    savePlan(dispatch, sm, source, treatmentPlan, true, false);
                } catch (error) {
                    console.log(error);
                }
                break;
            }

            case RES_TREATMENT_PLAN_BY_APP_ID.SAVE_CLEAR.action: {

                try {
                    const treatmentPlan = sm[source.smRef].data.treatmentPlan;
                    savePlan(dispatch, sm, source, treatmentPlan, true, true);
                } catch (error) {
                    console.log(error);
                }
                break;
            }

            case RES_TREATMENT_PLAN_BY_APP_ID.CLEAR.action:

                dispatch({type: RES_TREATMENT_PLAN_BY_APP_ID.CLEAR.action, id: source.id, smRef: source.smRef});
                break;

            /////////////////////////////////////////////////// Treatment Planning

            case RES_TREATMENT_PLANNING.SAVE.action: {

                let plans = _.get(sm, `${SM_TreatmentPlanHistory.id}_${source.smRef}.data.treatmentPlanHistory.plans`);

                plans = plans === undefined ? [] : plans;

                plans.forEach(plan => {

                    const treatmentPlanData = _.get(sm, `${SM_TreatmentPlan.id}_${plan.id}.data`);

                    if (treatmentPlanData) {

                        const {treatmentPlan} = treatmentPlanData;

                        if (Boolean(treatmentPlan.edited)) {
                            savePlan2(dispatch, sm, source, treatmentPlan, false);
                        }
                    }
                });
                break;
            }

            case RES_TREATMENT_PLANNING.SAVE_CLEAR.action: {

                let plans = _.get(sm, `${SM_TreatmentPlanHistory.id}_${source.id}.data.treatmentPlanHistory.plans`);

                plans = plans === undefined ? [] : plans;

                plans.forEach(plan => {

                    const treatmentPlanData = _.get(sm, `${SM_TreatmentPlan.id}_${plan.id}.data`);

                    if (treatmentPlanData !== undefined) {

                        const {treatmentPlan} = treatmentPlanData;

                        if (Boolean(treatmentPlan.edited)) {
                            savePlan2(dispatch, sm, source, treatmentPlan, true);
                        }
                    }
                });
                break;
            }

            case RES_TREATMENT_PLANNING.CLEAR.action:

                dispatch({type: RES_TREATMENT_PLANNING.CLEAR.action, id: source.id, smRef: source.smRef});
                break;

            case RES_PAYMENT_PLANNING.SAVE.action: {

                const selectedPPs = `${SM_PaymentPlaning.id}_${source.smRef}.data.selectedPPs`;
                let plans = _.get(sm, selectedPPs);

                plans = plans === undefined ? [] : plans;

                plans.forEach(plan => {

                    const paymentPlanData = _.get(sm, `${SM_PAYMENT_PLAN.id}_${plan.id}.data`);

                    if (paymentPlanData !== undefined) {

                        const {paymentPlan} = paymentPlanData;

                        if (Boolean(paymentPlan.edited)) {
                            const installments = [];
                            savePaymentPlan(dispatch, sm, source, paymentPlan, installments, false);
                        }
                    }
                });
                break;
            }

            case RES_PAYMENT_PLANNING.SAVE_CLEAR.action: {

                const selectedPPs = `${SM_PaymentPlaning.id}_${source.smRef}.data.selectedPPs`;
                let plans = _.get(sm, selectedPPs);

                plans = plans === undefined ? [] : plans;

                plans.forEach(plan => {

                    const paymentPlanData = _.get(sm, `${SM_PAYMENT_PLAN.id}_${plan.id}.data`);

                    if (paymentPlanData !== undefined) {

                        const {paymentPlan} = paymentPlanData;

                        if (Boolean(paymentPlan.edited)) {
                            const installments = [];
                            savePaymentPlan(dispatch, sm, source, paymentPlan, installments, true);
                        }
                    }
                });
                break;
            }

            case RES_PAYMENT_PLANNING.CLEAR.action:

                dispatch({type: RES_PAYMENT_PLANNING.CLEAR.action, id: source.id, smRef: source.smRef});

                break;

            case RES_PAYMENT_PLAN_BY_ID.ADD.action:

                ac.fetchClient().post(`${RES_PAYMENT_PLAN_BY_ID.ADD.url}/?mcId=${ac.getMcId()}`, params[0])
                    .then(res => {

                        dispatch({type: RES_PAYMENT_PLAN_BY_ID.ADD.action, payload: res.data, id: res.data.id})
                    });
                break;

            case RES_LABORATORY_DETAILS.SAVE.action:

                const laboratoryDetailsId = `${SM_LABORATORY_DETAILS_DATA.id}_${source.smRef}`;
                const laboratory = sm[laboratoryDetailsId].data.laboratoryDetails;

                ac.fetchClient().post(`${RES_LABORATORY_DETAILS.SAVE.url}/?mcId=${ac.getMcId()}`, laboratory)
                    .then(res => {

                        dispatch({type: RES_LABORATORY_DETAILS.SAVE.action, payload: res.data, id: res.data.id})
                    });
                break;

            ///////////////////////////////////////////////////

            case RES_getUsersAndCapabilities.SAVE_USERS.action:
                saveUserCapabilities(dispatch, sm, false);
                break;

            case RES_getUsersAndCapabilities.SAVE_ROLES.action:
                saveRoleCapabilities(dispatch, sm, false);
                break;

            case RES_TEMPLATE_NOTES.SAVE.action:
                saveTemplateNotes(dispatch, sm, false);
                saveNoteFavourites(dispatch, sm, false);
                break;

            case RES_TEMPLATE_NOTES.SAVE_CLEAR.action:
                saveTemplateNotes(dispatch, sm, true);
                saveNoteFavourites(dispatch, sm, true);
                break;

            case Actions.CLEAR_REFERRER_ADD:

                dispatch({type: Actions.CLEAR_REFERRER_ADD, payload: source.id});
                break;

            case RES_REFERRER_DETAILS.SAVE_CLEAR.action: {
                saveReferrer(dispatch, sm, source, true);
                break;
            }

            case RES_REFERRER_DETAILS.SAVE.action: {
                saveReferrer(dispatch, sm, source, false);
                break;
            }

            case Actions.CLEAR_PATIENT_ADD:

                dispatch({type: Actions.CLEAR_PATIENT_ADD, payload: source.id});
                break;

            case Actions.CLEAR_USER_ADD:

                dispatch({type: Actions.CLEAR_USER_ADD, payload: source.id});
                break;

            case RES_getAccountGroups.SAVE.action:
                saveAccountGroups(dispatch, sm, source);
                break;

            case RES_getAccountGroups.CLEAR.action:
                dispatch({type: Actions.CLEAR_ACCOUNTGROUP, payload: source.id});
                break;

            case Actions.CLEAR_SUBSCRIPTION_ADD:
                dispatch({type: Actions.CLEAR_SUBSCRIPTION_ADD, payload: source.id});
                break;

            case RES_PATIENT_DETAILS.CLEAR.action:
                dispatch({type: RES_PATIENT_DETAILS.CLEAR.action, smRef: source.smRef});
                dispatch({type: RES_PATIENT_DETAILS.CLEAR.action, smRef: source.smRef});
                break;

            case RES_REFERRER_DETAILS.CLEAR.action:
                dispatch({type: RES_REFERRER_DETAILS.CLEAR.action, smRef: source.smRef});
                break;

            case RES_PATIENT_DETAILS.SAVE_TABLET.action: {
                saveTabletPatient(dispatch, sm, source);
                break;
            }

            case RES_PATIENT_DETAILS.SAVE_CLEAR.action: {
                savePatient(dispatch, sm, source, true);
                break;
            }

            case RES_PATIENT_DETAILS.SAVE.action: {
                savePatient(dispatch, sm, source, false);
                break;
            }

            case RES_PATIENT_DETAILS.SAVE_PORTAL.action: {
                savePatientPortal(dispatch, sm, source);
                break;
            }

            case Actions.RECEIVE_SAVE_TAB_PATIENT_CONSENTS:

                dispatch({type: Actions.RECEIVE_SAVE_TAB_PATIENT_CONSENTS});
                break;

            case RES_PATIENT_MHFS.SAVE.action:
                savePatientMHF(dispatch, sm, source);
                break;

            case RES_PATIENT_MHFS.NO_CHANGE.action:
                patientMHFNoChange(dispatch, sm, source);
                break;

            case RES_PATIENT_QUESTIONNAIRE.SAVE.action:
                savePatientQuestionnaire(dispatch, sm, source);
                break;

            case RES_EMAIL_SHOT.ADD.action:
                addEmailShot(dispatch, params[0]);
                break;

            case RES_SMS_SHOT.ADD.action:
                addSMSShot(dispatch, params[0]);
                break;

            case RES_EMAIL_SHOT.SAVE.action: {

                const selectedShots = `${SM_EMAIL_MAIL_SHOTS.id}.data.selectedShots`;
                let shots = _.get(sm, selectedShots);

                shots = shots === undefined ? [] : shots;

                shots.forEach(eShot => {

                    const shotData = _.get(sm, `${SM_EMAIL_MAIL_SHOT.id}_${eShot.id}.data`);

                    if (shotData !== undefined) {

                        const {shot} = shotData;

                        if (Boolean(shot.edited)) {
                            saveEmailShot(dispatch, shot);
                        }
                    }
                });
                break;
            }

            case RES_EMAIL_SHOT.SAVE_CLEAR.action: {

                const selectedShots = `${SM_EMAIL_MAIL_SHOTS.id}.data.selectedShots`;
                let shots = _.get(sm, selectedShots);

                shots = shots === undefined ? [] : shots;

                shots.forEach(eShot => {

                    const shotData = _.get(sm, `${SM_EMAIL_MAIL_SHOT.id}_${eShot.id}.data`);

                    if (shotData !== undefined) {

                        const {shot} = shotData;

                        if (Boolean(shot.edited)) {
                            saveEmailShot(dispatch, shot);
                        }
                    }
                });
                break;
            }

            case RES_SMS_SHOT.SAVE.action: {

                const selectedShots = `${SM_SMS_MAIL_SHOTS.id}.data.selectedShots`;
                let shots = _.get(sm, selectedShots);

                shots = shots === undefined ? [] : shots;

                shots.forEach(eShot => {

                    const shotData = _.get(sm, `${SM_SMS_MAIL_SHOT.id}_${eShot.id}.data`);

                    if (shotData !== undefined) {

                        const {shot} = shotData;

                        if (Boolean(shot.edited)) {
                            saveSMSShot(dispatch, shot);
                        }
                    }
                });
                break;
            }

            case RES_SMS_SHOT.SAVE_CLEAR.action: {

                const selectedShots = `${SM_SMS_MAIL_SHOTS.id}.data.selectedShots`;
                let shots = _.get(sm, selectedShots);

                shots = shots === undefined ? [] : shots;

                shots.forEach(eShot => {

                    const shotData = _.get(sm, `${SM_SMS_MAIL_SHOT.id}_${eShot.id}.data`);

                    if (shotData !== undefined) {

                        const {shot} = shotData;

                        if (Boolean(shot.edited)) {
                            saveEmailShot(dispatch, shot);
                        }
                    }
                });
                break;
            }
            case RES_SUBSCRIPTION_DETAILS.SAVE.action:

                const subscriptionDataId = `${source.id}.data.subscriptionData`;
                const subscriptionData = _.get(sm, subscriptionDataId);

                if (subscriptionData.passwd === NewPassword) {
                    subscriptionData.hash = null;
                    subscriptionData.salt = null;
                }

                ac.fetchClient().post(`${RES_SUBSCRIPTION_DETAILS.SAVE.url}`, subscriptionData)
                    .then(res => {

                        dispatch({type: RES_SUBSCRIPTION_DETAILS.SAVE.action, payload: res.data, id: source.id})
                    });
                break;

            case RES_PREFERENCES.CLEAR.action:
            case RES_PRACTICE_DIARY.CLEAR.action:
            case RES_REPORT_IN_CREDIT.CLEAR.action:
            case RES_REPORT_PAYMENT_PLANS.CLEAR.action:
            case RES_TEMPLATE_NOTES.CLEAR.action:
            case RES_getRecallsDue.CLEAR.action:
            case RES_getRemindersDue.CLEAR.action:
            case RES_FIND_USERS.CLEAR.action:
            case RES_FIND_PATIENTS.CLEAR.action:
            case RES_FIND_REFERRERS.CLEAR.action:
            case RES_FIND_LABORATORIES.CLEAR.action:
            case RES_PATIENT_XRAY_RECORDS.CLEAR.action:
            case RES_REPORT_WORK_REQUESTED.CLEAR.action:
            case RES_REPORT_WORK_REQUIRED_DUE.CLEAR.action:
            case RES_REPORT_WORK_RECEIVED.CLEAR.action:
            case RES_REPORT_DAILY_TRANSACTIONS.CLEAR.action:
            case RES_REPORT_DAILYCASH.CLEAR.action:
            case RES_REPORT_MONTHS_CASH.CLEAR.action:
            case RES_REPORT_YEARS_CASH.CLEAR.action:
            case RES_REPORT_WRITE_OFFS.CLEAR.action:
            case RES_REPORT_VOIDS.CLEAR.action:
            case RES_REPORT_DAILY_CANCELS.CLEAR.action:
            case RES_REPORT_DAILY_DELETES.CLEAR.action:
            case RES_REPORT_WEEKLY_SHARE.CLEAR.action:
            case RES_REPORT_WEEKLY_CANCELS.CLEAR.action:
            case RES_REPORT_WEEKLY_XRAY.CLEAR_REPORT.action:
            case RES_REPORT_WEEKLY_XRAY.CLEAR_SUMMARY.action:
            case RES_REPORT_MONTHLY_SHARE.CLEAR.action:
            case RES_WORK_REQUIRED.CLEAR.action:
            case RES_HOUSEKEEPING.CLEAR.action:
            case RES_REPORT_PAT_JOURNEYS.CLEAR.action:
            case RES_REPORT_WEEKLY_DELETES.CLEAR.action:
            case RES_LABORATORY_DETAILS.CLEAR.action:
            case RES_NHS_MANAGEMENT_CLAIMS.CLEAR.action:
            case RES_PP_DUE_INSTALLMENTS.CLEAR.action:
            case RES_PATIENT_PAYMENTS.CLEAR.action:

                dispatch({type: source.action, id: source.id, smRef: source.smRef});
                break;

            default:
                break;
        }
    }
};

export const setState = (id, data, optionalParent) => {

    return (dispatch) => {
        dispatch({type: Actions.SET_STATE, payload: {id, data, optionalParent}})
    }
};

export const messageBus = (message) => {

    return (dispatch) => {

        dispatch({type: Actions.MESSAGE_BUS, payload: message})
    }
};
