import React, {Component} from 'react';
import _ from 'lodash';
import {PALETTE_GRP_TP_APPS, SELECTED_ITEM_COLOUR} from "../Constants";
import {connect} from "react-redux";
import {getResource as getTPResource, RES_TREATMENT_PLAN_BY_ID} from "../../../../actions/treatmentPlanning";
import {SM_TreatmentPlan} from "../../../../actions/stateManagement";
import {ADDBACK, APP_CREATED, APP_TPUNPLACED} from "../../../../Constants";
import {ProgressBar} from "primereact/progressbar";
import * as Actions from "../../../../actions";
import {COLOR_ChiralPalette} from "../../../Client/Constants";
import {durationTemplate} from "../../../PatientDynamicItems/Utils";

class ConnectedTaskTPPalette extends Component {

    constructor(props) {
        super(props);

        const handoverTask = {...props.handoverTask};
        const {patient} = handoverTask;

        this.state = {

            handoverTask,

            diaryPaletteGroups: [
                {
                    label: `Plan Appointments for : ${patient.firstName} ${patient.lastName}`,
                    open: true,
                    type: PALETTE_GRP_TP_APPS,
                    contents: []
                },
            ],
        };

        this.paddingStyle = {paddingTop: 5, paddingLeft: 10, paddingRight: 5};

        this.changeGroupState = this.changeGroupState.bind(this);
        this.buildPalette = this.buildPalette.bind(this);
        this.insertTPAppointments = this.insertTPAppointments.bind(this);
    }

    componentDidMount() {

        const {id, treatmentPlan} = this.state.handoverTask;

        this.props.getTreatmentPlanById(treatmentPlan.id, id);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_TREATMENT_PLAN_BY_ID.GET_FOR_TASK.receive:

                    const unplacedAppointments = _.filter(this.props.treatmentPlan.appointments, appointment => {
                        return appointment.status === APP_TPUNPLACED && appointment.appointmentWith !== null && appointment.apType !== null;
                    });
                    this.setState({
                        treatmentPlanLoaded: this.props.treatmentPlanLoaded,
                        treatmentPlan: this.props.treatmentPlan,
                        unplacedAppointments,
                    });
                    break;

                case Actions.WSM_APPOINTMENTS:

                    switch (this.props.wsmessage.function) {

                        case ADDBACK: {
                            const addBackEventDAO = {...this.props.wsmessage.content};
                            const treatmentPlan = {...this.state.treatmentPlan};

                            const index = _.findIndex(treatmentPlan.appointments, appointment => appointment.appointmentId === addBackEventDAO.id);
                            treatmentPlan.appointments[index].status = APP_CREATED;

                            const unplacedAppointments = _.filter(treatmentPlan.appointments, appointment => {
                                return appointment.status === APP_TPUNPLACED && appointment.appointmentWith !== null && appointment.apType !== null;
                            });
                            this.setState({
                                treatmentPlan,
                                unplacedAppointments,
                            }, () => {
                                if (unplacedAppointments.length === 0) {
                                    this.props.onReturnFromHandover();
                                }
                            });
                            break;
                        }
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    changeGroupState(group) {

        const diaryPaletteGroups = _.cloneDeep(this.state.diaryPaletteGroups);

        let thisGroup = _.find(diaryPaletteGroups, (target) => {
            return group.label === target.label;
        });
        thisGroup.open = !thisGroup.open;

        this.setState({diaryPaletteGroups});
    }

    insertTPAppointments(group) {

        const results = [];

        let key = 1000;

        if (group.open) {

            const sortedAppointments = _.orderBy(this.state.unplacedAppointments, 'appointmentId', 'asc');
            sortedAppointments.forEach((appointment) => {

                let backgroundColor = appointment.appointmentId === this.state.selectedItem ? SELECTED_ITEM_COLOUR : null;
                const duration = durationTemplate(appointment.start, appointment.end);

                results.push(
                    <div key={key++}
                         id={appointment.id}
                         className='event26'
                         onClick={(event) => {
                             this.props.onEventSelected({
                                 paletteType: PALETTE_GRP_TP_APPS,
                                 id: appointment.appointmentId,
                                 appointment
                             });
                             this.setState({selectedItem: parseInt(event.target.id, 10)})
                         }}
                         style={{fontWeight: 'bold', ...this.paddingStyle, backgroundColor}}>
                        <span className='fas fa-calendar-plus'
                              style={{
                                  fontSize: '20px',
                                  color: COLOR_ChiralPalette,
                                  marginRight: '5px',
                                  marginTop: '5px'
                              }}/>
                        {`${appointment.appointmentWith.username} (${duration}) ${appointment.title}`}
                    </div>
                )
            });
        }
        return results;
    };

    buildPalette() {

        let results = [];

        let key = Math.floor(Math.random() * 1000);

        this.state.diaryPaletteGroups.forEach((group, index) => {

            let groupContent = [];
            let groupCount = 0;

            switch (group.type) {
                case PALETTE_GRP_TP_APPS:

                    groupContent = this.insertTPAppointments(group);
                    break;
                default:
                    break;
            }

            results.push(
                <div key={key++}
                     style={{backgroundColor: '#f5f5f5', borderTop: '1px solid grey', borderBottom: '1px dotted grey'}}
                     onClick={(item) => {
                         this.changeGroupState(group);
                     }}
                >
                    <i style={this.paddingStyle}
                       className="fa icons8Open"/>
                    {(groupCount === 0 || group.open) ? group.label : `${group.label}+`}
                </div>
            );
            results = [...results, ...groupContent];
        });
        return results;
    };

    render() {

        if (!this.state.treatmentPlanLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        return (
            <div className='p-sm-2' style={{display: 'flex', flexFlow: 'column'}}>
                <div style={{backgroundColor: '#f5f5f5', borderBottom: '1px solid grey'}}>
                    <i style={this.paddingStyle}
                       className="fa paletteView16"/>Handover Actions
                </div>
                <div className="diaryPaletteEntry"
                     style={{fontWeight: 'bold'}}
                     onClick={(item) => {
                         this.setState({selectedItem: null});
                         this.props.onReturnFromHandover();
                     }}
                >
                    <i style={this.paddingStyle}
                       className="fa arrow16"/>Return from handover
                </div>
                {this.buildPalette(this.props)}
            </div>
        )
    };
}

const mapStateToProps = (state, ownProps) => {

    const {id} = ownProps.handoverTask;

    const treatmentPlanLoadedId = `${SM_TreatmentPlan.loaded}_${id}`;
    const treatmentPlanId = `${SM_TreatmentPlan.id}_${id}`;

    const treatmentPlanLoaded = Boolean(state.treatmentPlanning[treatmentPlanLoadedId]) ? state.treatmentPlanning[treatmentPlanLoadedId] : false;
    const treatmentPlan = treatmentPlanLoaded ? state.treatmentPlanning[treatmentPlanId] : null;

    return {

        message: state.stateManagement.message,
        wsmessage: state.websockets.message,

        treatmentPlanLoaded,
        treatmentPlan,
    }
};

const MapDispatchToProps = dispatch => {

    return {
        getTreatmentPlanById: (treatmentPlanId, taskId) => dispatch(getTPResource(RES_TREATMENT_PLAN_BY_ID.GET_FOR_TASK, treatmentPlanId, taskId)),
    }
};

const TaskTPPalette = connect(mapStateToProps, MapDispatchToProps)(ConnectedTaskTPPalette);

export default TaskTPPalette;
