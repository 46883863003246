import _ from 'lodash';
import moment from 'moment';

import {
    RES_HOUSEKEEPING,
    RES_HOUSEKEEPING_ADETS,
    RES_HOUSEKEEPING_ASSCATS,
    RES_HOUSEKEEPING_BASE_CHART_FAVS,
    RES_HOUSEKEEPING_CCS,
    RES_HOUSEKEEPING_CRS,
    RES_HOUSEKEEPING_DOC_CLASSIFICATIONS,
    RES_HOUSEKEEPING_FAVS,
    RES_HOUSEKEEPING_JSTAGES,
    RES_HOUSEKEEPING_MCS,
    RES_HOUSEKEEPING_MEDICATIONS,
    RES_HOUSEKEEPING_OCCS,
    RES_HOUSEKEEPING_QUES,
    RES_HOUSEKEEPING_RECTYPES,
    RES_HOUSEKEEPING_TCODES,
    RES_HOUSEKEEPING_TITLES, RES_HOUSEKEEPING_ZONES
} from "../housekeeping";
import {ac} from "../../index";
import {
    SM_HOUSEKEEPING_ADETS,
    SM_HOUSEKEEPING_ASSCATS,
    SM_HOUSEKEEPING_BASE_CHART_FAVS,
    SM_HOUSEKEEPING_CCS,
    SM_HOUSEKEEPING_CRS,
    SM_HOUSEKEEPING_CSCHED,
    SM_HOUSEKEEPING_DOC_CLASSIFICATIONS,
    SM_HOUSEKEEPING_FAVS,
    SM_HOUSEKEEPING_JSTAGES,
    SM_HOUSEKEEPING_MCS,
    SM_HOUSEKEEPING_MEDICATIONS,
    SM_HOUSEKEEPING_OCCS,
    SM_HOUSEKEEPING_PAT_CONSENT_FORMS,
    SM_HOUSEKEEPING_QUES,
    SM_HOUSEKEEPING_RECTYPES,
    SM_HOUSEKEEPING_TCODES,
    SM_HOUSEKEEPING_TITLES
} from "../stateManagement";
import {RES_PREFERENCES_PATIENT_CONSENT_FORMS} from "../preferences";
import {ReportTextType} from "../../Constants";

const saveTitles = (dispatch, items) => {

    const staticItems = _.filter(items, item => !(item.id < 0 || item.edited));
    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_TITLES.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        const newHistory = [...staticItems, ...returns];
                        dispatch({type: RES_HOUSEKEEPING_TITLES.SAVE.action, payload: newHistory});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveCostCenters = (dispatch, items) => {

    const staticItems = _.filter(items, item => !(item.id < 0 || item.edited));
    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_CCS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        const newHistory = [...staticItems, ...returns];
                        dispatch({type: RES_HOUSEKEEPING_CCS.SAVE.action, payload: newHistory});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveTreatmentFavourites = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_FAVS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_FAVS.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveBaseChartFavourites = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_BASE_CHART_FAVS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_BASE_CHART_FAVS.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveTreatmentCodes = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            item.needsGraphic = item.chartingItem ? item.chartingItem !== null : false;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_TCODES.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_TCODES.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveDiaryZoning = (dispatch, items) => {

    const returns = [];
    const flatEvents = [];

    // Iterate over each row (zoneIndex) in the matrix
    items.forEach((week, zoneIndex) => {
        // Iterate over each day in the current row (zoneDayIndex)
        week.forEach((events, zoneDayIndex) => {
            // Iterate over the events in the current cell and add them to flatEvents
            events.forEach(event => {
                flatEvents.push({
                    ...event,
                    zoneIndex,    // Add the zoneIndex to the event
                    zoneDayIndex  // Add the zoneDayIndex to the event
                });
            });
        });
    });

    if (flatEvents.length > 0) {
        ac.fetchClient().post(`${RES_HOUSEKEEPING_ZONES.SAVE.url}?mcId=${ac.getMcId()}`, flatEvents)
            .then(res => {
                dispatch({type: RES_HOUSEKEEPING_ZONES.GET.request, payload: res.data})
            });
    }
    dispatch({type: RES_HOUSEKEEPING_ZONES.GET.request, payload: false}) // needed to clear save icon and button
    dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
};

const saveJourneyStages = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited) || item.id < 0) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_JSTAGES.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_JSTAGES.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveDocumentClassifications = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited) || item.id < 0) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_DOC_CLASSIFICATIONS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_DOC_CLASSIFICATIONS.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveAppointmentTypes = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(_item => {

        if (Boolean(_item.edited)) {
            _item.edited = false;
            const item = {..._item};
            item.defaultProvider = null;
            item.id = item.id < 0 ? null : item.id;

            if (moment.isMoment(item.duration)) {
                item.duration = item.duration.toDate().toUTCString();
            }
            ac.fetchClient().post(`${RES_HOUSEKEEPING_ADETS.AT_SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_ADETS.AT_SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    })
};

const saveDiaryEventTypes = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {

            item.edited = false;

            const _item = {...item};
            _item.id = _item.id < 0 ? null : _item.id;
            _item.type = _item.type.name;

            ac.fetchClient().post(`${RES_HOUSEKEEPING_ADETS.DE_SAVE.url}?mcId=${ac.getMcId()}`, _item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_ADETS.DE_SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveRecallTypes = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;

            ac.fetchClient().post(`${RES_HOUSEKEEPING_RECTYPES.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_RECTYPES.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveMedicalConditions = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;

            ac.fetchClient().post(`${RES_HOUSEKEEPING_MCS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_MCS.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveMedications = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;

            ac.fetchClient().post(`${RES_HOUSEKEEPING_MEDICATIONS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_MEDICATIONS.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveQuestionnaires = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;

            ac.fetchClient().post(`${RES_HOUSEKEEPING_QUES.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_QUES.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveCancellationReasons = (dispatch, items, targetTCs) => {

    const staticItems = _.filter(items, item => !(item.id < 0 || item.edited));
    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_CRS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        const newHistory = [...staticItems, ...returns];
                        dispatch({type: RES_HOUSEKEEPING_CRS.SAVE.action, payload: newHistory});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
    const targetTCsIds = _.map(targetTCs, targetTC => targetTC.id);

    if (targetTCsIds.length > 0) {
        ac.fetchClient().post(`${RES_HOUSEKEEPING_CRS.SAVE_CCS.url}?mcId=${ac.getMcId()}`, targetTCsIds)
            .then(() => {
                    dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: null}) // needed to clear save icon and button
                }
            );
    }
};

const saveOccupations = (dispatch, items) => {

    const staticItems = _.filter(items, item => !(item.id < 0 || item.edited));
    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_OCCS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        const newHistory = [...staticItems, ...returns];
                        dispatch({type: RES_HOUSEKEEPING_OCCS.SAVE.action, payload: newHistory});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveConsentForms = (dispatch, items) => {

    const staticItems = _.filter(items, item => !(item.id < 0 || item.edited));
    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;

            // we must remove the type info and replace with ordinals name

            const name = _.find(ReportTextType, type => type.ordinal === item.type.ordinal).name;
            const _item = {...item, type: name};

            ac.fetchClient().post(`${RES_PREFERENCES_PATIENT_CONSENT_FORMS.SAVE.url}?mcId=${ac.getMcId()}`, _item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        const newHistory = [...staticItems, ...returns];
                        dispatch({type: RES_PREFERENCES_PATIENT_CONSENT_FORMS.SAVE.action, payload: newHistory});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveAssCats = (dispatch, items) => {

    const staticItems = _.filter(items, item => !(item.id < 0 || item.edited));
    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {

            item.elements.forEach(element => element.category = {id: item.id});

            item.edited = false;
            item.id = item.id < 0 ? null : item.id;

            ac.fetchClient().post(`${RES_HOUSEKEEPING_ASSCATS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        const newHistory = [...staticItems, ...returns];
                        dispatch({type: RES_HOUSEKEEPING_ASSCATS.SAVE.action, payload: newHistory});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const getData = (sm, item, name) => {
    const base = _.get(sm, `${item.id}`);
    return base === undefined ? [] : base.data[name];
};

export const saveHouskeeping = (dispatch, sm) => {

    const titles = getData(sm, SM_HOUSEKEEPING_TITLES, 'titles');
    const costCenters = getData(sm, SM_HOUSEKEEPING_CCS, 'costCenters');
    const treatmentFavourites = getData(sm, SM_HOUSEKEEPING_FAVS, 'treatmentFavourites');
    const baseChartFavourites = getData(sm, SM_HOUSEKEEPING_BASE_CHART_FAVS, 'baseChartFavourites');
    const treatmentCodes = getData(sm, SM_HOUSEKEEPING_TCODES, 'treatmentCodes');
    const diaryZones = getData(sm, SM_HOUSEKEEPING_CSCHED, 'zoneEvents');
    const journeyStages = getData(sm, SM_HOUSEKEEPING_JSTAGES, 'journeyStages');
    const classifications = getData(sm, SM_HOUSEKEEPING_DOC_CLASSIFICATIONS, 'classifications');
    const appointmentTypes = getData(sm, SM_HOUSEKEEPING_ADETS, 'appointmentTypes');
    const diaryEventTypes = getData(sm, SM_HOUSEKEEPING_ADETS, 'diaryEventTypes');
    const recallTypes = getData(sm, SM_HOUSEKEEPING_RECTYPES, 'recallTypes');
    const medicalConditions = getData(sm, SM_HOUSEKEEPING_MCS, 'medicalConditions');
    const medications = getData(sm, SM_HOUSEKEEPING_MEDICATIONS, 'medications');
    const questions = getData(sm, SM_HOUSEKEEPING_QUES, 'questions');
    const cancellationReasons = getData(sm, SM_HOUSEKEEPING_CRS, 'cancellationReasons');
    const targetTCs = getData(sm, SM_HOUSEKEEPING_CRS, 'targetTCs');
    const occupations = getData(sm, SM_HOUSEKEEPING_OCCS, 'occupations');
    const consentForms = getData(sm, SM_HOUSEKEEPING_PAT_CONSENT_FORMS, 'patientConsentForms');
    const assCats = getData(sm, SM_HOUSEKEEPING_ASSCATS, 'assCats');

    saveTitles(dispatch, titles);
    saveCostCenters(dispatch, costCenters);
    saveTreatmentFavourites(dispatch, treatmentFavourites);
    saveBaseChartFavourites(dispatch, baseChartFavourites);
    saveTreatmentCodes(dispatch, treatmentCodes);
    saveDiaryZoning(dispatch, diaryZones);
    saveJourneyStages(dispatch, journeyStages);
    saveDocumentClassifications(dispatch, classifications);
    saveAppointmentTypes(dispatch, appointmentTypes);
    saveDiaryEventTypes(dispatch, diaryEventTypes);
    saveRecallTypes(dispatch, recallTypes);
    saveMedicalConditions(dispatch, medicalConditions);
    saveMedications(dispatch, medications);
    saveQuestionnaires(dispatch, questions);
    saveCancellationReasons(dispatch, cancellationReasons, targetTCs);
    saveOccupations(dispatch, occupations);
    saveConsentForms(dispatch, consentForms);
    saveAssCats(dispatch, assCats);
};
