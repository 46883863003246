import React, {Component} from 'react';
import {connect} from "react-redux";
import _ from "lodash";

import 'nanoscroller';
import Dashboard from '../Dashboard';

import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';

import 'primereact/resources/primereact.min.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'nanoscroller/bin/css/nanoscroller.css';

import {saveStateVectors, updateTabStack} from "../../actions/login";
import {HM_CLOSE_ALL_TABS, HM_CLOSE_OTHER_TABS, HM_CLOSE_TAB} from "../../Constants";
import {TB_GOTO_APPOINTMENT, TB_PLACE_APPOINTMENT, TB_WEEK_GOTO_DAY_DIARY} from "./PatientDetails/Constants";
import {MESSAGE_BUS, OPEN_MESSAGING} from "../../actions";
import {SM_PRACTICE_CONTROL_CENTER, SM_PRACTICE_DIARY} from "../../actions/stateManagement";
import {
    getResource as getHKResource,
    getResource,
    RES_HOUSEKEEPING_ADETS,
    RES_HOUSEKEEPING_DOC_CLASSIFICATIONS,
    RES_HOUSEKEEPING_JSTAGES
} from "../../actions/housekeeping";
import {RES_PREFERENCES_PDS} from "../../actions/preferences";
import Messenger from "./Chat/Messenger";
import {menuButtonTemplate} from "./fixedItemUtils";
import PracticeDiary from "./Diary/PracticeDiary";

class ConnectedPracticeControlCenter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0,
            index: 0,
            tabStack: [],
            messagingVisible: false,
        };

        this.cm0 = null;
        this.cm1 = null;

        this.selectedCMHeader = null;

        this.onPCButtonClick = this.onPCButtonClick.bind(this);
        this.onTabCloseClick = this.onTabCloseClick.bind(this);
        this.multipleTabClose = this.multipleTabClose.bind(this);
        this.onTabUpdate = this.onTabUpdate.bind(this);
        this.onTabChange = this.onTabChange.bind(this);

        this.onShowContextMenu = this.onShowContextMenu.bind(this);
        this.findTargetFromIndex = this.findTargetFromIndex.bind(this);
        this.closeTab = this.closeTab.bind(this);
        this.closeOtherTabs = this.closeOtherTabs.bind(this);
        this.closeAllTabs = this.closeAllTabs.bind(this);
    }

    componentDidMount() {

        this.props.getAppointmentDiaryTypes();
        this.props.getJourneyStages();
        this.props.getDocClassifications();
        this.props.getPracticeDetails();

        this.setState({
            activeIndex: 0,
            index: 0,
            tabStack: [],
        }, () => {
            this.props.saveStateVectors(this.onPCButtonClick, this.onTabCloseClick, this.onTabUpdate);
        })
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            this.setState({activeIndex: this.props.activeIndex, ...this.props.tabStack});

            switch (this.props.message.type) {

                case OPEN_MESSAGING:

                    this.setState({messagingVisible: true});
                    break;

                case MESSAGE_BUS:

                    const {type, payload} = this.props.message.payload;

                    switch (type) {
                        case TB_GOTO_APPOINTMENT.id:
                            this.setState({currentDate: payload.start}, () => {

                                // find the active diary
                                let tabIndex = this.state.tabStack.findIndex(tab => tab.content.key === SM_PRACTICE_DIARY.id);
                                let newState = null;

                                // this.state.tabStack[tabIndex];
                                // if found, switch to it
                                if (!(tabIndex < 0)) {

                                    newState = {...this.state, activeIndex: tabIndex + 1};
                                    this.props.updateState(newState);
                                }
                            });
                            break;

                        case TB_PLACE_APPOINTMENT.id:
                            this.setState({currentDate: new Date()}, () => {
                                this.onPCButtonClick(menuButtonTemplate(this, PracticeDiary, SM_PRACTICE_DIARY.id, SM_PRACTICE_DIARY.tabIcon, SM_PRACTICE_DIARY.label, null));
                            });
                            break;

                        case TB_WEEK_GOTO_DAY_DIARY.id:
                            this.setState({currentDate: payload}, () => {

                                // find the active diary
                                let tabIndex = this.state.tabStack.findIndex(tab => tab.content.key === SM_PRACTICE_DIARY.id);
                                let newState = null;

                                // this.state.tabStack[tabIndex];
                                // if not found, create diary component, else switch to it
                                const options = {requestedDate: payload.requestedDate, eventId: payload.eventId};

                                if (tabIndex < 0) {

                                    this.props.onPCButtonClick(menuButtonTemplate(this.props, PracticeDiary, SM_PRACTICE_DIARY.id, SM_PRACTICE_DIARY.tabIcon, SM_PRACTICE_DIARY.label, null, options));
                                } else {

                                    newState = {...this.state, activeIndex: tabIndex + 1};
                                    const button = menuButtonTemplate(this.props, PracticeDiary, SM_PRACTICE_DIARY.id, SM_PRACTICE_DIARY.tabIcon, SM_PRACTICE_DIARY.label, null, options);
                                    newState.tabStack[tabIndex] = {...button};
                                    this.props.updateState(newState);
                                }
                            });
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }

        if (prevProps !== this.props) {

            if (!this.props.loggedIn) {
                this.setState({
                    activeIndex: 0,
                    index: 0,
                    tabStack: [],
                })
            }
        }

        let htmlElements = document.querySelectorAll('a[role]');

        htmlElements.forEach(element => {

            element.addEventListener('contextmenu', (e) => {
                this.onShowContextMenu(e, element.id);
            });
        });
    }

    onPCButtonClick(button) {

        let tabIndex = this.state.tabStack.findIndex(tab => tab.content.key === button.content.key);
        let newState = null;

        if (tabIndex < 0) {

            let index = this.state.index + 1;

            newState = {...this.state, index, activeIndex: index};
            newState.tabStack.push(button);
        } else {

            newState = {...this.state, activeIndex: tabIndex + 1};
            newState.tabStack[tabIndex] = {...button};
        }

        this.props.updateState(newState);
    }

    onTabCloseClick(target) {

        let index = this.state.index - 1;
        let activeIndex = this.state.activeIndex - 1;

        const newTabStack = [];

        this.state.tabStack.forEach((tab) => {
            if (tab.content.key !== target.key) {
                newTabStack.push(tab);
            }
        });

        let newState = {...this.state, tabStack: newTabStack, index, activeIndex};
        this.props.updateState(newState);
    }

    multipleTabClose(targets) {

        let index = this.state.index - targets.length;
        let activeIndex = this.state.activeIndex - targets.length;

        const newTabStack = [];

        this.state.tabStack.forEach((tab) => {

            const found = _.findIndex(targets, target => target.key === tab.content.key);

            if (found === -1) {
                newTabStack.push(tab);
            }
        });

        let newState = {...this.state, tabStack: newTabStack, index, activeIndex};
        this.props.updateState(newState);
    }

    onTabUpdate(target, updated) {

        let index = this.state.index;

        const newTabStack = [];

        this.state.tabStack.forEach((tab) => {
            if (tab.content.key === target.key) {
                tab.edited = updated;
            }
            newTabStack.push(tab);
        });

        let newState = {...this.state, tabStack: newTabStack, index, activeIndex: this.state.activeIndex};
        this.props.updateState(newState);
    }

    onTabChange(index) {
        let newState = {...this.state, activeIndex: index};
        this.props.updateState(newState);
    }

    findTargetFromIndex(index) {

        // as the PCC is not in the tab stack subtract one from the index
        return this.state.tabStack[index - 1].content;
    }

    onShowContextMenu(e, id) {

        this.selectedCMHeader = parseInt(id[id.length - 1], 10);

        if (this.selectedCMHeader === 0) {
            this.cm0.show(e);
            this.cm1.hide(e);
        } else {
            this.cm0.hide(e);
            this.cm1.show(e);
        }
    }

    closeTab() {

        const headerTarget = this.findTargetFromIndex(this.selectedCMHeader);
        this.onTabCloseClick(headerTarget);
    }

    closeOtherTabs() {

        const targets = [];

        // notice not 0 based index
        for (let index = 1; index <= this.state.tabStack.length; index++) {

            if (index !== this.selectedCMHeader) {
                const headerTarget = this.findTargetFromIndex(index);
                targets.push(headerTarget);
            }
        }
        this.multipleTabClose(targets);
    }

    closeAllTabs() {

        let index = 0;
        let activeIndex = 0;

        const newTabStack = [];

        let newState = {...this.state, tabStack: newTabStack, index, activeIndex};
        this.props.updateState(newState);

    }

    onCloseMessenger = () => {
        this.setState({messagingVisible: false});
    }

    render() {

        const items0 = [
            {label: HM_CLOSE_TAB.label, icon: HM_CLOSE_TAB.icon, disabled: true},
            {label: HM_CLOSE_OTHER_TABS.label, icon: HM_CLOSE_OTHER_TABS.icon},
            {label: HM_CLOSE_ALL_TABS.label, icon: HM_CLOSE_ALL_TABS.icon, disabled: true},
        ];

        const items1 = [
            {label: HM_CLOSE_TAB.label, icon: HM_CLOSE_TAB.icon, command: this.closeTab},
            {label: HM_CLOSE_OTHER_TABS.label, icon: HM_CLOSE_OTHER_TABS.icon, command: this.closeOtherTabs},
            {label: HM_CLOSE_ALL_TABS.label, icon: HM_CLOSE_ALL_TABS.icon, command: this.closeAllTabs},
        ];

        return (

            <div className="layout-main">

                <Messenger visible={this.state.messagingVisible}
                           onHide={this.onCloseMessenger}
                           loginIdentity={this.props.loginIdentity}
                />

                <ContextMenu model={items0} ref={el => this.cm0 = el}/>
                <ContextMenu model={items1} ref={el => this.cm1 = el}/>

                <div className="card card-w-title">
                    <TabView activeIndex={this.state.activeIndex}
                             onTabChange={(event) => {
                                 this.onTabChange(event.index);
                             }}>

                        <TabPanel id={SM_PRACTICE_CONTROL_CENTER.id}
                                  header={SM_PRACTICE_CONTROL_CENTER.label}
                                  leftIcon={SM_PRACTICE_CONTROL_CENTER.tabIcon}
                        >
                            <Dashboard onClick={this.onPCButtonClick}
                                       onCloseClick={this.onTabCloseClick}
                                       onTabUpdate={this.onTabUpdate}
                            />
                        </TabPanel>

                        {this.state.tabStack.map(tab => {
                            if (tab.edited) {
                                return tab.editedContent
                            } else {
                                return tab.content
                            }
                        })}

                    </TabView>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const practiceDetailsLoaded = Boolean(state.preferences.practiceDetailsLoaded);
    const practiceDetails = practiceDetailsLoaded ? state.preferences.practiceDetails : [];

    return {

        message: state.stateManagement.message,

        wsmessage: state.websockets.message,

        stateManagement: state.stateManagement,

        practiceDetailsLoaded,
        practiceDetails,

        loginIdentity: state.login.user,
        capabilities: state.login.capabilities,
        loggedIn: state.login.loggedIn,
        tabStack: state.login.pcc,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
    }
};

const mapDispatchToProps = dispatch => {

    return {
        getJourneyStages: () => dispatch(getResource(RES_HOUSEKEEPING_JSTAGES.GET, {})),
        getDocClassifications: () => dispatch(getResource(RES_HOUSEKEEPING_DOC_CLASSIFICATIONS.GET, {})),
        getAppointmentDiaryTypes: () => dispatch(getHKResource(RES_HOUSEKEEPING_ADETS.GET, {})),
        getPracticeDetails: () => dispatch(getResource(RES_PREFERENCES_PDS.GET, {})),

        saveStateVectors: (onPCButtonClick, onTabCloseClick, onTabUpdate) => dispatch(saveStateVectors(onPCButtonClick, onTabCloseClick, onTabUpdate)),
        updateState: (state) => dispatch(updateTabStack(state)),
    };
};

const PracticeControlCenter = connect(mapStateToProps, mapDispatchToProps)(ConnectedPracticeControlCenter);

export default PracticeControlCenter;
