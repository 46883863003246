import React from 'react';
import {Button} from 'primereact/components/button/Button';
import _ from "lodash";
import {
    CH_INVOICE,
    CH_INVOICED,
    CH_NHSCOMPLETED,
    CH_RECEIPTED,
    HM_AppointmentComment,
    HM_notImplemented
} from "../../Constants";
import {TabBaseComponent} from "../TabBaseComponent";
import {TB_SET_CURRENT_DATE, TB_SHOW_TREATMENT_ADD} from "./PatientDetails/Constants";
import {CariesColor} from "../Charting/Model/Constants";
import {PALETTE_CHANGE, RT_ADD_PATIENT_NOTE, RT_EDIT_PATIENT_NOTE} from "../../actions";
import {getSalesTPTotal} from "./AppointmentDetails/Utils";
import {COLOR_ChiralPalette} from "../Client/Constants";
import {RES_TREATMENT_PLAN_BY_APP_ID, RES_TREATMENT_PLANNING} from "../../actions/treatmentPlanning";

export class ChartingComponent extends TabBaseComponent {

    codeToggle = () => {
        this.setState({showCode: !this.state.showCode});
    }

    checkForChargeableItems() {

        const result = _.findIndex(this.state.treatmentPlan.unassignedCharges, charge => {
            return charge.status === CH_INVOICE;
        });
        return result !== -1;
    };

    handleCategoryChange(handler, category) {

        let baseCategoryGroups = [...this.props.baseCategoryGroups];
        let favCategoryGroups = [...this.props.favCategoryGroups];

        // clear the old selection
        baseCategoryGroups.forEach((category) => {
            category.selected = false;
        });
        // clear the old selection
        favCategoryGroups.forEach((category) => {
            category.selected = false;
        });
        category.selected = true;

        this.setState({
            baseCategoryGroups,
            favCategoryGroups,
            selectedItem: {label: 'Select'},
            selectedCategory: category
        }, () => {
            this.save = true;
            handler({owner: PALETTE_CHANGE, value: null});
        });
    }

    mapThePalette() {

        const style = document.createElement("style");

        style.setAttribute("type", "text/css");
        style.setAttribute("title", "materials");

        // WebKit hack :(
        style.appendChild(document.createTextNode(""));

        // Add the <style> element to the page
        document.head.appendChild(style);

        this.props.resources.materials.forEach((material) => {
            const rgb = `rgba(${material.red},${material.green},${material.blue})`;
            // ensure style name has only alphanumeric chars
            const styleMaterialName = material.name.replace(/\W/g, '');
            style.sheet.insertRule(`.${styleMaterialName}{ background: ${rgb}; display:inline-block; width:16px; height:16px; position:relative; margin-right: 0.55em}`);
        });
        style.sheet.insertRule(`.caries{ background: ${CariesColor}; display:inline-block; width:16px; height:16px; position:relative; margin-right: 0.55em}`);
    }

    onHideMenuEntry(target) {

        const state = {...this.state};
        _.set(state, target, false);

        this.setState(state);
    }

    onEditAppointmentTitle(appointment) {
        this.onShowMenuEntry({item: {target: HM_AppointmentComment.id}});
        this.appointment = appointment;
    }

    onHideAddEditTreatment() {
        this.setState({showAddTreatment: false, showEditTreatment: false, addFromCharting: false});
    }

    onShowAddEditTreatment(target, charted, entry, selectedAppointmentId) {

        if (target === TB_SHOW_TREATMENT_ADD.id) {
            this.setState({
                showAddTreatment: true,
                addFromCharting: charted,
                currentChartedEntry: entry,
                selectedAppointmentId,
            });
        } else {
            this.setState({
                showEditTreatment: true,
                addFromCharting: charted,
                currentChartedEntry: entry,
                selectedAppointmentId,
            });
        }
    }

    onAddTreatment(treatment) {

        const treatmentPlan = _.cloneDeep(this.state.treatmentPlan);
        const charting = _.cloneDeep(this.state.charting);
        treatmentPlan.edited = true;

        treatment.id = treatmentPlan.unassignedCharges.length - 1000;
        treatmentPlan.unassignedCharges.push(treatment);

        // if the appointment is null this is being added via treatment planning
        if (treatment.appointment !== null) {
            const targetAppointment = _.find(treatmentPlan.appointments, appointment => appointment.appointmentId === treatment.appointment.appointmentId);
            targetAppointment.charges.push(treatment);
        }

        // if the treatment has charting attached
        // 1 push the charting entry
        // 2 clear the Add Treatment dialog

        let showAddTreatment = true;

        if (treatment.chartEntry) {
            charting.entries.push(treatment.chartEntry)
            showAddTreatment = false;
        }
        const payable = this.checkForChargeableItems();

        const canSave = {...this.state.canSave};
        _.set(canSave, 'status', true)
        _.set(canSave, 'source.action', this.state.fromTreatmentPlan ? RES_TREATMENT_PLANNING.SAVE.action : RES_TREATMENT_PLAN_BY_APP_ID.SAVE.action);
        _.set(canSave, 'source.saveState', true)

        this.setState({treatmentPlan, charting, payable, canSave, showAddTreatment}, () => {

            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

            this.props.sendMessage({type: RT_ADD_PATIENT_NOTE, payload: treatment});

            let salesTotal = treatmentPlan ? getSalesTPTotal(treatmentPlan) : 0.0;

            if (this.props.onCostChange) {
                // propagate upwards
                this.props.onChange({
                    owner: 'salesTotal',
                    value: salesTotal,
                    source: {
                        id: this.state.stateManagementId,
                        action: RES_TREATMENT_PLANNING.SAVE.action
                    }
                });
            } else {
                this.onCostChange(salesTotal);
            }
        });
    }

    onEditTreatment(treatment) {

        const treatmentPlan = _.cloneDeep(this.state.treatmentPlan);
        treatmentPlan.edited = true;

        let index = _.findIndex(treatmentPlan.unassignedCharges, charge => charge.id === treatment.id);
        treatmentPlan.unassignedCharges[index] = treatment;

        // if the treatment is assigned it must be updated in both the un- and assigned list
        if (treatment.appointment !== -1 && treatment.appointment !== null) {
            const targetAppointment = _.find(treatmentPlan.appointments, appointment => appointment.appointmentId === treatment.appointment.appointmentId);
            if (targetAppointment !== undefined) {
                index = _.findIndex(targetAppointment.charges, charge => charge.id === treatment.id);
                targetAppointment.charges[index] = treatment;
            }
        }

        const payable = this.checkForChargeableItems();

        const canSave = {...this.state.canSave};
        _.set(canSave, 'status', true)
        _.set(canSave, 'source.action', this.state.fromTreatmentPlan ? RES_TREATMENT_PLANNING.SAVE.action : RES_TREATMENT_PLAN_BY_APP_ID.SAVE.action);
        _.set(canSave, 'source.saveState', true)

        this.setState({treatmentPlan, payable, showEditTreatment: false, canSave}, () => {

            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

            this.props.sendMessage({type: RT_EDIT_PATIENT_NOTE, payload: treatment});

            let salesTotal = treatmentPlan ? getSalesTPTotal(treatmentPlan) : 0.0;

            if (this.props.onCostChange) {
                // propagate upwards
                this.props.onChange({
                    owner: 'salesTotal',
                    value: salesTotal,
                    source: {
                        id: this.state.stateManagementId,
                        action: RES_TREATMENT_PLANNING.SAVE.action
                    }
                });
            } else {
                this.onCostChange(salesTotal);
            }
        });
    }

    onDeleteTreatment(treatment) {

        const treatmentPlan = _.cloneDeep(this.state.treatmentPlan);
        const charting = _.cloneDeep(this.state.charting);
        treatmentPlan.edited = true;

        // if a completed treatment code chart entry has been added to the chart and that treatment exists in this plan and it is removed
        // we must also remove the chart entry from the chart
        charting.entries = treatment.chartEntry ? _.filter(charting.entries, entry => entry.id !== treatment.chartEntry.id) : charting.entries

        treatmentPlan.unassignedCharges = _.filter(treatmentPlan.unassignedCharges, charge => charge.id !== treatment.id);

        // if the appointment is null this is being added via treatment planning

        treatmentPlan.appointments.forEach(appointment => {
            appointment.charges = _.filter(appointment.charges, charge => charge.id !== treatment.id);
        });

        const payable = this.checkForChargeableItems();

        const canSave = {...this.state.canSave};
        _.set(canSave, 'status', true)
        _.set(canSave, 'source.action', this.state.fromTreatmentPlan ? RES_TREATMENT_PLANNING.SAVE.action : RES_TREATMENT_PLAN_BY_APP_ID.SAVE.action);
        _.set(canSave, 'source.saveState', true)

        this.setState({treatmentPlan, charting, payable, canSave}, () => {

            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

            let salesTotal = treatmentPlan ? getSalesTPTotal(treatmentPlan) : 0.0;

            if (this.props.onCostChange) {
                // propagate upwards
                this.props.onChange({
                    owner: 'salesTotal',
                    value: salesTotal,
                    source: {
                        id: this.state.stateManagementId,
                        action: RES_TREATMENT_PLANNING.SAVE.action
                    }
                });
            } else {
                this.onCostChange(salesTotal);
            }
        });
    }

    onTreatmentComplete(completedCharge) {

        const treatmentPlan = {...this.state.treatmentPlan};
        const charting = {...this.state.charting};

        let chargeIndex = _.findIndex(treatmentPlan.unassignedCharges, charge => charge.id === completedCharge.id);
        treatmentPlan.unassignedCharges[chargeIndex].status = completedCharge.status;
        treatmentPlan.edited = true;

        if (chargeIndex > -1 && treatmentPlan.unassignedCharges[chargeIndex].chartEntry) {

            let chartIndex = _.findIndex(charting.entries, entry => entry.id === completedCharge.chartEntry.id);

            switch (completedCharge.status) {
                case CH_INVOICE:
                case CH_INVOICED:
                case CH_NHSCOMPLETED:
                case CH_RECEIPTED:
                    completedCharge.chartEntry.complete = true;
                    treatmentPlan.unassignedCharges[chargeIndex].chartEntry.complete = true;
                    charting.entries[chartIndex].complete = true;
                    break;
                default:
                    completedCharge.chartEntry.complete = false;
                    treatmentPlan.unassignedCharges[chargeIndex].chartEntry.complete = false;
                    charting.entries[chartIndex].complete = false;
                    break;
            }
        }

        // if the appointment is null this is being added via treatment planning
        if (completedCharge.appointment !== null && completedCharge.appointment.appointmentId !== undefined) {
            const targetAppointmentIndex = _.findIndex(treatmentPlan.appointments, appointment => appointment.appointmentId === completedCharge.appointment.appointmentId);
            chargeIndex = _.findIndex(treatmentPlan.appointments[targetAppointmentIndex].charges, charge => charge.id === completedCharge.id);
            treatmentPlan.appointments[targetAppointmentIndex].charges[chargeIndex] = completedCharge;
        }

        const payable = this.checkForChargeableItems();

        const canSave = {...this.state.canSave};
        _.set(canSave, 'status', true)
        _.set(canSave, 'source.action', this.state.fromTreatmentPlan ? RES_TREATMENT_PLANNING.SAVE.action : RES_TREATMENT_PLAN_BY_APP_ID.SAVE.action);
        _.set(canSave, 'source.saveState', true)

        this.setState({treatmentPlan, charting, payable, canSave}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

            if (this.state.fromTreatmentPlan) {
                // propagate upwards
                this.props.onChange({
                    owner: 'canSave.status',
                    value: true,
                    source: {
                        id: this.state.stateManagementId,
                        action: RES_TREATMENT_PLANNING.SAVE.action
                    }
                });
            }
        });
    }

    // for base charting only
    resetTooth = (item) => {

        const charting = {...this.state.charting};

        charting.entries = _.filter(charting.entries, entry => !(item.jaw === entry.jaw && item.pos.perm === entry.position));

        this.onChange({owner: 'charting', value: charting});
    }

    removeChartingEntry = (item) => {

        const charting = {...this.state.charting};

        charting.entries = _.filter(charting.entries, entry => entry.id !== item.id);

        this.onChange({owner: 'charting', value: charting});
    }

    // used
    addChartingEntry(entry) {

        const charting = {...this.state.charting};

        charting.entries.push(entry);

        this.onChange({owner: 'charting', value: charting});
    }

    onUpdateAppointment = (updatedAppointment) => {

        const treatmentPlan = _.cloneDeep(this.state.treatmentPlan);
        treatmentPlan.edited = true;

        const targetAppointment = _.find(treatmentPlan.appointments, appointment => appointment.appointmentId === updatedAppointment.appointmentId);
        targetAppointment.shortDescription = updatedAppointment.shortDescription;
        targetAppointment.description = updatedAppointment.description;
        targetAppointment.apType = updatedAppointment.apType;
        targetAppointment.appointmentWith = updatedAppointment.appointmentWith;
        targetAppointment.attendantNurse = updatedAppointment.attendantNurse;

        const canSave = {...this.state.canSave};
        _.set(canSave, 'status', true)
        _.set(canSave, 'source.action', this.state.fromTreatmentPlan ? RES_TREATMENT_PLANNING.SAVE.action : RES_TREATMENT_PLAN_BY_APP_ID.SAVE.action);
        _.set(canSave, 'source.saveState', true)

        this.setState({treatmentPlan, canSave}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

            if (this.state.fromTreatmentPlan) {
                // propagate upwards
                this.props.onChange({
                    owner: 'canSave.status',
                    value: true,
                    source: {
                        id: this.state.stateManagementId,
                        action: RES_TREATMENT_PLANNING.SAVE.action
                    }
                });
            }
        });
    }

    showCategoryButtons(handler, sortedCategories, bgColour, showCode, selectedCategory) {

        const content = [];

        sortedCategories.forEach((category, index) => {

            const label = showCode ? category.code : category.name;

            const baseStyle = {color: 'black', marginRight: '2px'};
            const style = selectedCategory.code === category.code ? {backgroundColor: COLOR_ChiralPalette, ...baseStyle} : {backgroundColor: bgColour, ...baseStyle};

            content.push(
                <Button className="p-button-secondary"
                        key={index} label={label}
                        style={style}
                        onClick={() => {
                            this.handleCategoryChange(handler, category);
                        }}
                />
            )
        });
        return content;
    }
}
