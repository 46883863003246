import React from "react";

export class EventComponent extends React.Component {

    constructor(props) {
        super(props);

        this.myRef = React.createRef();
    }

    rightCLickHandler = (e) => {
        this.props.onShowRightClick(e, this.props.diary, this.props.event);
    }

    componentDidMount() {

        try {
            this.myRef.current.parentNode.parentNode.parentNode.addEventListener('contextmenu', this.rightCLickHandler)
        } catch (error) {
            console.log(`Missing parent event node adding listener`);
        }
    }

    render() {

        const content = [];
        content.push(
            <div id={this.props.event.id}
                 style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-around'}}
                 ref={this.myRef}
            >
                <div>{this.props.event.title}</div>
            </div>
        );
        content.push(<div style={{textAlign: 'center'}}>{this.props.event.comment}</div>);
        return content;
    }

    componentWillUnmount() {

        try {
            this.myRef.current.parentNode.parentNode.parentNode.removeEventListener('contextmenu', this.rightCLickHandler)
        } catch (error) {
            console.log(`Missing parent event node removing listener`);
        }
    }
}
