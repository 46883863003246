import React from 'react';
import {connect} from 'react-redux';
import {Panel} from 'primereact/components/panel/Panel';
import {ProgressBar} from "primereact/progressbar";
import {CLINICIAN, SU_STATUS_ACTIVE} from "../../../Constants";
import {getResource, RES_SMS_SHOT} from "../../../actions/mailShots";
import {setState, SM_SMS_MAIL_SHOT, SM_SMS_RECIPIENTS} from "../../../actions/stateManagement";
import {CONST_FEMALE, inputText, inputTextArea} from "../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {
    ICON_PATIENT_FEMALE,
    ICON_PATIENT_MALE,
    TB_MAILSHOT_RECIPIENTS,
    TB_MAILSHOT_REMOVE_RECIPIENT,
    TB_PATIENT_DETAILS
} from "../PatientDetails/Constants";
import {getDropDowns, RES_getDropDowns} from "../../../actions/dropDowns";
import {getResource as getHKResource, RES_HOUSEKEEPING_JSTAGES} from "../../../actions/housekeeping";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {dateTemplate} from "../../PatientDynamicItems/Utils";
import {MailShotBase} from "../Utilities/MailShotBase";

class ConnectedSMSMailShot extends MailShotBase {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {

            this.state = {

                stateManagementId: props.shotDataId,

                shotLoaded: false,
                shot: null,

                recipientsLoaded: false,
                recipients: [],

                dataTableSelectValue: null,

                first: 0,
                rows: 10,

                sortedClinicians: [],

                operatorIndex: 1,

                emailShot: false,
                saveAction: RES_SMS_SHOT.SAVE.action,
            }
        }
        this.editor = null;
    }

    componentDidMount() {
        if (!this.props.currentState) {
            this.props.getJourneyStages();
            this.props.getDropDowns();
            this.props.getMailShot({mspId: this.props.smsShotId});
        }
    }

    componentDidUpdate(prevProps, prevState, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_getDropDowns.receive:

                    const clinicians = _.filter(this.props.providers, provider => provider.myRole === CLINICIAN && provider.status === SU_STATUS_ACTIVE);
                    const sortedClinicians = _.sortBy(clinicians, ['lastName', 'firstName'], ['asc', 'asc']);

                    this.setState({sortedClinicians});
                    break;
                case RES_SMS_SHOT.GET_BY_ID.receive:
                    const shot = {...this.props.shot};

                    if (shot.patientJourneyCodeIds === null) {
                        shot.patientJourneyCodeIds = Array.from({length: 20}, () => null);
                    }

                    this.setState({shotLoaded: true, shot}, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                    });
                    break;

                case RES_SMS_SHOT.SELECT.receive:

                    const sortedRecipients = _.sortBy(this.props.recipients, ['lastName', 'firstName'], ['asc', 'asc']);

                    this.setState({recipientsLoaded: true, recipients: sortedRecipients, first: 0}, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                    });
                    break;

                case RES_SMS_SHOT.SAVE.receive:
                    // propagate upwards
                    this.props.onChange({
                        owner: 'canSave.status',
                        value: false,
                        source: {id: this.state.stateManagementId, action: RES_SMS_SHOT.SAVE.action}
                    });
                    break;
                default:
                    break;
            }
        }
    }

    buildItems() {

        if (this.state.dataTableSelectValue === null) return;

        const genderIcon = this.state.dataTableSelectValue.gender === CONST_FEMALE ? ICON_PATIENT_FEMALE : ICON_PATIENT_MALE;

        return [
            {
                label: TB_PATIENT_DETAILS.text, icon: genderIcon, command: (e) => {

                    this.onShowDetails(this.state.dataTableSelectValue, null);
                }
            },
            {
                label: TB_MAILSHOT_REMOVE_RECIPIENT.text,
                icon: TB_MAILSHOT_REMOVE_RECIPIENT.icon,
                command: this.removeRecipient,
            },
        ]
    }

    render() {

        if (!this.state.shotLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = this.buildItems();

        const props = {
            onChange: this.onChange,
            target: 'shot',
            shot: this.state.shot,
        };

        const recipientsHeader = <div className='panel-header-centered-content'><label id='panel-header'>Patient
            Selection{` (${this.state.recipients.length})`}</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={TB_MAILSHOT_RECIPIENTS.text}
                    icon={TB_MAILSHOT_RECIPIENTS.icon}
                    onClick={this.runSelection}>
            </Button>
        </div>;

        const templateHeader = `SMS Template Design (${this.state.shot.content ? this.state.shot.content.length : 0})`

        return (
            <div className="p-grid" style={{fontSize: 'small'}}>

                {this.showDialogs()}

                <div className="p-col-6">
                    <Panel header='Details'>
                        <div className="p-grid p-fluid form-group p-col-12">
                            <div className="p-col-2">
                                <label>Title</label>
                            </div>
                            <div className="p-col-4">
                                {inputText(props, 'title', '')}
                            </div>
                            <div className="p-col-2">
                                <label>Description</label>
                            </div>
                            <div className="p-col-4">
                                {inputTextArea(props, 'description', 3, -1, false, false)}
                            </div>

                            <div className="p-col-2">
                                <label>Comments</label>
                            </div>
                            <div className="p-col-12">
                                {inputTextArea(props, 'comments', 6, -1, false, false)}
                            </div>

                            <div className="p-col-12">
                                <DataTable value={this.state.shot.operators}
                                           className='p-datatable-gridlines'
                                           style={{fontSize: 'small'}}
                                >
                                    <Column header='Operator' body={this.column1Template} style={{width: '40%'}}/>
                                    <Column header='Value 1' body={this.column2aTemplate} style={{width: '30%'}}/>
                                    <Column header='Value 2' body={this.column2bTemplate} style={{width: '30%'}}/>
                                    <Column header='' body={this.column3Template} style={{width: '10%'}}/>
                                    <Column header='' body={this.column4Template} style={{width: '10%'}}/>
                                </DataTable>
                            </div>
                        </div>
                    </Panel>
                    <Panel header={recipientsHeader} style={{marginTop: '5px'}}>
                        <div className="p-grid p-fluid p-col-12" style={{fontSize: 'small'}}>

                            <ContextMenu style={{width: 300}} model={items} ref={el => this.cm = el}/>

                            <DataTable value={this.state.recipients}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       selectionMode="single"
                                       paginator={true}
                                       rows={this.state.rows}
                                       rowsPerPageOptions={[10, 50, 200]}
                                       onPage={this.onPage}
                                       first={this.state.first}
                                       selection={this.state.dataTableSelectValue}
                                       onSelectionChange={(e) => this.onChange({
                                           owner: 'dataTableSelectValue',
                                           value: e.value
                                       })}
                                       contextMenuSelection={this.state.dataTableSelectValue}
                                       onContextMenuSelectionChange={(e) => this.onChange({
                                           owner: 'dataTableSelectValue',
                                           value: e.value
                                       })}
                                       onContextMenu={e => {
                                           this.cm.show(e.originalEvent)
                                       }}
                            >
                                <Column field='fullName' header='Name' style={{width: '33%'}}/>
                                <Column body={(row) => dateTemplate(row.dateOfBirth)} header='Date Of Birth'
                                        style={{width: '33%'}}/>
                                <Column body={(row) => this.providerTemplate(row)} header='Provider'
                                        style={{width: '33%'}}/>
                            </DataTable>
                        </div>
                    </Panel>
                </div>
                <Panel header={templateHeader} className="p-fluid p-col-6">
                    {inputTextArea(props, 'content', 3, -1, false, false)}
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const shotLoadedId = `${SM_SMS_MAIL_SHOT.loaded}_${ownProps.smsShotId}`;
    const shotDataId = `${SM_SMS_MAIL_SHOT.id}_${ownProps.smsShotId}`;

    const shotLoaded = Boolean(state.mailShot[shotLoadedId]) ? state.mailShot[shotLoadedId] : false;
    const shot = shotLoaded ? state.mailShot[shotDataId] : null;

    const recipientsLoadedId = `${SM_SMS_RECIPIENTS.loaded}_${ownProps.smsShotId}`;
    const recipientsDataId = `${SM_SMS_RECIPIENTS.id}_${ownProps.smsShotId}`;

    const recipientsLoaded = Boolean(state.mailShot[recipientsLoadedId]) ? state.mailShot[recipientsLoadedId] : false;
    const recipients = recipientsLoaded ? state.mailShot[recipientsDataId] : null;

    const dropDownsLoaded = Boolean(state.dropDowns.dropDownsLoaded);

    return {

        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        shotDataId,
        shotLoaded,
        shot,

        recipientsLoaded,
        recipients,

        dropDownsLoaded,
        providers: state.dropDowns.providers,

        journeyStagesLoaded: state.housekeeping.journeyStagesLoaded,
        journeyStages: state.housekeeping.journeyStages,

        onPCButtonClick: state.login.onPCButtonClick,

        currentState: state.stateManagement[shotDataId],
    };
};

const mapDispatchToProps = dispatch => {

    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getJourneyStages: () => dispatch(getHKResource(RES_HOUSEKEEPING_JSTAGES.GET, {})),

        getMailShot: (params) => dispatch(getResource(RES_SMS_SHOT.GET_BY_ID, params)),
        runSelection: (params) => dispatch(getResource(RES_SMS_SHOT.SELECT, params)),

        setState: (id, data, parent) => dispatch(setState(id, data, parent)),
    };
};

const SMSMailShot = connect(mapStateToProps, mapDispatchToProps)(ConnectedSMSMailShot);

export default SMSMailShot;
