import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import moment from "moment";
import {Panel} from 'primereact/components/panel/Panel';
import currency from "currency.js";
import {
    DOC_PAYMENT_PLAN,
    HM_PaymentPlanChargeCodeNotSpecified,
    PP_INTERVAL,
    PP_INTERVAL_MONTHLY,
    PP_INTERVAL_MONTHS2,
    PP_INTERVAL_MONTHS4,
    PP_INTERVAL_MONTHS6,
    PP_INTERVAL_QUARTERLY,
    PP_INTERVAL_WEEKLY,
    PP_PRECENT,
    PP_PRECENT0,
    PP_SET_TYPE,
    PP_STATUS_ACCEPTED,
    UP_DOCUMENT_ADDED
} from "../../Constants";
import {SM_PAYMENT_PLAN} from "../../actions/stateManagement"
import {
    calendar,
    dropDownPlain,
    inputCurrencyText,
    inputTextArea,
    outputPropsCurrencyBigText,
    outputPropsCurrencyText,
    spinner
} from "../PatientDynamicItems/OnChangeUtils";
import {getResource, RES_HOUSEKEEPING_TCODES, RES_TEMPLATE_NOTES} from "../../actions/housekeeping";
import {getResource as getResourcePP, RES_PAYMENT_PLAN_BY_ID} from "../../actions/paymentPlanning";
import {ppStatusDropDownList} from "../FixedItems/fixedItemUtils";
import {ProgressBar} from "primereact/progressbar";
import * as Actions from "../../actions";
import {ac} from "../../index";
import {ClientComponent} from "./ClientComponent";
import {SM_CLIENT_PAYMENT_PLANNING} from "./Constants";

class ConnectedClientPaymentPlan extends ClientComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: props.paymentPlanId,

                paymentPlanLoaded: false,
                paymentPlan: null,

                notes: [],
                selectedNote: null,
                firstNote: 0,
                noteRows: 5,

                codes: [],
                selectedCode: null,
                firstCode: 0,
                codeRows: 5,

                blockedStatusEditing: true,
                blockedEditing: true,
            }
        }

        this.onChange = this.onChange.bind(this);
        this.onNoteSelection = this.onNoteSelection.bind(this);
        this.onCodeSelection = this.onCodeSelection.bind(this);
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getTemplateNotes();
            this.props.getHousekeepingTCodes();
        }
    }

    componentDidUpdate(prevProps, prevState, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_HOUSEKEEPING_TCODES:

                    this.props.getPaymentPlan(this.props.plan.id);
                    break;

                case RES_PAYMENT_PLAN_BY_ID.GET.receive: {

                    const paymentPlan = {...this.props.paymentPlan};
                    paymentPlan.displayedNoOfInstallments = paymentPlan.numberOfinstallments === 1 ? 1 : paymentPlan.numberOfinstallments - 1;
                    paymentPlan.totalPaymentMinusDeposit = paymentPlan.planTotal - paymentPlan.deposit;

                    let firstCode = paymentPlan.chargeableItem === null ? -1 : _.findIndex(this.props.treatmentCodes.items, target => target.id === paymentPlan.chargeableItem.id);
                    const selectedCode = firstCode === -1 ? null : this.props.treatmentCodes.items[firstCode];
                    firstCode = firstCode === -1 ? 0 : firstCode;

                    this.setState({
                        paymentPlan: paymentPlan,
                        paymentPlanLoaded: this.props.paymentPlanLoaded,
                        selectedCode,
                        firstCode
                    });
                }
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_DOCUMENT_ADDED:

                            const document = {...this.props.wsmessage.content};

                            // this filters out other patients updates
                            if (this.state.paymentPlan.patient.id === document.patientId) {

                                switch (document.type) {

                                    case DOC_PAYMENT_PLAN.name:

                                        const {filename, visibleName, type} = document;

                                        const documentData = {
                                            chiralServer: ac.getBASERESTURL(),
                                            mcid: ac.getMcId(),
                                            filename,
                                            docname: visibleName,
                                            type
                                        };

                                        this.setState({documentData}, () => {

                                            const key = Math.random().toString(36).slice(2);
                                            const key2 = "1234";

                                            localStorage.setItem(key2, JSON.stringify(documentData));

                                            window.open(`https://${ac.getChiralServer()}/documentLoader.html?id=${key}`, '_blank');
                                        });
                                        break;
                                    default:
                                        break;
                                }
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                default:
            }
        }
    }

    onNoteSelection(e) {

        const paymentPlan = {...this.state.paymentPlan};
        paymentPlan.notes += ` ${e.data.content}`;
        paymentPlan.edited = true;

        this.setState({paymentPlan, selectedNote: e.data});
    }

    onCodeSelection(e) {

        const paymentPlan = {...this.state.paymentPlan};
        paymentPlan.chargeableItem = {id: e.value.id};
        paymentPlan.edited = true;

        this.setState({paymentPlan, selectedCode: e.value});
    }

    onChange(event) {

        if (event.owner === 'paymentPlan.status' && event.value === PP_STATUS_ACCEPTED.name && this.state.paymentPlan.chargeableItem === null) {
            this.setState({[HM_PaymentPlanChargeCodeNotSpecified.id]: true});
        } else {
            const state = {...this.state};
            _.set(state, event.owner, event.value);

            let paymentPlan = {...state.paymentPlan};

            state.paymentPlan = this.calculateInstallments(paymentPlan);
            state.paymentPlan.edited = true;

            state.blockedEditing = state.paymentPlan.status === PP_STATUS_ACCEPTED.name;

            this.setState(state);
        }
    }

    findPercentage(percentage) {

        return _.find(PP_PRECENT, target => target.value === percentage).percentage;
    }

    calculateInstallments(plan) {

        let due = currency(plan.planTotal - plan.deposit);
        plan.totalPaymentMinusDeposit = due;
        plan.displayedNoOfInstallments = plan.numberOfinstallments === 1 ? 1 : plan.numberOfinstallments - 1;

        if (plan.preProcedurePercentage !== PP_PRECENT0.value) {

            const percentage = due.multiply(currency(this.findPercentage(plan.preProcedurePercentage) / 100));

            const preProcedurePercentageAmount = percentage.value;
            plan.preProcedurePercentageAmount = preProcedurePercentageAmount;
            due = due.subtract(percentage);
        } else {
            plan.preProcedurePercentageAmount = 0.0;
        }

        let finalDue = currency(0);

        if (plan.numberOfinstallments > 1) {
            let install = currency(due.divide(plan.numberOfinstallments));

            finalDue = due.subtract(install.multiply(plan.numberOfinstallments - 1));
            due = install;
        }
        plan.installmentAmount = due.value;
        plan.finalInstallmentAmount = finalDue.value;

        let startDate = moment(plan.firstInstallment);
        let endDate = startDate;

        plan.lastInstallment = endDate.toDate();
        switch (plan.instalInterval) {
            case PP_INTERVAL_MONTHS2.value :
                endDate = startDate.add((plan.numberOfinstallments - 1) * 2, 'months');
                break;
            case PP_INTERVAL_MONTHS4.value :
                endDate = startDate.add((plan.numberOfinstallments - 1) * 4, 'months');
                break;
            case PP_INTERVAL_MONTHS6.value :
                endDate = startDate.add((plan.numberOfinstallments - 1) * 6, 'months');
                break;
            case PP_INTERVAL_MONTHLY.value :
                endDate = startDate.add((plan.numberOfinstallments - 1), 'months');
                break;
            case PP_INTERVAL_QUARTERLY.value :
                endDate = startDate.add((plan.numberOfinstallments - 1) * 3, 'months');
                break;
            case PP_INTERVAL_WEEKLY.value :
                endDate = startDate.add((plan.numberOfinstallments - 1), 'weeks');
                break;
            default:
                break;
        }
        plan.lastInstallment = endDate.toDate();

        return plan;
    }

    render() {

        if (!this.props.templateNotesLoaded || !this.props.templateNotesLoaded || !this.state.paymentPlanLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const header = <div className='panel-header-centered-content'><label id='panel-header'>{SM_CLIENT_PAYMENT_PLANNING.detail}</label></div>;

        const displayedNoOfInstallments = this.state.paymentPlan.displayedNoOfInstallments;

        const propsPlan = {
            preProcedurePercentageOptions: PP_PRECENT,
            installIntervalOptions: PP_INTERVAL,
            settlementTypeOptions: PP_SET_TYPE,
            statuses: ppStatusDropDownList(),
            onChange: this.onChange,
            target: 'paymentPlan',
            paymentPlan: this.state.paymentPlan,
        };

        return (

            <div className="p-col-12">

                <Panel header={header}>
                    <div className="p-grid p-fluid form-group p-col-12" style={{fontSize: 'small'}}>

                        <div className="p-col-6">
                            <label htmlFor="status">Status</label>
                        </div>
                        <div className="p-col-6">
                            {dropDownPlain(propsPlan, 'statuses', 'status', 'label', this.state.blockedStatusEditing, false)}
                        </div>

                        <div className="p-col-6">
                            <label>Total Treatment Fee</label>
                        </div>
                        <div className="p-col-6">
                            {outputPropsCurrencyText(propsPlan, 'totalPayment')}
                        </div>

                        <div className="p-col-6">
                            <label>Deposit</label>
                        </div>
                        <div className="p-col-6">
                            {inputCurrencyText(propsPlan, 'deposit', '', this.state.blockedEditing, true)}
                        </div>

                        <div className="p-col-6">
                            <label>Pre-procedure Payment</label>
                        </div>
                        <div className="p-col-6">
                            {dropDownPlain(propsPlan, 'preProcedurePercentageOptions', 'preProcedurePercentage', 'label', this.state.blockedEditing, false)}
                        </div>

                        <div className="p-col-3">
                            <label>Number of Instalments</label>
                        </div>
                        <div className="p-col-3">
                            {spinner(propsPlan, 'numberOfinstallments', 1, 72, this.state.blockedEditing, false)}
                        </div>

                        <div className="p-col-2">
                            <label>Installment Interval</label>
                        </div>
                        <div className="p-col-4">
                            {dropDownPlain(propsPlan, 'installIntervalOptions', 'instalInterval', 'label', this.state.blockedEditing, false)}
                        </div>

                        <div className="p-col-6">
                            <label>Pre-procedure Amount</label>
                        </div>
                        <div className="p-col-6">
                            {outputPropsCurrencyBigText(propsPlan, 'preProcedurePercentageAmount', '', true, true)}
                        </div>

                        <div className="p-col-6">
                            <label>Installment Amount ({displayedNoOfInstallments})</label>
                        </div>
                        <div className="p-col-6">
                            {outputPropsCurrencyBigText(propsPlan, 'installmentAmount', '', true, true)}
                        </div>

                        <div className="p-col-6">
                            <label>Final Installment Amount</label>
                        </div>
                        <div className="p-col-6">
                            {outputPropsCurrencyBigText(propsPlan, 'finalInstallmentAmount', '', true, true)}
                        </div>

                        <div className="p-col-6">
                            <label>Payment Plan Total Fee</label>
                        </div>
                        <div className="p-col-6">
                            {outputPropsCurrencyBigText(propsPlan, 'totalPaymentMinusDeposit', '', true, true)}
                        </div>

                        <div className="p-col-6">
                            <label>Proposed Payment Method</label>
                        </div>
                        <div className="p-col-6">
                            {dropDownPlain(propsPlan, 'settlementTypeOptions', 'settlementType', 'label', this.state.blockedEditing, false)}
                        </div>

                        <div className="p-col-6">
                            <label>Payment Plan Start Date</label>
                        </div>
                        <div className="p-col-6">
                            {calendar(propsPlan, 'agreementDate', this.state.blockedEditing, false)}
                        </div>

                        <div className="p-col-6">
                            <label>Deposit Payment Date</label>
                        </div>
                        <div className="p-col-6">
                            {calendar(propsPlan, 'depositDate', this.state.blockedEditing, false)}
                        </div>

                        <div className="p-col-6">
                            <label>Date of First Installment</label>
                        </div>
                        <div className="p-col-6">
                            {calendar(propsPlan, 'firstInstallment', this.state.blockedEditing, false)}
                        </div>

                        <div className="p-col-6">
                            <label>Date of Last Installment</label>
                        </div>
                        <div className="p-col-6">
                            {calendar(propsPlan, 'lastInstallment', true, false)}
                        </div>

                        <div className="p-col-12">
                            <label>Comments</label>
                        </div>
                        <div className="p-col-12">
                            {inputTextArea(propsPlan, 'notes', 5, -1, false, false)}
                        </div>
                    </div>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const templateNotesLoaded = Boolean(state.housekeeping.templateNotesLoaded) ? state.housekeeping.templateNotesLoaded : false;
    const templateNotes = templateNotesLoaded ? state.housekeeping.templateNotes : [];
    const defaultPaymentNote = state.housekeeping.defaultPaymentNote;

    const treatmentCodesLoaded = Boolean(state.housekeeping.treatmentCodesLoaded);
    const treatmentCodes = treatmentCodesLoaded ? state.housekeeping.treatmentCodes : [];

    const paymentPlanLoadedId = `${SM_PAYMENT_PLAN.loaded}_${ownProps.plan.id}`;
    const paymentPlanId = `${SM_PAYMENT_PLAN.id}_${ownProps.plan.id}`;

    const paymentPlanLoaded = Boolean(state.paymentPlanning[paymentPlanLoadedId]) ? state.paymentPlanning[paymentPlanLoadedId] : false;
    const paymentPlan = paymentPlanLoaded ? state.paymentPlanning[paymentPlanId] : null;

    return {

        message: state.stateManagement.message,
        wsmessage: state.websockets.message,

        loginIdentity: state.login.user,

        templateNotesLoaded,
        templateNotes,
        defaultPaymentNote,

        treatmentCodesLoaded,
        treatmentCodes,

        paymentPlanId,
        paymentPlanLoaded,
        paymentPlan,

        currentState: state.stateManagement[paymentPlanId],
    };
};

const mapDispatchToProps = dispatch => {

    return {
        getTemplateNotes: () => dispatch(getResource(RES_TEMPLATE_NOTES.GET, {})),
        getHousekeepingTCodes: () => dispatch(getResource(RES_HOUSEKEEPING_TCODES.GET, {})),
        getPaymentPlan: (id) => dispatch(getResourcePP(RES_PAYMENT_PLAN_BY_ID.GET, id)),
    };
};

const ClientPaymentPlan = connect(mapStateToProps, mapDispatchToProps)(ConnectedClientPaymentPlan);

export default ClientPaymentPlan;
